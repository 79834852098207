import React from 'react'; 
import { FunctionComponent } from "react";
import styled from 'styled-components'; 
import { styles } from "../styles/themes/style";
import Logo from './Logo'

import { ReactComponent as IconLogo } from '../assets/icons/logo.svg'; 

interface ILoading {
    showInitialLoading: boolean
    className?: string
}

interface ILoaderProps {
    $start?: boolean
    $done?: boolean
}

const InitialLoading: FunctionComponent<ILoading> = ({ showInitialLoading, className }) => {
   // const { showInitialLoading } = useUserInterfaceStore(); 

    const [show, setShow] = React.useState(false); 
    const [start, setStart] = React.useState(false); 
    const [done, setDone] = React.useState(false); 

    const refs = {
        timeout: React.useRef()  
    }; 

    React.useEffect(() => { 
        clearTimeout(refs.timeout.current); 

        if (showInitialLoading) {
            setShow(true); 

            setTimeout(() => {
                setStart(true); 
            }, 140);
        } 
        else {
            setDone(true); 

            let timeout: NodeJS.Timeout// todo: check this
            timeout = setTimeout(() => {
                setShow(false); 
            }, 1500);
        }
    }, [showInitialLoading]); 

    if (!show) return null; 

    return (
        <Root
            className={[className, 'InitialLoading'].join(' ')}  
        >
            <Header>
                <Logo />
            </Header>

            <Logos>
                <IconLogo /> 

                <IconLogo /> 
            </Logos> 

            <WrapperLoader>
                <Loader $start={start} $done={done} />
            </WrapperLoader>
        </Root>
    );
}

const Root = styled.div`
    position: fixed; 
    inset: 0; 
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
    gap: 28px;
    background-color: ${styles.colors.uiBackground};
    z-index: 1400014; 
`;
const Header = styled.div`
    position: absolute;
    top: 0;
    height: 66px;
    width: 100%;
    padding-inline: 20px 16px;
    border-bottom: 2px solid rgb(218, 224, 232);
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
`;
const Logos = styled.div`
    position: relative;
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 56px;

    @keyframes animateLogoLines {
        0% {
            stroke-dashoffset: 0; 
        }

        50% {
            stroke-dashoffset: -204.8818359375;
        }

        100% {
            stroke-dashoffset: 0; 
        }
    }

    & svg {
        position: absolute; 

        &:nth-child(1) {
             & path {
                stroke: ${styles.colors.primary200};
             }
        }

        &:nth-child(2) {
             & path {
                stroke-dasharray: 204.8818359375;
                stroke-dashoffset: -204.8818359375;

                stroke: ${styles.colors.primary600};
                
                animation: animateLogoLines 3s ease-out infinite; 
             }
        }
    }
`;

const WrapperLoader = styled.div` 
    position: relative; 
    width: 162px;
    height: 6px; 
    background: ${({ theme: { secondaryColor } }) => secondaryColor};
    border-radius: 140px; 
`;

const Loader = styled.div<ILoaderProps>` 
    position: absolute; 
    top: 0;
    left: 0; 
    width: 10%;
    height: 6px; 
    background: linear-gradient(90deg, #5964FF 0%, #05CCD1 100%);
    border-radius: 140px;  
    transition: width 7s;  

     ${({ $start }) => $start ? `width: 90%;` : ``}
     ${({ $done }) => $done ? `width: 100%; transition: width 1.4s;` : ``}
`;

export default InitialLoading;