import React, { FunctionComponent, ReactNode } from "react"
import { Dropdown } from "react-bootstrap"
import styled from "styled-components"
import { styles } from "../../styles/themes/style";

interface IStyledDropdownItemProps {
    $biggerPadding?: boolean,
    $nonHoverable?: boolean,
    $borderBottom?: boolean,
    $marginTop?: boolean,
    $marginBottom?: boolean, //todo: define these to be sent as values instead of booleans
    $danger?: boolean,
    className?: string,
    $disabled?: boolean
}

interface IDropdownItemProps extends IStyledDropdownItemProps {
    children?: ReactNode,
    onClickHandler?: (e: any) => void //we do not need to be very strict here, it can depend on the element etc
}
const DropdownItem: FunctionComponent<IDropdownItemProps> = ({ children, onClickHandler, $disabled, ...props }) => {
    return (
        <StyledDropdownItem $disabled={$disabled} onClick={ !$disabled && onClickHandler} {...props}>
            {children}
        </StyledDropdownItem> /* todo: add more options such as children */
    )
}

const StyledDropdownItem = styled(Dropdown.Item) <IStyledDropdownItemProps>`
    line-height: 20px;
    padding: 6px 15px;
    font-family: NunitoSans;
    font-size: 14px;
    color: ${styles.colors.primary600};
    .svg .path {
        fill: ${styles.colors.black300};
    }
    font-weight: bold;
    ${({ $danger }) => $danger && `color: ${styles.colors.red600};
        .svg .path {
            fill: ${styles.colors.red600};
        }
    `}

    ${({ $nonHoverable, $danger, theme: { primaryColor, senaryColor } }) => $nonHoverable && `&:hover, &:focus  { 
        opacity: 1;
        color: ${primaryColor};
        .svg .path {
            fill: ${primaryColor};
        }
        background-color: transparent;
        ${$danger && `color: ${styles.colors.red400};
            .svg .path {
                fill: ${styles.colors.red400};
            }
        `}
    };`}

    ${({ $nonHoverable, $danger, theme: { senaryColor } }) => !$nonHoverable && `&:hover, &:focus  { 
        opacity: 1;
        color: ${styles.colors.primary600};
        .svg .path {
            fill: ${styles.colors.primary600};
        }
        background-color: ${senaryColor};
        ${$danger && `color: ${styles.colors.red500};
            .svg .path {
                fill: ${styles.colors.red500};
            }
        `}
    };`}

    ${({ $disabled }) => $disabled && `
        cursor: default;
        &, &:hover, &:focus{
            cursor: default;
            color: ${styles.colors.disabledState};
            .svg .path {
                fill: ${styles.colors.disabledState};
            }
            background-color: transparent;
        }
    `}

    ${({ $biggerPadding }) => $biggerPadding && `padding: 16px 20px;`}
    ${({ $borderBottom, theme: { tertiaryColor } }) => $borderBottom && `border-bottom: 2px solid ${tertiaryColor};`}
    ${({ $marginBottom }) => $marginBottom && `margin-bottom: 10px;`}
    ${({ $marginTop }) => $marginTop && `margin-top: 10px;`}
`

export default DropdownItem