import { FunctionComponent } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Calendar from 'react-calendar';
import { styles } from "../styles/themes/style"
import DropdownMenu from './dropdown/DropdownMenu';
import Row from './Row';
import DropdownItem from './dropdown/DropdownItem';
import Text from './Text';
import FilterDropdownButton from '../fragments/dropdowns/FilterDropdownButton';

interface DateConnectedFilterProps {
  title: string,
  dateFrom: any,
  dateTo: any,
  dateFromValueChangedHandler: (e: any) => void,
  dateToValueChangedHandler: (e: any) => void,
  resetFilter: () => void,
}

const DateComponent: FunctionComponent<DateConnectedFilterProps> = ({title, dateFrom, dateTo, dateFromValueChangedHandler, dateToValueChangedHandler, resetFilter, ...props}) => {

    const dateChangedHandler = (fromOrTo: 'from' | 'to', event: Date[]) => {
        if (fromOrTo === 'from') {
            dateFromValueChangedHandler(event[0]);
        } else {
            dateToValueChangedHandler(event[1]);
        }
    };
    //hover state on menu item when non hoverable should be turned off, also the placeholder colors, and overflow

    const dropdownButton = <FilterDropdownButton
        svgType='calendar' 
        placeholder={dateFrom || dateTo ? <>
                <Text $black>{dateFrom ? moment(dateFrom).format('MMM D, YYYY') : 'From Date'}</Text>
                - 
                <Text $black>{dateTo ? moment(dateTo).format('MMM D, YYYY') : 'To Date'}</Text>
        </> : <>{ title }</>}
        resetFilter={(dateFrom || dateTo) ? () => resetFilter() : undefined}
    />

    return (
            <DropdownMenu 
                title={dropdownButton}
                autoClose='outside'
                selectStyle
                $hideToggle={dateFrom || dateTo}
            >
                <DropdownItem $nonHoverable>
                    <Row>
                        <LeftSide>
                            <Label>From Date</Label>
                            <StyledCalendar
                                value={!dateTo ? dateFrom : [dateFrom, dateTo]}
                                onChange={event => dateChangedHandler('from', event as Date[])}
                                showNeighboringMonth={false}
                                minDate={new Date(1900, 4, 5)}
                                maxDate={dateTo || new Date()}
                                returnValue='range'
                                defaultActiveStartDate={!dateFrom ? new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()) : dateFrom}
                                locale='en-US' 
                            />
                        </LeftSide>
                        <RightSide>
                            <Label>To Date</Label>
                            <StyledCalendar
                                value={!dateTo ? null : [dateFrom, dateTo]}
                                onChange={event => dateChangedHandler('to', event as Date[])}
                                showNeighboringMonth={false}
                                minDate={dateFrom || new Date(1900, 4, 5)}
                                maxDate={new Date(2050, 4, 5)}
                                returnValue='range'
                                defaultActiveStartDate={dateTo && dateTo}
                                locale='en-US' 
                            />
                        </RightSide>
                    </Row>
                </DropdownItem>
            </DropdownMenu>
    );
}


const Input = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    height: 34px;
    border: none;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;

    & .calendar-svg {
        top: 8px;
        left: 9px;
        &:hover path { fill: ${styles.colors.primary600}; }
    }

    & .clear-svg {
        top: 9px;
        right: 9px;
        path { fill: ${styles.colors.red600 }}
        &:hover path { fill: ${styles.colors.red500}; }
    }
`;

const StyledText = styled(Text)`
    flex: 1;
`

const Side = styled.div`
    height: 232px;
    width: 240px;
    display: inline-block;
    vertical-align: top;
`;

const LeftSide = styled(Side)`
    margin-right: 27px;
`;

const RightSide = styled(Side)``;

const Label = styled.p`
    line-height: 16px;
    font-family: NunitoSans;
    font-size: 11px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${styles.colors.black600};
    margin: 0 0 12px;
`;
//todo: maybe reuse these styles since we have another calendar component
const StyledCalendar = styled(Calendar)`
    height: 204px;
    width: 240px;
    font-family: NunitoSans;
    font-size: 14px;
    font-weight: bold;
    color: ${styles.colors.black600};

    & abbr { text-decoration: none; }

    & button {
        border: none;
        background-color: transparent;

        &:disabled, &:disabled:hover { color: ${props => props.theme.primary ? '#b8bfcf' : '#7e8087'}; }
        &:not(:disabled) {
            color: ${props => props.theme.primary ? '#333951' : '#E8E8E8'};
            cursor: pointer;
        }       
    }

    & .react-calendar__navigation {
        height: 20px;
        margin-bottom: 10px;
        display: flex;
    }

    & .react-calendar__navigation__label {
        font-weight: bold;
        color: ${styles.colors.black600};
        display: flex;
        justify-content: center;
    }

    & .react-calendar__navigation__arrow {
        line-height: 20px;
        font-size: 20px;
        font-weight: bold;
        color: #8c96ad;
    }

    & .react-calendar__viewContainer {
        height: 174px;
    }

    & .react-calendar__month-view__weekdays {
        height: 20px;
        margin-bottom: 4px;
        align-items: center;
    }

    & .react-calendar__month-view__weekdays__weekday {
        text-align: center;
    }

    & .react-calendar__month-view__days {
        height: 150px;
    }

    & .react-calendar__tile {
        height: 28px;
        width: 33px;
        margin-bottom: 2px;

        &:hover {color: #5964FF;}
    }

    & .react-calendar__tile--active {        
        background-color: ${props => props.theme.primary ? '#eef3ff' : '#373945'};
        color: ${props => props.theme.primary ? '#333951 !important' : '#E8E8E8 !important'};

        &:hover { background-color: ${props => props.theme.primary ? '#F6FBFF' : '#2A2D3A'}; }
    }

    & .react-calendar__tile--rangeStart, & .react-calendar__tile--rangeEnd {
        border-radius: 6px;
        background-color: #5964ff;
        color: ${props => props.theme.primary ? 'white !important' : '#E8E8E8 !important'};

        &:hover {
            background-color: #7a89fc;
            color: ${props => props.theme.primary ? '#333951' : '#E8E8E8'};
        }
    }
`;
export default DateComponent;
