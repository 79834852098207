import { FunctionComponent } from "react";
import styled from "styled-components";
import img from "../assets/img/spinner/Spin-1s-200px_gray.svg"

interface ISpinner {
    showHideSpinner: boolean
}

const Spinner: FunctionComponent<ISpinner> = ({ showHideSpinner }) => {
    return (
        <>
            {showHideSpinner && <Container>
                <img src={img} alt='spinner' />
            </Container>}
        </>
    )
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
`

export default Spinner