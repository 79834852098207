import React, { FormEvent, FunctionComponent, useCallback, useState } from 'react'
import styled from 'styled-components'
import Column from '../../../../../components/Column'
import Row from '../../../../../components/Row'
import Text from '../../../../../components/Text'
import Button from '../../../../../components/Button'
import { useAuthHandlers } from '../../../../../services/actions/auth/useAuthHandlers'
import { useAuthStore } from '../../../../../stores/auth/useAuthStore'
import moment from 'moment'
import InputNumber from '../../../../../components/InputNumber'
import debounce from 'lodash.debounce'
import { useAppHandlers } from '../../../../../services/actions/app/useAppHandlers'

interface IBackgroundUpdateProps { }

const BackgroundUpdate: FunctionComponent<IBackgroundUpdateProps> = () => {

    const { store: { user } } = useAuthStore()
    const { syncConnectionsHandler, saveUserPreferencesHandler } = useAuthHandlers()
    const { setSyncConnectionsModalHandler } = useAppHandlers()
    const setLastSyncAt = useCallback(async() => {
        if(!user?.isSynced) setSyncConnectionsModalHandler((user) => syncConnectionsHandler(user))
        else syncConnectionsHandler(user)
    }, [user])

    const setUpdateLimit = debounce(async (value: number) => {
        await saveUserPreferencesHandler({ dailyUpdateLimit: value })
    }, 500)

    const lastSyncAtLocalStorage = window.localStorage.getItem('lastSyncAt')
    const lastSyncAt = lastSyncAtLocalStorage && lastSyncAtLocalStorage !== 'undefined' ? lastSyncAtLocalStorage : 0

    return <Column gap="40px">
    <StyledRow spaceBetween gap='40px'>
        <Column gap='16px'>
            <Text $label $capitalize>{'Manual sync'}</Text>
            <Text $lighter>
            Manually sync your Connections. “Sync” synchronizes the latest state from LinkedIn to LeadDelta (i.e. NEW connections, removed connections, etc.).
            </Text>
            {lastSyncAt !== 0 && <Text $lighter>{`Last sync at ${moment(lastSyncAt).format('MMM D, YYYY')}`}</Text>}
        </Column>
        <Button $type="grey" $extraBigButton type="submit" $SVGtype='refresh' disabled={moment().isBefore(moment(lastSyncAt).add(15, 'minutes'))} onClickHandler={()=>setLastSyncAt()}>Sync connections</Button>
    </StyledRow>
    <StyledRow spaceBetween gap='40px'>
        <Column gap='16px'>
            <Text $label $capitalize>{'24-hour limit'}</Text>
            <Text $lighter>
            {'The number of connections to be updated (automatically or manually) within 24hrs every time you log into LeadDelta. This limit is set for your own safety and should be used to mimic your regular LinkedIn usage.'}
            </Text>
            <Text $lighter>{`Increasing the recommended limit can lead to triggering LinkedIn automation tools warning.`}</Text>
        </Column>
        <InputNumber prefilledValue={user?.preferences?.dailyUpdateLimit ?? 25} minValue={0} onChangeHandler={(value: number) => setUpdateLimit(value)}/>
    </StyledRow>
</Column>
}

const Container = styled.div`
    width: 100%;
    padding: 20px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow-y:auto;
    height: 100%;
`
const StyledRow = styled(Row)`

    @media (max-width: 786px) {
        flex-direction: column;
        align-items: normal;
        gap: 10px;
    }
`
export default BackgroundUpdate
