import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import TableCell from '../../../components/table/TableCell'
import TableData from '../../../components/table/TableData'
import TableHeader from '../../../components/table/TableHeader'
import TableRow from '../../../components/table/TableRow'
import Tag from '../../../components/Tag'
import Text from '../../../components/Text'
import { IStripePaymentMethod } from '../../../interfaces/models/user'
import { useAuthStore } from '../../../stores/auth/useAuthStore'
import { styles } from "../../../styles/themes/style";
import sepaImage from '../../../assets/img/billing/sepa.png'
import cardImage from '../../../assets/img/billing/card.png'
import Table from '../../../components/Table'
import Checkbox from '../../../components/Checkbox'
import Row from '../../../components/Row'
import { calculateColumnWidth } from '../../../utils'
import useMediaQuery from '../../../components/useMediaQuery'

interface IPaymentMethodsProps { 
    onChangeHandler?: (e: string) => void
    $height?: string
}

const PaymentMethods: FunctionComponent<IPaymentMethodsProps> = ({ onChangeHandler, $height }) => {
    const tableRef = useRef<HTMLDivElement>(null)

    const isSmall = useMediaQuery("(max-width: 768px)");

    const { store: { subscription, user } } = useAuthStore()//todo check sepa representation

    const [ paymentMethods, setPaymentMethods ] = useState<IStripePaymentMethod[]>([])
    const [ selectedMethod, setSelectedMethod ] = useState<IStripePaymentMethod | undefined>(undefined)

    useEffect(() => {
        let paymentMethods = []
        if(subscription?.paymentMethods?.length > 0) paymentMethods = subscription.paymentMethods
        //if(user?.stripePaymentMethods?.length > 0) paymentMethods = user.stripePaymentMethods //this should only be read from the  subscription

        setPaymentMethods(paymentMethods)
        if(paymentMethods.length) onSelectHandler(paymentMethods.find((paymentMethod: IStripePaymentMethod) => paymentMethod.isDefault))
    }, [subscription, user])

    //constants
    const cardImg = <img
        src={cardImage}
        alt=''
    />
    const sepaImg = <img
        src={sepaImage}
        alt=''
    />

    const [columns, setColumns] = useState([
        { header: 'Type', key: ['image'], show: true, showSmall: true },
        { header: 'Brand', key: ['branding'], width: '100%', show: true, showSmall: true },
        { header: 'Default', key: ['default'],  width: '100px', show: true, showSmall: true },
    ])

    useEffect(() => {
        const offsetWidth = tableRef.current?.offsetWidth

        const adjustedColumns = calculateColumnWidth(offsetWidth, columns, isSmall) 
        setColumns(adjustedColumns)

    }, [paymentMethods, isSmall])

    const onSelectHandler = (paymentMethod: IStripePaymentMethod) => {
        setSelectedMethod(paymentMethod)
        onChangeHandler && onChangeHandler(paymentMethod.id)
    }

    return (
        <Container>
                <Table
                ref={tableRef}
                $height={$height}
                columns={[
                    ...onChangeHandler ? [{
                        header: "",
                        key: ['checkbox'],
                        width: '36px'
                    }] : [],
                    ...columns
                ]}
                noDataPage={<Text $bold $heading5 $lighter>You don`t have any payment methods.</Text>}
                data={paymentMethods?.sort((a: any, b: any) => {
                    if(a.isDefault) return -1
                    if(b.isDefault) return 1
                    return 0
                }).map((paymentMethod: IStripePaymentMethod) => {
                    return (
                        {
                            object: { ...paymentMethod },
                            ...onChangeHandler && { checkbox: (
                                <StyledCheckbox
                                    checked={selectedMethod ? selectedMethod.id === paymentMethod.id :  paymentMethod.isDefault}
                                    checkboxId={paymentMethod.id}
                                    onClickHandler={(e: any) => { e.stopPropagation(); onSelectHandler(paymentMethod)}}
                                />
                            ) },
                            ...onChangeHandler && { onRowClick: () => onSelectHandler(paymentMethod) },
                            image: (
                                <Row alignItems><StyledImage>{paymentMethod.type === 'sepa_debit' ? sepaImg : cardImg}</StyledImage> <Text $capitalize $bold $black>{paymentMethod.type}</Text></Row>
                            ),
                            branding: (
                                <Text $black><Text $black $capitalize>{paymentMethod.brand === 'sepa_debit' ? 'sepa' : paymentMethod.brand}</Text> ending with {paymentMethod.last4}</Text>
                            ),
                            default: (
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>{paymentMethod.isDefault && <StyledTag><Tag title={'Default'} $borderColor={styles.colors.green600} $color={styles.colors.green200} SVGtype={'checkmark'} /></StyledTag>}</div>
                            )
                        }
                    )
                })}
            />
        </Container>
    )
}
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
`
const StyledCheckbox = styled(Checkbox)`
    height: fit-content;
`
const StyledTag = styled.div`
    background-color: ${styles.colors.green200};

    & svg {
        width: 20px;
        height: 16px
    }
`
const StyledImage = styled.div`
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
`


export default PaymentMethods