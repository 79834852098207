import { FunctionComponent, useEffect, useState, ReactElement } from "react";
import { styles } from "../../../styles/themes/style";
import { useSearchParams } from 'react-router-dom'
import styled from "styled-components";
import Text from "../../../components/Text";
import SvgFragment from "../../../fragments/SvgFragment";
import { useAuthStore } from "../../../stores/auth/useAuthStore";
import { useAppHandlers } from "../../../services/actions/app/useAppHandlers";

enum InfoActionType {
    LIFETIME_USER = "LIFETIME_USER",
    TEAM_MEMBER = "TEAM_MEMBER"
}

const InfoSection: FunctionComponent = () => {//todo: check and remove
    const [searchParams, setSearchParams] = useSearchParams()
    const { store: { lifetime, workspaceOwner, context, authData: { extensionId } } } = useAuthStore()
    const { openExtension } = useAppHandlers()
    const [infoToShow, setInfoToShow] = useState<ReactElement>(<></>)

    const actionText = <span className="action_text" onClick={() => openExtension()}> LeadDelta!</span>

    useEffect(() => {
        const message = searchParams.get('message') ?? undefined
        message && setInfoMessage(message)
    }, [searchParams])
    const setInfoMessage = (message: string) => {
        switch (message) {
            case InfoActionType.LIFETIME_USER:
                setInfoToShow(
                    <Wrapper>
                        <Text $heading1 $lighter>Thank you for being our lifetime user!</Text>
                        <Text $heading1 $lighter>Continue using {actionText}</Text>
                    </Wrapper>
                )
                break
            case InfoActionType.TEAM_MEMBER:
                setInfoToShow(
                    <Wrapper>
                        <Text $heading1 $lighter>You are here as a team member.</Text>
                        <Text $heading1 $lighter><span>In order to see our plans, come here by logging into</span></Text>
                        <Text $heading1 $lighter><span>your personal account or create a workspace on {actionText}</span></Text>
                    </Wrapper>
                )
                break
            default:
                setInfoToShow(
                    <Wrapper>
                        <Text $heading1 $lighter>Thank you for being our loyal user</Text>
                        <Text $heading1 $lighter><span>Please try out our app {actionText}</span></Text>
                    </Wrapper>
                )
                break
        }
    }

    useEffect(() => {
        lifetime ? setInfoMessage(InfoActionType.LIFETIME_USER)
            : !workspaceOwner && context === 'workspace' ? setInfoMessage(InfoActionType.LIFETIME_USER)
                : setInfoMessage("default")
    }, [lifetime, workspaceOwner, context])
    return (
        <Container>
            <Checkmark><SvgFragment type='checkmark' /></Checkmark>
            {infoToShow}
        </Container>
    )
}

const Container = styled.div`
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;

    & .action_text {
        color: ${styles.colors.primary600};
        font-weight: bold;

        &:hover {
            cursor: pointer;
            color: ${styles.colors.primary400}
        }
    }
`
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Checkmark = styled.div`
    width: 86px;
    height: 86px;
    background-color: ${styles.colors.green600};
    border-radius: 50%;
    svg path {
        fill: ${styles.colors.green200};
    }
`
export default InfoSection