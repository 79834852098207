import { usePabblyWebhook } from "../../apis/pabbly/usePabblyWebhook";
import { useAppHandlers } from "../app/useAppHandlers";

export const usePabblyHandlers = () => {
    const { getPabblyWebhooks, deletePabblyWebhook, createPabblyWebhook, editPabblyWebhook } = usePabblyWebhook()
    const { showSpinner, hideSpinner } = useAppHandlers()

    const getPabblyWebhooksHandler = async () => {
        const result = await getPabblyWebhooks()
        if(result) return result
    }
    
    const createPabblyWebhookHandler = async (payload: any) => {
        await createPabblyWebhook(payload)
    }

    const editPabblyWebhookHandler = async (payload: any) => {
        await editPabblyWebhook(payload)
    }

    const deletePabblyWebhooksHandler = async (id: any) => {
        showSpinner()
        await deletePabblyWebhook(id)
        hideSpinner()
    }

    return {
        getPabblyWebhooksHandler,
        deletePabblyWebhooksHandler,
        editPabblyWebhookHandler,
        createPabblyWebhookHandler
    };
}
