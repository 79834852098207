import { FormEvent, FunctionComponent, useCallback, useEffect } from 'react'
import styled, { DefaultTheme, useTheme } from "styled-components"
import Button from '../../../components/Button';
import { useAuthHandlers } from '../../../services/actions/auth/useAuthHandlers';
import { useExternalHandlers } from '../../../services/actions/navigation/useExternalHandlers';
import ActionText from '../../../components/ActionText';

interface IExtensionInstallProps { }


const ExtensionInstall: FunctionComponent<IExtensionInstallProps> = () => {
    const theme: DefaultTheme = useTheme()
    const { saveOnboardingData } = useAuthHandlers()
    const { visitChromeStore } = useExternalHandlers()



    const skipExtensionInstalationClick = useCallback(async () => {
                await saveOnboardingData({
                startedExtensionInstallation: false,
            })
	},[]); 

    const visitChromeStoreClick = useCallback(async () => {
    await Promise.all([
        saveOnboardingData({
            startedExtensionInstallation: true,
        }),
        (async () => visitChromeStore())(),
    ]);
	},[]); 
    

    return <Container>
        <StyledForm>
            <Button $type='blue' $SVGtype={'openNewTab'} onClickHandler={()=>visitChromeStoreClick()}>{'Download LeadDelta App'}</Button>
            <CenterContainer><ActionText faded onClickHandler={()=> {skipExtensionInstalationClick()}}>{'I’ll do this later'}</ActionText></CenterContainer>
        </StyledForm>
    </Container>
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    max-width: 600px;
    padding: 5px;
    overflow-y: auto;
`
const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const CenterContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 600px;
    text-align: center;
`


export default ExtensionInstall