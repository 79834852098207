import { DefaultTheme } from "styled-components";

export const styles = {
  lengths: {
    navbarHeight: "64px",
    railWidth: "58px",
  },
  colors: {
    //Main colors
    black600: "#333951",
    primary600: "#5964FF",
    turquise600: "#05CCD1",
    blue600: "#03C9F7",
    red600: "#FF6760",
    green600: "#16D090",
    yellow600: "#FDBE00",
    orange200: "#FFF1E1",
    orange600: "#FF9100",
    neutral600: "#414042",
    white: "#FFFFFF",
    disabledState: "#B8BFCF",
    uiBackground: "#EBEFF4",
    //Secondary colors
    black500: "#41495E",
    black400: "#545E77",
    black300: "#8C96AD",
    black200: "#DAE0E8",
    black100: "#F6F8FB",

    primary500: "#697BFF",
    primary400: "#7A89FC",
    primary300: "#9FABFF",
    primary200: "#CAD5FF",
    primary100: "#EEF3FF",

    turquoise500: "#1BDDDD",
    turquoise400: "#46E8E0",
    turquoise300: "#6DF7F0",
    turquoise200: "#B9FCF5",
    turquoise100: "#E1FFFD",

    blue500: "#32DDF9",
    blue400: "#64EBF9",
    blue300: "#A1FBFF",
    blue200: "#E3FCFC",

    red500: "#FF8383",
    red400: "#FCA2A2",
    red300: "#FCCACA",
    red200: "#FFEFEE",

    green500: "#34E09E",
    green400: "#68F2BA",
    green300: "#A7FCD7",
    green200: "#E6FAF3",

    yellow300: "#FFECC7",
    yellow200: "#FEF8E7",

    //add missing colors

    neutral500: "#5B5B5B",
    neutral400: "#828282",
    neutral300: "#C3C2C4",
    neutral200: "#EFEFEF",

    //Dark mode //change names
    darkBlack600: "#2A2D3A",
    darkUiBackground: "#232531",
    black: "#000000",
    darkTextcolor: "#E8E8E8",

    //Dark mode secondary colors
    darkblack500: "#373945",
    darkblack400: "#424551",
    darkblack300: "#444756",
    darkblack200: "#4E515C",
    darkblack100: "#505464",
  },
};

export const LightTheme: any = {
  themeName: "LightTheme",
  primary: true,
  //Colors
  primaryColor: styles.colors.black600,
  secondaryColor: styles.colors.white,
  tertiaryColor: styles.colors.uiBackground,
  quaternaryColor: styles.colors.black300,
  quinaryColor: styles.colors.black200,
  senaryColor: styles.colors.black100,

  //Exceptions (in the design but should be used with the same principle)
  septenaryColor: styles.colors.black600,
  octonaryColor: styles.colors.uiBackground,
  nonaryColor: styles.colors.black400,

  //missing colors
  primary600: styles.colors.primary600,
  disabledState: styles.colors.disabledState,
};

export const DarkTheme: any = {
  themeName: "DarkTheme",
  primary: false,
  //Colors
  primaryColor: styles.colors.darkTextcolor,
  secondaryColor: styles.colors.darkblack500,
  tertiaryColor: styles.colors.darkUiBackground,
  quaternaryColor: styles.colors.darkblack300,
  quinaryColor: styles.colors.darkblack500,
  senaryColor: styles.colors.darkblack300,

  //Exceptions (in the design but should be used with the same principle)
  septenaryColor: styles.colors.black,
  octonaryColor: styles.colors.darkblack200,
  nonaryColor: styles.colors.white,

  //missing colors
  primary600: styles.colors.primary600,
  disabledState: styles.colors.disabledState,
};

export const themes = {
  light: LightTheme,
  dark: DarkTheme,
};
