import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { formatNumber } from '../utils';
import Column from '../components/Column';
import Text from '../components/Text';
import { styles } from '../styles/themes/style';
import LoaderCell from '../components/LoaderCell';
import SvgFragment from './SvgFragment';

interface IStatBoxProps {
    svgType: string,
    statistic: number,
    title: string,
    onClickHandler?: (e: any) => void,
    loading?: boolean
}

const StatBox: FunctionComponent<IStatBoxProps> = ({ svgType, statistic, title, onClickHandler, loading }) => {
    
    return (
        <Container onClick={onClickHandler}>
            { loading ? 
                <>
                    <LoaderCell $width='66px' $height='66px' $circle={true}/>
                    <Column gap='20px'>
                        <LoaderCell $width='120px' $height='120px'/>
                        <LoaderCell $width='75px' $height='12px'/>
                    </Column>
                </> : 
            <>
                <SvgFragment type={svgType}/>
                <Column>
                    <Number className="hoverableText"> {formatNumber(statistic) || 0} </Number>
                    <Text $faded $label> {title} </Text>
                </Column>
            </>}
        </Container>
    )
}
const Container = styled.div`
    flex: 1 1 auto; 
    min-height: 90px;
    min-width: 190px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 20px;
    background: ${({ theme: { secondaryColor }}) => secondaryColor};
    border-radius: 16px;
    border: 2px solid ${({ theme: { secondaryColor }}) => secondaryColor};
    transition: all .2s ease;
    &:hover {
        border: 2px solid ${styles.colors.primary600};
        cursor: pointer;
        .hoverableText {
            color: ${styles.colors.black600};
        }
    }
    & .svg .path{
        fill: ${({ theme: { primary }}) => primary ? styles.colors.primary100 : styles.colors.darkblack400 };
    } 
`
const Number = styled.span`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: ${styles.colors.primary600};
    transition: all .2s ease;
`
export default StatBox