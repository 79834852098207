// Tooltip.tsx
import React, { FunctionComponent } from 'react';
import { Tooltip, OverlayTrigger, OverlayTriggerProps } from 'react-bootstrap';
import styled from 'styled-components'

interface TooltipWrapperProps extends Partial<OverlayTriggerProps> {
  tooltipText: string;
  placement?: 'auto' | 'top' | 'bottom' | 'left' | 'right';
  children: React.ReactElement;
}

interface IStyledTooltipStyles {
  $disabled?: boolean
}

const TooltipWrapper: FunctionComponent<TooltipWrapperProps> = ({ tooltipText, placement, children, ...props }) => {
  const renderTooltip = (tooltipProps: any) => (
    <StyledTooltip $disabled={tooltipText.length === 0} {...tooltipProps}>
      {tooltipText}
    </StyledTooltip >
  );

  return (
    <OverlayTrigger
      flip={true}
      placement={placement || 'top-start'}
      delay={{ show: 0, hide: 0 }}
      overlay={renderTooltip}
      {...props}
    >
      {children}
    </OverlayTrigger>
  );
};

const StyledTooltip = styled(Tooltip)<IStyledTooltipStyles>`
  ${({$disabled}) => $disabled && `
    display: none;
  `}

  .tooltip-arrow::before{
    display: none;
  }
  &.tooltip {
    position: fixed;
    z-index: 1000001;
    border-radius: 3px;
    padding: 1px;
    // text-transform: capitalize;
    
    .tooltip-inner {
      padding: 2px;
      font-size: 12px;
      background-color: ${({ theme: { septenaryColor } }) => septenaryColor};
      color:${({ theme: { secondaryColor } }) => secondaryColor};
      // text-transform: capitalize;
    }
`;

export default TooltipWrapper;
