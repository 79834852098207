import React, { FormEvent, FunctionComponent, ReactNode, useCallback, useState } from 'react'
import styled from 'styled-components'
import Text from '../../components/Text'
import { styles } from '../../styles/themes/style'
import SvgFragment from '../SvgFragment'

interface IFilterDropdownButtonProps { 
    svgType?: string
    placeholder?: ReactNode,
    selectValue?: string,
    resetFilter?: () => void
}

const FilterDropdownButton: FunctionComponent<IFilterDropdownButtonProps> = ({ svgType, placeholder, selectValue, resetFilter }) => {
    return <Container>
                {svgType && <Icon><SvgFragment type={svgType}/></Icon>}
                <StyledText $lighter>
                    {placeholder}
                </StyledText>
            {selectValue && <StyledText $bold $lighter>{selectValue}</StyledText>}
            {resetFilter &&
            <svg onClick={() => resetFilter()} className='clear-svg' width="12" height="12" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.96 11.825 6 7.785l4.04 4.04a.6.6 0 0 0 .846 0l.94-.939a.6.6 0 0 0 0-.847L7.785 6l4.039-4.04a.6.6 0 0 0 0-.846l-.939-.939a.6.6 0 0 0-.847 0L6 4.215 1.96.175a.6.6 0 0 0-.846 0l-.939.939a.6.6 0 0 0 0 .847L4.215 6 .175 10.04a.6.6 0 0 0 0 .847l.94.938a.6.6 0 0 0 .846 0z" fill="#8C96AD" fillRule="evenodd"/>
            </svg>}
    </Container>
}

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    height: 34px;
    border: none;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
    background-color: transparent;

    & .clear-svg {
        top: 9px;
        right: 9px;
        path { fill: ${styles.colors.red600 }}
        &:hover path { fill: ${styles.colors.red500}; }
    }  
`
const Icon = styled.div`
    & svg {
        top: 8px;
        left: 9px;
        &:hover path { fill: ${styles.colors.primary600}; }
    }
`

const StyledText = styled(Text)`
    flex: 1;
`

export default FilterDropdownButton
