import { FunctionComponent, useState } from 'react'
import CustomModal from '../../CustomModal'
import ActionText from '../../../ActionText'
import { useExternalHandlers } from '../../../../services/actions/navigation/useExternalHandlers'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers';
import Row from '../../../Row'
import Text from '../../../Text'
import Checkbox from '../../../Checkbox'
import styled from 'styled-components'

interface ISyncConnectionsModalProps {
    onClickHandler: () => void
}

const SyncConnectionsModal: FunctionComponent<ISyncConnectionsModalProps> = ({ onClickHandler }) => {

    const { openPermissionHandler } = useExternalHandlers()
    const { hideModal } = useAppHandlers()
    const [checkboxChecked, setCheckboxChecked] = useState(false)

    const description = `To make the most out of LeadDelta, we need your consent to sync your LinkedIn First Degree Connections. Learn more about permissions` 
    return (
        <CustomModal
            logo
            closeButton
            descriptionText={<StyledText>{description}<StyledActionText onClickHandler={() => openPermissionHandler()}>here</StyledActionText></StyledText>}
            descriptionTextBold={<StyledText>{`LeadDelta is your CRM for managing professional connections efficiently. With features like Tags, Notes, Filters, and the ability to co-share with your circles, you're set to transform your networking experience.`}</StyledText>}
            confirmButton={{
                text: 'Initiate Sync Now',
                bigButton: true,
                disable: !checkboxChecked,
                onClickHandler: () => checkboxChecked && (onClickHandler(), hideModal())
            }}>
                <Row gap="10px">
                    <Checkbox checked={checkboxChecked} checkboxId={'sync'} onClickHandler={() => setCheckboxChecked(!checkboxChecked)} />
                    <Text>{'I understand and give permission to LeadDelta to sync my LinkedIn Connections'}</Text>
                </Row>
            </CustomModal>
    )
}

const StyledText =  styled(Text)`
    display: inline-block;
    text-align: left;
    font-weight: normal;
`
const StyledActionText = styled(ActionText)`
    display: inline-block;
    margin-left: 3px;
`

export default SyncConnectionsModal