import { useAxios } from "../useAxios"
import { useAuthStore } from "../../../stores/auth/useAuthStore"


export const useHubspotWebhook = () => {
    const { sendRequest } = useAxios()

    const route = '/hub-spot'

    const getHubspotAccessToken = (payload?: any) => {
        return sendRequest({
            slug: 'get-hubspot-access-token',
            url: `${route}/access-token/me`,
            method: 'GET',
            payload
        })
    };

    const addHubspotAccessToken = (payload: any) => {
        console.log('payload', payload)
        return sendRequest({
            slug: 'add-hubspot-access-token',
            url: `${route}/access-token`,
            method: 'POST',
            payload
        })
    };

    const removeHubspotAccessToken = (id?: any) => {
        return sendRequest({
            slug: 'remove-hubspot-access-token',
            url: `${route}/access-token/${id}`,
            method: 'DELETE'
        })
    };

    const getHubspotTriggers = (payload?: any) => {
        return sendRequest({
            slug: 'get-hubspot-triggers',
            url: `${route}/triggers`,
            method: 'GET',
            payload: {
                page: 1,
                pageSize: 50,
                sort: 'newest',
                total: true
            }
        })
    };

    const addHubspotTrigger = (payload?: any) => {
        return sendRequest({
            slug: 'add-hubspot-trigger',
            url: `${route}/triggers`,
            method: 'POST',
            payload
        })
    };

    const editHubspotTrigger = (payload?: any) => {
        return sendRequest({
            slug: 'edit-hubspot-trigger',
            url: `${route}/triggers/${payload.id}`,
            method: 'PUT',
            payload: payload.body
        })
    };

    const removeHubspotTrigger = (id?: any) => {
        return sendRequest({
            slug: 'remove-hubspot-trigger',
            url: `${route}/triggers/${id}`,
            method: 'DELETE'
        })
    };

    return {
        getHubspotAccessToken,
        addHubspotAccessToken,
        removeHubspotAccessToken,
        getHubspotTriggers,
        addHubspotTrigger,
        editHubspotTrigger,
        removeHubspotTrigger
    };
}