import React, { FormEvent, FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import CreatableAsyncSelect from '../components/select/CreatableAsyncSelect'
import { useTagsHandlers } from '../services/actions/tags/useTagsHandlers'
import { useTagsRequest } from '../services/apis/tags/useTagsRequest'
import debounce from 'lodash.debounce'
import Tag from '../components/Tag'
import Row from '../components/Row'
import SvgFragment from './SvgFragment'
import Text from '../components/Text'
import Column from '../components/Column'
import { IConnection, ITag } from '../interfaces/models/connection'
import AsyncSelect from '../components/select/AsyncSelect'
import ProfilePhoto from '../components/ProfilePhoto'
import { useConnectionsRequest } from '../services/apis/connections/useConnectionsRequest'

interface IConnectionAutocompleteSelectProps {
    prefilledValue?: IConnection,
    onChangeHandler?: (e: any) => void
}

interface IEditConnection extends IConnection{
    label: string,
    value: string
}

const ConnectionAutocompleteSelect: FunctionComponent<IConnectionAutocompleteSelectProps> = ({ prefilledValue, onChangeHandler }) => {

    const { getConnections } = useConnectionsRequest();
    const [ value, setValue ] = useState<IEditConnection | undefined>(undefined)

    useEffect(() => {
        /* Esma molim te pogledaj use effect, hvala */
        setValue(prefilledValue ? {
            ...prefilledValue,
            label: `${prefilledValue.firstName} ${prefilledValue.lastName}`,
            value: prefilledValue._id
        } : undefined)
    }, [])

    useEffect(()=>{
        if(value) {
            const {label, value: newValue, ...newConnection} = value
            onChangeHandler &&  onChangeHandler(newConnection)
        }
    }, [value])

    const onChange = useCallback((option: IEditConnection) => {
        if(option) setValue({...option })
        else setValue(undefined)
    }, [value])

    const loadOptionsHandler = debounce(async (inputValue: string, callback: any) => {
        if(inputValue.trim())
        {
            try{
                let connectionData = await getConnections({page: 1, pageSize: 5, searchValues: encodeURIComponent(JSON.stringify([{searchByName: 'yes', searchValue: inputValue}]))  });
                //todo: filter for everything but forbidden

                callback(connectionData?.connections?.map((connection: IConnection) => ({
                    ...connection,
                    label: `${connection.firstName} ${connection.lastName}`,
                    value: connection._id
                })))
            }
            catch(error){
                callback([])
            }
        }    
    }, 500)

    return  <AsyncSelect 
        isSearchable
        isClearable
        placeholder = {'Type connection name...'}
        maxLength={30}
        onChange = {(e: any) => onChange(e)}
        value = {value}
        loadOptions = { loadOptionsHandler }
        components={{
            DropdownIndicator: null,
            Option: (itemProps: any) => {
                return (
                    <StyledOption>
                        <Row gap="6px" alignItems {...itemProps.innerProps}>
                            <ProfilePhoto source={itemProps.data?.profilePicture} />
                            <Column>
                                <Text $label>{`${itemProps.data?.firstName} ${itemProps.data?.lastName}`}</Text>
                                <Text $lighter>{itemProps.data?.email}</Text>
                            </Column>
                        </Row>
                    </StyledOption>
                )
            }, 
            SingleValue: (itemProps: any) => {
                return (
                    itemProps.data?.value ? <Row gap="6px" alignItems>
                        <ProfilePhoto source={itemProps.data?.profilePicture} />
                        <Text $label>{`${itemProps.data?.firstName} ${itemProps.data?.lastName}`}</Text>
                    </Row> : null
                )
            }, 
        }}
    />
}

const CreateLabel = styled(Row)`
    background: transparent;
    padding: 3px;
`
const StyledOption = styled.div`
    padding: 6px 10px;
`

export default ConnectionAutocompleteSelect
