import { ActionType, TNotesAction, TNotesState } from "../../interfaces/store/notesStore";
import { makeStore } from "../makeStore";


const initialNotesParameters = {
    page: 1,
    pageSize: 25,
    hasNext: false, //Not in use
    total: undefined,
    sort: 'newest'
}

const initialNotesFilters = {
    searchByText: '',
    searchByConnectionName: '',
    usersTagged: [], //Not in use
    createdAtTo: '',
    createdAtFrom: '',
    usersFilter: [], //Not in use
    usersSelected: [], //Not in use
    userCondition: 'or',
    selectedNotes: [],
    usersMutualCondition: 'And',
    selectedUsersMutual: [],
    usersTaggedCondition: 'And',
    selectedUsersTagged: [],
    noteAccessType: { value: 'all', label: 'All' }
}

const initialState: TNotesState = {
    notes: [],
    notesParameters: initialNotesParameters,
    notesFilters: initialNotesFilters
}
//dule: initial Notes parameters should be updated as a whole instead of doing it one by one, it makes it easier for the mapping and for the future new fitlers

const reducer = (state: TNotesState, action: TNotesAction): TNotesState => {
    switch (action.type) {
        case ActionType.SET_NOTES: return { ...state, notes: action.payload }
        case ActionType.SET_NOTES_PARAMETERS: return { ...state, notesParameters: { ...state.notesParameters, ...action.payload } }
        case ActionType.SET_INITIAL_NOTES_FILTERS: return { ...state, notesFilters: { ...state.notesFilters, ...action.payload } }
        case ActionType.RESET_NOTES_PAGINATION: return {  ...state, notesParameters: {...initialNotesParameters }, notesFilters: {...initialNotesFilters} }
        case ActionType.RESET_STORE: return { ...initialState }
        default: return state
    }
}

const { StoreProvider: NotesStoreProvider, useStoreContext: useNotesStore } = makeStore(reducer, initialState)

export { NotesStoreProvider, useNotesStore }