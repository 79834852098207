import { FunctionComponent, ReactNode } from "react";
import Column from "./Column";
import Text from "./Text";
import styled from "styled-components";
import Button from "./Button";
import SvgFragment from "../fragments/SvgFragment";

interface IEmptyPageProps {
    title?: string
    label?: string
    svgType?: string
    labelNode?: ReactNode
}

const EmptyPage: FunctionComponent<IEmptyPageProps> = ({title, label, labelNode, svgType}) => {

    return (
        <>
            <SvgFragment type={svgType} />
            <Text $heading3>{title}</Text>
            <Text $lighter $paragraph1>{label}</Text>
            {labelNode}
            {/* <div style={{padding: '0 10px', marginTop: '15px'}}><Button $type="blue">Go to Chrome Web Store</Button></div> */}
        </>
    )
}

export default EmptyPage