import { FunctionComponent } from "react";
import styled from "styled-components";
import { styles } from "../styles/themes/style";
import { ReactComponent as LogoSymbol } from '../assets/img/logo-symbol.svg';
import Column from "../components/Column";
import Text from "../components/Text";
import SvgFragment from "../fragments/SvgFragment";

const UnderConstruction: FunctionComponent = () => {

    return (
        <Container>
            <Header>
                <StyledLogoSymbol />
            </Header>
            <Column gap="20px" alignItems justifyCenter>
                <SvgFragment type="underConstruction" />
                <Text $bold $heading1>Page under construction</Text>
                <Text $lighter $heading4>We're busy adding some serious magic to LeadDelta!</Text>
                <Text $lighter $heading4> Don't worry, your networking superpowers will be back in action soon.</Text>
                <Text $lighter $heading4> Just a few more clicks and caffeine fixes! 🚀☕</Text>
            </Column>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 100px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${styles.colors.white};
`
const Header = styled.div`
    height: ${styles.lengths.navbarHeight};
    width: 100%;
    padding: 0px 16px 0px 0px;
    border-bottom: 2px solid ${({ theme: { quinaryColor } }) => quinaryColor};
    background-color: ${({ theme: { secondaryColor } }) => secondaryColor};
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const StyledLogoSymbol = styled(LogoSymbol)` 
    height: 32px;
    width: auto;
    padding: 0 0 0 20px;
`
export default UnderConstruction