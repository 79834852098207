import { FunctionComponent } from 'react'
import styled from "styled-components"
import CreateWorkspaceForm from '../../../fragments/forms/CreateWorkspaceForm'

interface ITeamSectionProps { }

const TeamSection: FunctionComponent<ITeamSectionProps> = () => {

    return <Container>
        <CreateWorkspaceForm loginWorkspace={true}/>
    </Container>
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    max-width: 600px;
    padding: 5px;
    overflow-y: auto;
`

export default TeamSection