import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { styles } from "../../../styles/themes/style";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../interfaces/store/appStore";

interface IBottomBarCSVProps {
    disabled?: boolean
    onClickHandler?: (e: any) => void
}

const BottomBarCSV: FunctionComponent<IBottomBarCSVProps> = ({ disabled, onClickHandler }) => {
    const navigate = useNavigate() //todo: this is a bad place for this, also no absolute since you can easily put this on the bottom

    return (
        <Container>
            <ButtonWrapper>
                <Button $type="cancel" $mediumButton onClickHandler={() => navigate(AppRoutes.CONNECTIONS)}>Cancel</Button>
                <Button disabled={disabled} $type="blue" $mediumButton onClickHandler={onClickHandler}>Next</Button>
            </ButtonWrapper>

        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    bottom: 0;
`
const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
`
export default BottomBarCSV