import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components'; 

interface IListProps { 
    children?: ReactNode,
	className?: string
}

const List: FunctionComponent<IListProps> = ({ children, ...props }) => {

	return (
		<Container {...props}>
			{children}
		</Container>
	);
}; 

const Container = styled.div`
	display: flex;
	flex-direction: column; 
	gap: 12px; 
	width: 100%;
`;

export default List;