import React, { FunctionComponent, useEffect } from 'react'
import styled from 'styled-components'
import LoaderCell from '../../../../components/LoaderCell'
import Row from '../../../../components/Row'
import Tooltip from '../../../../fragments/Tooltip'
import { styles } from '../../../../styles/themes/style'

interface StatisticsAreaProps {
    data: any[]
    fetchingData: boolean
    total: number,
    dataLineClickHandler: (e: any) => void
}

const StatisticsArea: FunctionComponent<StatisticsAreaProps> = ({ data, fetchingData, total, dataLineClickHandler }) => {

    useEffect(() => {
        if(data) data = data.sort((a: any,b: any) => b.count - a.count)
    }, [data])

    const listPreloader = [ ...Array(10)].map( (value, index) => {
        return <ListLinePreloader key={index}>
                <Row gap='10px'>
                    <LoaderCell $circle $height='12px' $width='12px'/>
                    <LoaderCell $height='12px' $width='110px'/>
                </Row>
                <LoaderCell $width='100%' $height='5px'/>
            </ListLinePreloader>
    })
    
    return (
        <Container>
            { fetchingData && listPreloader}
            { data && data.map(element => 
                <ListLine key={element?._id}>
                    <DataLine onClick={() => dataLineClickHandler(element?._id ?? 'Not disclosed')}> 
                        <Name>{ element?._id != null && element?._id !== '' ? element?._id : 
                                      (element?._id === '' ? 'To be updated' : 'Not disclosed') }</Name> 
                        <Value> { Math.round(element.count/total*100) }% ({ element?.count?.toLocaleString() }) </Value>
                        <Tooltip title={ element?._id != null && element?._id !== '' ? element?._id : 
                                      (element?._id === '' ? 'To be updated' : 'Not disclosed') }/>
                    </DataLine>
                    <VisualLine>
                        <VisualLinePercentage width={ Math.round(element.count/total*100) }/>
                    </VisualLine>
                </ListLine>) }
        </Container>
    )
}
const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    background: ${({theme: { secondaryColor }}) => secondaryColor};
    overflow-y: auto;
`
const ListLinePreloader = styled.div`
    display: flex;
    flex-direction: column;
    width: 99%;
    align-items: flex-start;
    margin-bottom: 20px;
    gap: 3px;
`
const ListLine = styled.div`
    display: flex;
    flex-direction: column;
    width: 99%;
    align-items: flex-start;
    margin-bottom: 20px;
`
const DataLine = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    width: 100%;
    transition: all .2s ease;
    color: ${styles.colors.black500};
    &:hover{
        cursor: pointer;
        color: ${styles.colors.primary600};
        span { display: block; }
    }
`
const Name = styled.div`
    text-overflow: ellipsis;
    max-width: 290px;
    overflow: hidden;
    white-space: nowrap;
`
const Value = styled.div`
    width: 110px;
    text-align: right;
`
const VisualLine = styled.div`
    height: 5px;
    width: 100%;
    border-radius: 4px;
    background: ${styles.colors.primary100};
`
const VisualLinePercentage = styled.span<{width: number}>`
    width: ${props => props.width}%;
    border-radius: 4px;
    height: 5px;
    background: ${styles.colors.primary300};
    display: flex;
`
export default StatisticsArea