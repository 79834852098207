import React, { useState, useRef, useEffect, CSSProperties, FunctionComponent } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { styles } from '../styles/themes/style';

interface CustomTextAreaProps {
    ref?: React.Ref<HTMLDivElement>;
    defaultValue?: string;
    onBlur?: (e: any) => void;
    style?: CSSProperties;
    type?: string;
    placeholder?: string;
    errorMessage?: string;
    maxLength?: number;
    value?: string;
    onChange?: (e: any) => void;
}

const CustomTextArea: FunctionComponent<CustomTextAreaProps> = (props) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [currentLength, setCurrentLength] = useState(props.value ? props.value.length : 0);

    useEffect(() => {
        setCurrentLength(props.value ? props.value.length : 0);
    }, [props.value]);

    const focusInput = () => {
        inputRef.current?.focus();
    };

    const onChangeHandler = (e: any) => {
        setCurrentLength(e.target.value.length);
        props.onChange && props.onChange(e);
    };

    return (
        <React.Fragment>
            <StyledContainer className="position-relative">
                <StyledFormControl
                    ref={inputRef}
                    defaultValue={props.defaultValue}
                    onChange={onChangeHandler}
                    onBlur={props.onBlur}
                    style={props.style}
                    type={props.type || 'text'}
                    placeholder={props.placeholder}
                    as="textarea"
                    maxLength={props.maxLength}
                    value={props.value}
                    spellCheck="true"
                />
                {props.maxLength && (
                    <StyledSpan>
                        {currentLength}/{props.maxLength}
                    </StyledSpan>
                )}
            </StyledContainer>
            {props.errorMessage && (
                <span style={{ color: "#ff6760", fontSize: "11px" }}>
                    {props.errorMessage}
                </span>
            )}
        </React.Fragment>
    );
};

const StyledContainer = styled.div`
    border: 2px solid ${styles.colors.black200};
    border-radius: 5px;
    pointer-events: none;

    &:focus-within, &:hover {
        border: 2px solid ${styles.colors.primary600};
    }
    ${props => !props.theme.primary && `
        background-color: #4E515C;

        & textarea { background-color: ${styles.colors.white}; }

        & span { background-color: ${styles.colors.white}; }
    `};
`;

const StyledFormControl = styled(Form.Control)<any>`
    display: block;
    width: 100%;
    padding: 7px 10px 5px;
    border: none;
    border-radius: 3px;
    resize: none;
    color: ${styles.colors.black600};
    font-family: NunitoSans;
    font-size: 14px;
    pointer-events: auto;

    span { background-color: red; }

    &::placeholder { color: #8c96ad; }
    &:focus { outline: none; }
`;

const StyledSpan = styled.span`
    display: block;
    text-align: right;
    padding: 10px;
    font-size: 12px;
    color: #8c96ad;
`;

export default CustomTextArea;
