import { FunctionComponent, useState } from 'react'
import Row from './Row'
import DropdownMenu from './dropdown/DropdownMenu'
import DropdownItem from './dropdown/DropdownItem'
import Text from './Text'
import InputNumber from './InputNumber'
import Select from './select/Select'
import styled from 'styled-components'
import Button from './Button'
import debounce from 'lodash.debounce'
import Column from './Column'
import { useAuthHandlers } from '../services/actions/auth/useAuthHandlers'

interface ITablePageNavigationProps {
    className?: string,
    totalCount: number,
    page: number,
    pageSize: number,
    pageCount?: number,
    onPageChanged: (e: any) => void,
    onPageSizeChanged: (e: any) => void
 }

 const pageSizeOptions = [
    {label: 25, value: 25},
    {label: 50, value: 50},
    {label: 100, value: 100},
    {label: 200, value: 200}
 ]

const TablePageNavigation: FunctionComponent<ITablePageNavigationProps> = ({className, totalCount = 0, page, pageSize = 25, pageCount,  onPageChanged, onPageSizeChanged}) => {
    const { saveUserPreferencesHandler } = useAuthHandlers()
    pageCount = pageCount ?? Math.max(Math.ceil(totalCount / pageSize), 1)
    const pageValidate = debounce((value: number) => {
        pageCount = pageCount ?? Math.max(Math.ceil(totalCount / pageSize), 1)
        if(value < 1) return
        if(value > pageCount) return
        onPageChanged(value)
    }, 500)

    const pageSizeHandler = (number: Number) => {
        onPageSizeChanged(number)
        saveUserPreferencesHandler({pageSize: number})
    }
    
    return <Row className={className} alignItems gap='10px'>
                <DropdownMenu $unsetOverflow $transparent title={`Page ${page} of ${pageCount}`} autoClose='outside'>
                    <StyledRow><DropdownItem $disabled><Text $lighter>{`${pageSize*(page -1) + 1}-${Math.min(totalCount, pageSize * page)} of ${totalCount ?? 0}`}</Text></DropdownItem></StyledRow>
                    <DropdownItem $nonHoverable>
                        <Column gap="5px">
                            <Row gap="20px" alignItems>
                                <Text $label $capitalize>{'Jump to page'}</Text>
                                <InputNumber prefilledValue={page} minValue={1} maxValue={pageCount} onChangeHandler={(value: number) => pageValidate(value)}/>
                            </Row>
                            <Row gap="2px" alignItems spaceBetween>
                                <Text $label $capitalize>{'Items per page'}</Text>
                                <SelectContainer
                                    isSearchable={false}
                                    options={pageSizeOptions}
                                    onChange={(selected: any) => pageSizeHandler(selected.value)}
                                    value={({label: pageSize, value: pageSize})}
                                />
                            </Row>
                        </Column>
                    </DropdownItem>
                </DropdownMenu>
                <StyledRow><Text $lighter>{`${pageSize*(page -1) + 1}-${Math.min(totalCount, pageSize * page)} of ${totalCount ?? 0}`}</Text></StyledRow>
                <Row gap="2px">
                    <Button $type='white' $smallButton $SVGtype='arrowLeft' disabled={page === 1} onClickHandler={() => pageValidate(page - 1)}/>
                    <Button $type='white' $smallButton $SVGtype='arrowRight' disabled={page === pageCount} onClickHandler={() => pageValidate(page + 1)}/>
                </Row>
    </Row>
}

const SelectContainer = styled(Select)`
    width: 125px !important;
    height: 34px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
`
const StyledRow = styled(Row)`
    @media (max-width: 768px) {
        display: none;
    }
`

export default TablePageNavigation
