import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

interface IBoxContainerProps {
    children?: ReactNode,
    className?: string
 }

const BoxContainer: FunctionComponent<IBoxContainerProps> = ({children, ...props}) => {
    return <Container {...props}>
        {children}
    </Container>
}

const Container = styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justif-content: center;
    border-radius: 4px;
    background-color: ${({ theme: { secondaryColor } }) => secondaryColor};
    gap: 15px;
`

export default BoxContainer
