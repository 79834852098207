import { FunctionComponent } from "react";
import styled from "styled-components";
import { styles } from "../styles/themes/style";
import Text from "../components/Text";
import Column from "../components/Column";
import Row from "../components/Row";
import Button from "../components/Button";
import { useAuthStore } from "../stores/auth/useAuthStore";
import moment from "moment";
import CustomModal from "../components/modal/CustomModal";
import { useAppHandlers } from "../services/actions/app/useAppHandlers";
import { useAppStore } from "../stores/application/useAppStore";

const ConnectionsOverviewModal: FunctionComponent = () => {

    const { store: { user: { lastSyncAt } } } = useAuthStore()
    const { hideModal } = useAppHandlers()
    const { store: { modal } } = useAppStore()

    const connectionsTotal = modal["connections-total"]
    const connectionsImportedCsvTotal = modal["connections-imported-csv-total"]
    const connectionsUpdated = modal["connections-updated"]
    const connectionsUpdatedConnectedToLinkedin = modal["connections-updated-connected-to-linkedin"]
    const connectionsImportedLinkedinTotal = modal["connections-imported-linkedin-total"]

    return (
        <CustomModal
        $center
        headingText={'Connections Overview'}
        confirmButton={{
            text: 'Acknowledge',
            onClickHandler: hideModal
        }}
    >
        <OverviewModal>
            <Column gap="5px" spaceBetween>
                <StyledColumn>
                    <Row spaceBetween>
                        <Text $bold>{`Total number of connections:`}</Text>
                        <Text $bold>{connectionsTotal}</Text>
                    </Row>
                </StyledColumn>
                <StyledColumn gap="12px">
                    <Row spaceBetween>
                        <Text $bold>{`Synced connections:`}</Text>
                        <Text $bold>{connectionsTotal - connectionsImportedLinkedinTotal}</Text>
                    </Row>
                    <Row spaceBetween>
                        <Text>{`Connections with updated data:`}</Text>
                        <Text>{connectionsUpdatedConnectedToLinkedin}</Text>
                    </Row>
                </StyledColumn>
                <StyledColumn gap="12px">
                    <Row spaceBetween>
                        <Text $bold>{`Imported connections:`}</Text>
                        <Text $bold>{connectionsImportedLinkedinTotal + connectionsImportedCsvTotal}</Text>
                    </Row>
                    <Row spaceBetween>
                        <Text>{`Sidebar imported connections:`}</Text>
                        <Text>{connectionsImportedLinkedinTotal}</Text>
                    </Row>
                    <Row spaceBetween>
                        <Text>{`CSV imported connections:`}</Text>
                        <Text>{connectionsImportedCsvTotal}</Text>
                    </Row>
                </StyledColumn>
                <StyledColumn>
                    <Row spaceBetween>
                        <Text $bold>{`Last connections sync:`}</Text>
                        <Text $bold>{lastSyncAt ? <> <b>{moment(lastSyncAt).format('MMM D, YYYY')}</b> at <b>{moment(lastSyncAt).format('HH:mm')}</b> </> : 'N/A'}</Text>
                    </Row>
                </StyledColumn>
            </Column>
        </OverviewModal>
    </CustomModal>
    )
}

const OverviewModal = styled.div`
    border-radius: 5px;
    background-color: ${styles.colors.black100};
    padding: 24px;
`
const StyledColumn = styled(Column)`
    padding: 8px 16px;
    background: white;
    border-radius: 5px;
`
export default ConnectionsOverviewModal