//Enums
export enum ActionType {
  SET_WORKSPACE_USERS = "SET_WORKSPACE_USERS",
  SET_WORKSPACE_USERS_PARAMETERS = "SET_WORKSPACE_USERS_PARAMETERS",
  SET_WORKSPACE_USERS_FILTERS = "SET_WORKSPACE_USERS_FILTERS",
  RESET_STORE = "RESET_STORE",
}

//Interfaces
export type IWorkspaceUsersParametersState = {
  page?: number;
  totalCount?: undefined | number;
};

export type IWorkspaceUsersFiltersState = {
  page: number;
  pageSize: number;
  sort: string;
  total: string;
  active: string;
  resolved: string;
};

//States
export type TWorkspaceUsersState = {
  workspaceUsers: any[];
  workspaceUsersParameters: IWorkspaceUsersParametersState;
  workspaceUsersFilters: IWorkspaceUsersFiltersState;
};

interface ISetWorkspaceUsersAction {
  type: ActionType.SET_WORKSPACE_USERS;
  payload: TWorkspaceUsersState["workspaceUsers"];
}

interface ISetWorkspaceUsersParametersAction {
  type: ActionType.SET_WORKSPACE_USERS_PARAMETERS;
  payload: TWorkspaceUsersState["workspaceUsersParameters"];
}

interface ISetWorkspaceUsersFiltersAction {
  type: ActionType.SET_WORKSPACE_USERS_FILTERS;
  payload: TWorkspaceUsersState["workspaceUsersFilters"];
}

interface IResetStoreAction {
  type: ActionType.RESET_STORE;
}

export type TWorkspaceUsersAction =
  | ISetWorkspaceUsersAction
  | ISetWorkspaceUsersParametersAction
  | ISetWorkspaceUsersFiltersAction
  | IResetStoreAction;
