import { FunctionComponent, ReactNode, useEffect, useState } from "react"
import { styles } from "../styles/themes/style";
import styled from "styled-components"
import TooltipWrapper from "./TooltipWrapper";

interface IStylesSwitchProps {
    $value?: boolean,
    $bothStatesOn?: boolean,
    $disabled?: boolean,
    tooltipMessage?: string
}

interface ISwitchProps extends IStylesSwitchProps {
    prefilledValue?: boolean,
    onClickHandler: (e: any) => void
}

const Switch: FunctionComponent<ISwitchProps> = ({ tooltipMessage = '', $disabled, prefilledValue, onClickHandler, ...props }) => {
    const [$value, setValue] = useState(prefilledValue)
    const onChangeStateHandler = () => {
        setValue(!$value)
        onClickHandler(!$value)
    }

    useEffect(() => {
        setValue(prefilledValue)
    }, [prefilledValue])

    return (
        <TooltipWrapper tooltipText={tooltipMessage}>
            <StyledSwitch onClick={() => !$disabled && onChangeStateHandler()} $value={$value} $disabled={$disabled} {...props}>
                <div className='ball' />
            </StyledSwitch>
        </TooltipWrapper>
    )
}
const StyledSwitch = styled.div<IStylesSwitchProps>`
    height: 22px;
    width: 42px;
    display: inline-block;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    flex-shrink: 0;
    ${({ $value, $bothStatesOn, theme: { quaternaryColor } }) => !$value && !$bothStatesOn && `
        border: 2px solid ${quaternaryColor};
        background-color: ${quaternaryColor};
    `}
    ${({ $value, $bothStatesOn }) => $value && !$bothStatesOn && `
        border: 2px solid ${styles.colors.primary600};
        background-color: ${styles.colors.primary600};
    `}
    &:hover, &:focus{
        border: 2px solid ${styles.colors.primary400};
        background-color: ${styles.colors.primary400};
    }
    & div.ball {
        width: 17px;
        height: 17px;
        position: absolute;
        ${({ $value }) => $value ? 'right: 0;' : 'left: 0;'}
        top: 0;
        border-radius: 50%;
        background-color: ${styles.colors.white};
    }

    ${({ $bothStatesOn }) => $bothStatesOn && `
        border: 2px solid ${styles.colors.primary200};
        background-color: ${styles.colors.primary200};
        & div.ball {
            background-color: ${styles.colors.primary600};
        }
        &:hover, &:focus{
            border: 2px solid ${styles.colors.primary100};
            background-color: ${styles.colors.primary100};
            & div.ball {
                background-color: ${styles.colors.primary400};
            }
        }
    `}
    
    ${({ $disabled }) => $disabled && `
        border: 2px solid ${styles.colors.disabledState};
        background-color: ${styles.colors.disabledState};
        &:hover, &:focus{
            border: 2px solid ${styles.colors.disabledState};
            background-color: ${styles.colors.disabledState};
        }
    `}

    
`
export default Switch