import { loadStripe } from '@stripe/stripe-js'
import  { FunctionComponent, useEffect, useState } from 'react'
import styled, { DefaultTheme, useTheme } from 'styled-components'
import { styles } from '../../../styles/themes/style'
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { AppRoutes } from '../../../interfaces/store/appStore'

interface ICheckoutProps { }

const Checkout: FunctionComponent<ICheckoutProps> = () => {

    const location = useLocation()
    const { setupIntent, selectedPrice, selectedBillingPlan, quantity } = location.state || {}
    const [searchParams, setSearchParams] = useSearchParams()
    const theme: DefaultTheme = useTheme()
    const [stripePromise] = useState(() => loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY}`))
    const [stripeOptions, setStripeOptions] = useState<any>()
    const navigate = useNavigate()

    const appearance = {
        theme: 'none',
        variables: {
            colorPrimary: styles.colors.primary600,
            colorBackground: theme.secondaryColor,
            colorText: theme.primaryColor,
            colorDanger: styles.colors.red600,
            colorSuccess: styles.colors.green600,
            fontFamily: 'Avenir, system-ui, sans-serif',
            spacingUnit: '3px',
            borderRadius: '4px'
        },
        rules: {
            '.Input': {
                border: `2px solid ${theme.quinaryColor}`,
                outline: 'none',
                padding: '7px 10px'
            },
            '.Input:hover': {
                border: `2px solid ${styles.colors.primary600}`,
            },
            '.Input:focus': {
                border: `2px solid ${styles.colors.primary600}`,
            },
            '.Label': {
                fontSize: '14px',
                lineHeight: '20px',
                textTransform: 'capitalize',
                fontWeight: 'bold'
            },
            '.Error': {
                fontSize: '11px',
                lineHeight: '16px'
            }
            // See all supported class names and selector syntax https://stripe.com/docs/elements/appearance-api?platform=web#variables
        }
    }

    
    useEffect(() => {
        const setupIntentClientSecret = searchParams.get('setup_intent_client_secret') ?? undefined
        if(setupIntentClientSecret || setupIntent) {
            setStripeOptions({
                clientSecret: setupIntentClientSecret ?? setupIntent?.client_secret,
                appearance
            })
        }
        else{
            navigate(AppRoutes.BILLING)
        }
    }, [searchParams])

    return <Container>
        {stripeOptions && <Elements stripe={stripePromise} options={stripeOptions} >
            <Outlet />
        </Elements>}
    </Container>
}

const Container = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    height: 100%;
`

export default Checkout
