import React, { FormEvent, FunctionComponent, ReactNode, useCallback, useState } from 'react'
import styled from 'styled-components'
import TabsView from '../../../../components/TabsView'
import BackgroundUpdate from './extension/BackgroundUpdate'

interface ILeadDeltaExtensionProps { }

const LeadDeltaExtension: FunctionComponent<ILeadDeltaExtensionProps> = () => {
    const [ selectedSlug, setSelectedSlug ] = useState<string>('backgroundUpdate')

    const leadDeltaExtensionTabs = [{
        slug: "backgroundUpdate",
        title: "Background update",
        selected: true
    }]
    const renderComponent: {[key: string]: ReactNode} = {
        backgroundUpdate: <BackgroundUpdate />,
    }
    return <Container>
        <TabsView onSwitchTab={()=>{}} tabs={leadDeltaExtensionTabs}>
            {renderComponent[selectedSlug]}
        </TabsView>
    </Container>
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y:auto;
    height: 100%;
`

export default LeadDeltaExtension
