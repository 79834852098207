import React, { createContext, useContext, useRef, ReactNode, FunctionComponent } from 'react';

interface IntervalContextProps {
    startInterval: (callback: () => void, intervalTime?: number) => void;
    stopInterval: () => void;
}

const IntervalContext = createContext<IntervalContextProps | undefined>(undefined);

export const IntervalProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
    const intervalIdRef = useRef<number | null>(null);

    const startInterval = (callback: () => void, intervalTime = 300000) => {
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
        }
        intervalIdRef.current = window.setInterval(callback, intervalTime);
    };

    const stopInterval = () => {
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        }
    };

    return (
        <IntervalContext.Provider value={{ startInterval, stopInterval }}>
            {children}
        </IntervalContext.Provider>
    );
};

export const useInterval = (): IntervalContextProps => {
    const context = useContext(IntervalContext);
    if (!context) {
        throw new Error('useInterval must be used within an IntervalProvider');
    }
    return context;
};