import { IResponse, useAxios } from "../useAxios"
import { useAuthStore } from "../../../stores/auth/useAuthStore"
import { IAnalyticsResponse } from "../../../interfaces/response/analytics"

export interface IAnalyticsQueryParams {
    version: string,
    type?: string
}

export const useAnalyticsRequest = () => {
    const { sendRequest } = useAxios()
    const { store: { authData: { accessToken } } } = useAuthStore()

    const route = '/analytics'

    const getAnalytics = (params: IAnalyticsQueryParams, slugAddition = ''): Promise<IAnalyticsResponse[]> => {
        return sendRequest({
            slug: `analytics${slugAddition}`,
            url: `${route}`,
            method: 'GET',
            params
        })
    };

    const incrementDiconnectedConnectionsCountAnalytics = (payload: any) => {
        return sendRequest({
            slug: `connectionsDisconnectedTotal`,
            url: `${route}/connectionsDisconnectedTotal/increment`,
            method: 'PUT',
            payload: payload,
            //all: true
        })
    };

    return {
        getAnalytics,
        incrementDiconnectedConnectionsCountAnalytics
    };
}

