import { FunctionComponent, ReactNode } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

interface IStyledModalComponent {
    children?: ReactNode
    show?: boolean
    onHide?: (e: any) => void
}

const StyledModal: FunctionComponent<IStyledModalComponent> = ({ children, show, onHide }) => {
    return (
        <StyledModalComponent
            show={show}
            onHide={onHide}
            backdrop="static"
        >
            {children}
        </StyledModalComponent>
    )
}

const StyledModalComponent = styled(Modal)`
    & .modal-dialog {
        width: 448px;
        position: relative;
        top: 70px;   
    }
`

export default StyledModal