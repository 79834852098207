import { IFormProperty } from "../common"
import { IBillingPlan, IBillingPlanV2, IPrice } from "../models/billingPlan"
import { IInvoice } from "../models/invoice"
import { ICoupon } from "../models/coupon"
import { IUpcomingPayment } from "../response/upcomingPayment"
import { IBillingPlanFeatures } from "../models/billingPlanFeatures"

//Enums
export enum ActionType {
    SET_BILLING_PLANS = "SET_BILLING_PLANS",
    SET_SELECTED_PLAN = "SET_SELECTED_PLAN",
    SET_SELECTED_PRICE = "SET_SELECTED_PRICE",
    SET_CURRENCY = "SET_CURRENCY",
    SET_SETUP_INTENT = "SET_SETUP_INTENT",
    SET_APPLIED_COUPON = "SET_APPLIED_COUPON",
    SET_QUANTITY_FORM_STATE = "SET_QUANTITY_FORM_STATE",
    SET_LICENCE_QUANTITY = "SET_LICENCE_QUANTITY",
    SET_INVOICES = "SET_INVOICES",
    RESET_STORE = "RESET_STORE",
    SET_UPCOMING_PAYMENT = "SET_UPCOMING_PAYMENT",
    SET_BILLING_PLANS_FEATURES = "SET_BILLING_PLANS_FEATURES"
}

//Interfaces
export interface ISetupIntent {
    client_secret: string
}

//States
export type TBillingState = {
    billingPlans: IBillingPlan[],
    selectedBillingPlan?: IBillingPlanV2,
    selectedPrice?: IPrice,
    setupIntent?: ISetupIntent,
    appliedCoupon?: ICoupon,
    quantityFormState?: IFormProperty,
    licenceQuantity?: number
    invoices?: IInvoice[],
    upcomingPayment?: IUpcomingPayment,
    billingPlansFeatures: IBillingPlanFeatures[],
}

//Actions
interface ISetBillingPlansAction {
    type: ActionType.SET_BILLING_PLANS
    payload: TBillingState['billingPlans']
}

interface ISetSelectedPlanAction {
    type: ActionType.SET_SELECTED_PLAN
    payload: TBillingState['selectedBillingPlan']
}

interface ISetSelectedPriceAction {
    type: ActionType.SET_SELECTED_PRICE
    payload: TBillingState['selectedPrice']
}

interface ISetSetupIntentAction {
    type: ActionType.SET_SETUP_INTENT
    payload: TBillingState['setupIntent']
}

interface ISetAppliedCoupon {
    type: ActionType.SET_APPLIED_COUPON
    payload: TBillingState['appliedCoupon']
}

interface ISetQuantityFormStateAction {
    type: ActionType.SET_QUANTITY_FORM_STATE
    payload: TBillingState['quantityFormState']
}

interface ISetLicenceQuantityAction {
    type: ActionType.SET_LICENCE_QUANTITY
    payload: TBillingState['licenceQuantity']
}

interface ISetInvoices {
    type: ActionType.SET_INVOICES
    payload: TBillingState['invoices']
}

interface ISetUpcomingPayment {
    type: ActionType.SET_UPCOMING_PAYMENT
    payload: TBillingState['upcomingPayment']
}

interface ISetBillingPlansFeaturesAction {
    type: ActionType.SET_BILLING_PLANS_FEATURES
    payload: TBillingState['billingPlansFeatures']
}

interface IResetStoreAction {
    type: ActionType.RESET_STORE
}

export type TBillingAction =
    ISetBillingPlansAction |
    IResetStoreAction |
    ISetSelectedPlanAction |
    ISetSelectedPriceAction |
    ISetSetupIntentAction |
    ISetAppliedCoupon |
    ISetQuantityFormStateAction |
    ISetLicenceQuantityAction |
    ISetInvoices |
    ISetUpcomingPayment |
    ISetBillingPlansFeaturesAction
