import { FunctionComponent, useEffect } from 'react'
import Table from '../../../../../components/Table'
import { useCSVImportHandlers } from '../../../../../services/actions/csv/useCSVImportHandlers'
import Text from '../../../../../components/Text'
import ActionText from '../../../../../components/ActionText'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import Row from '../../../../../components/Row'
import moment from 'moment'
import { calculateColumnWidth } from '../../../../../utils'
import SvgFragment from '../../../../../fragments/SvgFragment'
import EmptyPage from '../../../../../components/EmptyPage'
import { useAppHandlers } from '../../../../../services/actions/app/useAppHandlers'
import useMediaQuery from '../../../../../components/useMediaQuery'

interface ICSVImportTableProps { 
    columns: any,
    data: any,
    tableRef?: any
    setColumns: (e: any) => void
}

const CSVImportTable: FunctionComponent<ICSVImportTableProps> = ({ columns, data, tableRef, setColumns }) => {
    const { downloadCSVHandler } = useCSVImportHandlers()
    const { setCsvOverviewModal } = useAppHandlers()
    const isSmall = useMediaQuery("(max-width: 768px)");

    const noResultTitle = "No Import to Show";
    const noResultDescription = "";

    useEffect(() => {
        const offsetWidth = tableRef.current?.offsetWidth

        const adjustedColumns = calculateColumnWidth(offsetWidth, columns, isSmall) 
        setColumns(adjustedColumns)

    }, [isSmall])

    return <Table
                columns={[
                    ...columns
                ]}
                ref={tableRef}
                noDataPage={<EmptyPage svgType='importEmpty' title={noResultTitle} label={noResultDescription} />}
                data={data.map((singleImport: any) => {
                    return (
                        {
                            nameDownload: (
                                <ActionText variable onClickHandler={() => downloadCSVHandler(singleImport)}>{singleImport.originalFilename}</ActionText>
                            ),
                            status: (
                                singleImport.isProcessed ? 
                                    <ActionText onClickHandler={()=>setCsvOverviewModal(singleImport)}>{'Overview available'}</ActionText> :
                                    <Row gap='5px'><LoadingSpinner /><Text>{'In progress'}</Text></Row>
                            ),
                            createdAtMoment: (
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}><Text>{moment(singleImport.createdAt).format('MMM D, YYYY [at] HH:mm')}</Text></div>
                            ),
                            ...singleImport
                        }
                    )
                })}
                />
}

export default CSVImportTable
