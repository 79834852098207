import { FunctionComponent, ReactNode, useRef, useState } from "react"
import styled from "styled-components"
import Text from "./Text";
import Tooltip from "../fragments/Tooltip";
import { styles } from "../styles/themes/style";
import SvgFragment from "../fragments/SvgFragment";

interface IStyledTagProps {
    $color?: string,
    $borderColor?: string,
    $backgroundColor?: string,
    $cutLeft?: boolean,
    $cutRight?: boolean,
    $width?: string,
    $height?: string,
    $isDisconnected?: boolean,
}

interface ITagProps extends IStyledTagProps {
    title: string,
    SVGtype?: string,
    onDestructiveClickHandler?: (e: any) => void
}

const Tag: FunctionComponent<ITagProps> = ({ SVGtype, title, $width, $height, onDestructiveClickHandler, ...props }) => {
    const textRef = useRef<HTMLDivElement>(null)
   
    const isEllipsisApplied = () => {
        const element = textRef.current

        if (element) {
          return element.scrollWidth > element.clientWidth
        }
        return false
    }
    
    return (
        <StyledTagContainer $width={$width} $height={$height} {...props}>
            {SVGtype && <StyledIcon {...props}><SvgFragment type={SVGtype} /></StyledIcon>}
            <StyledTag {...props} $cutLeft={!!onDestructiveClickHandler} >
                <Text ref={textRef} $paragraph3 $bold $inheritColor $ellipsis="0" showTooltip={isEllipsisApplied()}>{title}</Text></StyledTag>
            {onDestructiveClickHandler && <StyledDestructiveAction onClick={(e) => onDestructiveClickHandler(e)} {...props}>
                <SvgFragment type={'close'} />
            </StyledDestructiveAction>}
        </StyledTagContainer>

    )
}
const StyledIcon = styled.div<IStyledTagProps>`
    margin-left: 5px;
    line-height: normal;
    display: flex;
    ${({ $backgroundColor }) => $backgroundColor && `background-color: ${$backgroundColor}`};
    .svg {
        width: 14px;
        height: 14px;
        .path { fill: ${({ $borderColor, $color }) =>  $borderColor || $color || styles.colors.primary600} !important; } 
    }
`
const StyledTagContainer = styled.div<IStyledTagProps>`
    max-width: ${({$width}) => ($width !== undefined && $width !== null && $width.length > 0) ? $width : 'fit-content'};
    width: fit-content;
    display: flex;
    align-items: center;
    height: ${({$height}) => ($height !== undefined && $height !== null && $height.length > 0) ? $height : 'fit-content'};
    border-radius: 4px;
    ${({ $backgroundColor }) => $backgroundColor && `background-color: ${$backgroundColor}`};
    border: 1px solid ${({ $backgroundColor, $borderColor, $color }) => $borderColor || $backgroundColor  || $color || styles.colors.primary600}
`
const StyledTag = styled.div<IStyledTagProps>`
    display: flex;
    border-radius: 4px;
    ${({ $cutLeft }) => $cutLeft && 'border-radius: 4px 0px 0px 4px;'}
    ${({ $cutRight }) => $cutRight && 'border-radius: 0px 4px 4px 0px;'}
    padding: 0px 5px;
    word-wrap: break-word;
    max-width: 100%;
    height: fit-content;
    width: fit-content;
    color: ${({ $borderColor, $isDisconnected }) => $isDisconnected ? styles.colors.white : ($borderColor ?? styles.colors.white)};
    background-color: ${({ $color }) =>  ($color ?? styles.colors.primary600)};
`
const StyledDestructiveAction = styled.div<IStyledTagProps>`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 4px 4px 0px;
    padding: 0px 5px;
    background-color: ${({ $color }) => $color ?? styles.colors.primary600};
    & .svg {
        height: 16px;
        width: 16px;
    }
    & .svg path{
        fill: ${({ $borderColor }) => $borderColor ?? styles.colors.white};
    }
    &:hover, &:focus{
        opacity: 0.7;
    }
`
export default Tag