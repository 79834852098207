import { useAppActions } from "../../../stores/application/useAppActions"
import { ModalActionType } from "../../../components/modal/AppModal"
import { ReactNode } from "react"
import { useExtensionRequest } from "../../apis/extension/useExtensionRequest"
import { useAppStore } from "../../../stores/application/useAppStore"
import { ISidebar } from "../../../interfaces/store/appStore"
import { IUser } from "../../../interfaces/models/user"

export const useAppHandlers = () => {
    const { setToast, setModal, setSpinner, setExtensionInstalled, setExtensionId, setExtensionVersion, setSidebar, setLeadDeltaTabIsActive } = useAppActions()
    const { getExtensionStatus, openExtensionInTab } = useExtensionRequest()

    const { store: { extensionId, sidebar } } = useAppStore()

    interface IToastPayload {
        duration?: number,
        message: string,
        onClick?: {
            onClickHandler?: (e: any) => void;
            label?: string
        }
    }

    interface IModalPayload {
        headingText?: ReactNode
        descriptionText?: ReactNode
        descriptionTextBold?: ReactNode
        onConfirmHandler?: (e: any) => void
        onCancelHandler?: (e: any) => void
    }

    const showSuccessToast = (payload: IToastPayload) => setToast({ type: 'success', ...payload })
    const showInfoToast = (payload: IToastPayload) => setToast({ type: "info", ...payload })
    const showWarningToast = (payload: IToastPayload) => setToast({ type: 'warning', ...payload })
    const showErrorToast = (payload: IToastPayload) => setToast({ type: 'error', ...payload })
    const showReSendMessageToast = (payload: IToastPayload) => setToast({ type: 'reSendMessage', ...payload })
    const hideToast = () => setToast()

    const setModalSample = () => setModal({ type: ModalActionType.MODAL_SAMPLE })

    const setCancelFlow = () => setModal({ type: ModalActionType.MODAL_CANCEL_FLOW })
    const setPauseFlow = () => setModal({ type: ModalActionType.MODAL_PAUSE_FLOW })
    const setPaymentConfirmModal = () => setModal({ type: ModalActionType.PAYMENT_CONFIRM_MODAL })
    const setCustomColorModalHandler = (color: string, onColorChangedHandler: (color: string) => void) => setModal({ type: ModalActionType.CUSTOM_COLOR_MODAL, props: {title: 'Custom Tag Color', colorProp: color, onColorChangedHandler } })
    const setSyncConnectionsModalHandler = (syncCallback: (user: IUser) => void) => setModal({ type: ModalActionType.SYNC_CONNECTIONS_MODAL, props: { onClickHandler: syncCallback} })
    const setDisconnectConnectionsModalHandler = ( disconnectCallback: (disconnectFromLI: boolean, removeFromLD: boolean) => void, newDisconnectedCount?: number, selectedAllConnections?: boolean, numberOfConnections?: number, enableLinkedinAction?: boolean) => setModal({ type: ModalActionType.DISCONNECT_CONNECTIONS_MODAL, props: { onClickHandler: disconnectCallback, newDisconnectedCount: newDisconnectedCount, isAllSelected: selectedAllConnections, numberOfConnections: numberOfConnections, enableLinkedinAction: enableLinkedinAction } })
    const setExportConnectionsModalHandler = ( exportCallback: (includeTags: boolean, includeNotes: boolean, selectAll: boolean) => void, numberOfConnections?: number, selectedAllConnections?: boolean) => setModal({ type: ModalActionType.EXPORT_CONNECTIONS_MODAL, props: { onClickHandler: exportCallback, numberOfConnections: numberOfConnections, selectedAllConnections: selectedAllConnections } })
    
    const setConfirmationModal = (payload: IModalPayload) => setModal({ type: ModalActionType.CONFIRMATION_MODAL, ...payload })
    const setDestructiveModal = (payload: IModalPayload) => setModal({ type: ModalActionType.DESTRUCTIVE_MODAL, ...payload })
    const setMessageModal = (payload: IModalPayload) => setModal({ type: ModalActionType.MESSAGE_MODAL, ...payload })
    const setConnectionsOverviewModal = (payload: any) => setModal({ type: ModalActionType.CONNECTIONS_OVERVIEW_MODAL, ...payload })
    const setCsvOverviewModal = (payload: any) => setModal({ type: ModalActionType.CSV_OVERVIEW_MODAL, ...payload })
    const hideModal = () => setModal()

    const showSpinner = () => setSpinner(true)
    const hideSpinner = () => setSpinner(false)

    
    const openSidebar = (type: string, props?: any) => {
        let zIndex: number = 1000000;
        zIndex = sidebar.length > 0 ? sidebar[sidebar.length - 1].zIndex + 1 : zIndex
        setSidebar([...sidebar, { type, zIndex, props}])
    } 

    //This relies heavily on the logic that user won't see other sidebars
    const closeSidebar = (type: string) => {
        let newSidebar = sidebar.filter((sidebarItem: ISidebar) => sidebarItem.type !== type)
        setSidebar([...newSidebar])
    }

    const checkExtensionStatus = async () => {
        let result = await getExtensionStatus()
        setExtensionInstalled(result?.status === 'installed' ? true : false)
        setExtensionVersion(result?.status === 'installed' ? result?.version : undefined)
    }

    const syncExtensionId = (appId?: string) => {
        if (!appId) {
            appId = localStorage.getItem('appId') ?? process.env.REACT_APP_EXTENSION_ID
        }
        if(appId) localStorage.setItem('appId', appId)
        setExtensionId(appId)
    }

    const openExtension = ({ query }: any = {}) => {
        openExtensionInTab({query});
    };

    const buildExtensionUrl = ({ query }: any = {}) => {
        return `chrome-extension://${extensionId}/src/options/index.html${query ? `#${query}` : ''}`;
    };

    const setLeadDeltaTabIsActiveHandler = (payload: boolean) => {
        setLeadDeltaTabIsActive(payload)
    }

    return {
        showSuccessToast,
        showInfoToast,
        showWarningToast,
        showErrorToast,
        showReSendMessageToast,
        hideToast,
        setModalSample,
        setCancelFlow,
        setPauseFlow,
        setPaymentConfirmModal,
        setConfirmationModal,
        setDestructiveModal,
        setMessageModal,
        setConnectionsOverviewModal,
        setCsvOverviewModal,
        hideModal,
        showSpinner,
        hideSpinner,
        checkExtensionStatus,
        syncExtensionId,
        openExtension,
        openSidebar,
        closeSidebar,
        setCustomColorModalHandler,
        buildExtensionUrl,
        setSyncConnectionsModalHandler,
        setDisconnectConnectionsModalHandler,
        setExportConnectionsModalHandler,
        setLeadDeltaTabIsActiveHandler
    }
}