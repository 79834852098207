import { FunctionComponent } from "react";
import styled from "styled-components";

interface IBadgeStyles {
    relative?: boolean
    top: string
    left: string
    visibility?: string
}


interface IBadgeProps extends IBadgeStyles {
    number: number,
    children: any
    onClick?: (e: any) => void
}

const Badge: FunctionComponent<IBadgeProps> = ({ relative, top, left, number, onClick, children }) => {

    return (
        <Wrapper onClick={onClick}>
            <Number visibility={number ? 'visible' : 'hidden'} relative={relative} top={top} left={left}>{number}</Number> 
            {children}
        </Wrapper>
    )

}
const Wrapper = styled.div`

`
const Number = styled.div<IBadgeStyles>`
    visibility: ${({visibility}) => visibility};
    position: ${({relative}) => relative ? 'relative' : 'absolute'};
    top: ${({top}) => top};
    left: ${({left}) => left};
    background: red;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    color: white;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default Badge