import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Column from '../../../components/Column';
import NotesControlBar from '../components/notes/NotesControlBar';
import NotesTable from '../components/notes/NotesTable';
import { useNotesHandlers } from '../../../services/actions/notes/useNotesHandlers';
import { useNotesStore } from '../../../stores/notes/useNotesStore';

interface INoteSectionProps { }

const NoteSection: FunctionComponent<INoteSectionProps> = () => {
    const { getNotesHandler } = useNotesHandlers()
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false)
    const { store: { notes, notesFilters, notesParameters }} = useNotesStore()
    const [controlBarHeight, setControlBarHeight] = useState<number>(0);
    const controlBarRef = useRef<HTMLDivElement>(null);

    const updateControlBarHeight = () => {
        if (controlBarRef.current) {
            const height = controlBarRef.current.offsetHeight - 2;
            setControlBarHeight(height);
        }
    };
    useEffect(() => {
        updateControlBarHeight();
    }, [controlBarRef.current]);

    useEffect(() => {
        getNotesHandler()
    }, [notesParameters.page, notesParameters.pageSize, notesFilters]) //dule: another reason to use one variable, this is silly

    return (
        <Container>
            {(
                <Column>
                    <StickyControlBar ref={controlBarRef}>
                        <NotesControlBar
                            isAllSelectedProps={{isAllSelected, setIsAllSelected}}
                            notesFilters={notesFilters}
                            notesParameters={notesParameters}
                        />
                    </StickyControlBar>
                    <NotesTable
                        isAllSelectedProps={{isAllSelected, setIsAllSelected}}
                        notes={notes}
                        notesFilters={notesFilters}
                        controlBarHeight={controlBarHeight}
                    />
                </Column>
            )}
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    padding: 0px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    overflow-y: auto;
`

const StickyControlBar = styled.div`
    position: sticky;
    top: 0;
    z-index: 101;
    background: ${({theme: { tertiaryColor }}) => tertiaryColor};
    padding-top: 16px;
`;

export default NoteSection
