import { FunctionComponent, useEffect, useState } from 'react'

import styled from 'styled-components'

import Text from '../../../components/Text';

import DropdownMenu from '../../../components/dropdown/DropdownMenu';
import DropdownItem from '../../../components/dropdown/DropdownItem';


import PlanToggle from '../components/PlanToggle';
import BillingPlans from '../components/BillingPlans';
import { useBillingHandlers } from '../../../services/actions/billing/useBillingHandlers';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import CompareFeatures from './CompareFeatures/CompareFeatures';
import { useAppStore } from '../../../stores/application/useAppStore';
import { useBillingStore } from '../../../stores/billing/useBillingStore';
import CurrencySelect from '../components/CurrencySelect';
import { IPrice } from '../../../interfaces/models/billingPlan';
import { useAuthStore } from '../../../stores/auth/useAuthStore';


interface IPlansAndPricingSectionProps {
}

const PlansAndPricingSection: FunctionComponent<IPlansAndPricingSectionProps> = () => {//move and rename this as a section
    const { store: { billingPlans, selectedBillingPlan, selectedPrice } } = useBillingStore()
    const { store: { subscription, trial }} = useAuthStore()

    const [currency, setCurrency] = useState<string>('usd')
    const [billingPlan, setBillingPlan] = useState({ plan: 'Yearly', key: 'year' })//this is cycle or interval, not plan
    const [showCompareFeatures, setShowCompareFeatures] = useState(false)

   

    const selectedPrices = billingPlans.map((plan: any) => {
        const filteredPrices = plan.prices.filter((price: any) => price.interval === billingPlan.key && price.currency === currency)
        return {
            name: plan.name,
            prices: filteredPrices
        }
    })

    const cantChangePrice = subscription?.isValid && !trial && !(subscription?.cancelDate && subscription?.isValid)

    return (
        <Container>
            <TitleCurrencySection>
                <Text $heading2 $bold $black>Plans & Pricing</Text>
                <Text $lighter>Choose the best plan for you</Text>
                {!cantChangePrice && <StyledCurrencySelect onChangeHandler={(value) => setCurrency(value)}/>}
                <PlanToggle
                    prices={!selectedBillingPlan?.prices ? 
                        billingPlans.filter((product: any) => product?.name === 'Business')?.flatMap((product: any) => product?.prices)?.filter((price: any) => price?.currency === 'usd')
                        : 
                        selectedBillingPlan?.prices?.filter((price: IPrice) => selectedPrice && price.currency === selectedPrice.currency
                    )}
                    selectedInterval={billingPlan}
                    onClickHandler={setBillingPlan}
                /> 
            </TitleCurrencySection>
            <BillingPlans selectedCycle={billingPlan.key} currency={currency}  setShowCompareFeatures={setShowCompareFeatures} />
            {showCompareFeatures && <CompareFeatures selectedPrices={selectedPrices} currency={currency} selectedCycle={billingPlan.key} setShowCompareFeatures={setShowCompareFeatures} />}
        </Container>
    )
}
const Container = styled.div<IPlansAndPricingSectionProps>`
    background-color: white;
    width: 100%;
    padding: 32px 24px 32px 24px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    gap: 56px;
    overflow-y: auto;
    height: 100%;
    @media only screen and (min-width: 1024px) {
        gap: 24px;
    }
`
const TitleCurrencySection = styled.section<IPlansAndPricingSectionProps>`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    @media only screen and (min-width: 1024px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
    }
`
const StyledCurrencySelect = styled(CurrencySelect) <IPlansAndPricingSectionProps>`
    position: absolute;
    bottom: -36px;
    right: 0;
    @media only screen and (min-width: 1024px) {
        top: 0
    }

    .actions-dropdown + div {
        min-width: 138px;
    }
`
export default PlansAndPricingSection