import { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { useAppHandlers } from '../../../../../services/actions/app/useAppHandlers'
import { useBillingRequest } from '../../../../../services/apis/billing/useBillingRequest'

import Button from '../../../../Button'
import DropdownItem from '../../../../dropdown/DropdownItem'
import DropdownMenu from '../../../../dropdown/DropdownMenu'
import Text from '../../../../Text'
import CustomModal from '../../../CustomModal'
import { styles } from '../../../../../styles/themes/style'
import FormControl from '../../../../FormControl'

interface IPauseFlow {
    nextSection: () => void
}

const WhyDontWePauseSection: FunctionComponent<IPauseFlow> = ({ nextSection }) => {
    const [value, setValue] = useState('Choose...')
    const { setCancelFlow, hideModal } = useAppHandlers()
    const { pauseSubscriptionRequestHandler } = useBillingRequest()

    const onPauseSubscriptionHandler = async () => {
        const resumeAt = moment(moment()).add(Number(value.charAt(0)), 'M').unix()
        const data = await pauseSubscriptionRequestHandler(resumeAt)
        data && data.pausedUntil === resumeAt && nextSection()
    }
    return (
        <CustomModal
            headingText={`Why don’t we pause?`}
            closeButton
            descriptionText={<StyledText>{'We will miss you but you can take a break from LeadDelta for a short while.'}</StyledText>}
            infoButton={{
                text: 'Decline offer',
                bigButton: true,
                onClickHandler: setCancelFlow
            }}
            confirmButton={{
                text: 'Pause Subscription',
                bigButton: true,
                disable: value === 'Choose...',
                onClickHandler: onPauseSubscriptionHandler
            }}
        >
            <Container>
                <FormControl>
                    <Text $label>Choose how long you want to pause</Text>
                    <DropdownMenu
                    title={value}
                >
                    <DropdownItem onClickHandler={(e) => setValue(e.target.innerText)}>1 month</DropdownItem>
                    <DropdownItem onClickHandler={(e) => setValue(e.target.innerText)}>2 months</DropdownItem>
                    <DropdownItem onClickHandler={(e) => setValue(e.target.innerText)}>3 months</DropdownItem>
                </DropdownMenu>
                </FormControl>
            </Container>
        </CustomModal>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    & .dropdown {
        width: 100%;
        border: 2px solid ${({ theme: { quinaryColor } }) => `${quinaryColor};`};
        padding: 5px;

        &:hover { border: 2px solid ${(styles.colors.primary600)}; }
    }

    & .dropdown-menu {
        width: 100%;
        transform: translate3d(0px, 50px, 0px) !important;
    }

    & .dropdown-toggle {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        ${({ theme: { quaternaryColor } }) => `color: ${quaternaryColor};`}
    }
`
const StyledText = styled.div`
    display: inline-block;
    text-align: left;
    font-weight: normal;
`

export default WhyDontWePauseSection