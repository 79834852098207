import React, { FormEvent, FunctionComponent, ReactNode, useCallback, useState } from 'react'
import styled from 'styled-components'
import { styles } from '../styles/themes/style'

interface IBannerStyleProps {
    backgroundColor?: string 
}
interface IBannerProps extends IBannerStyleProps { 
    children: ReactNode,
}

const Banner: FunctionComponent<IBannerProps> = ({ children, ...props }) => {
    return <Container { ...props }>{ children }</Container>
}

const Container = styled.div<IBannerStyleProps>`
    width: 100%;
    height: 40px;
    color: ${({ theme: { primaryColor } }) => primaryColor};
    font-family: NunitoSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 10px 16px;
    background: ${({ backgroundColor })  => backgroundColor ?? styles.colors.yellow600}
`

export default Banner
