import { FunctionComponent, useState } from "react";
import { useConnectionsStore } from "../../stores/connections/useConnectionsStore";
import ActionText from "../../components/ActionText";
import Button from "../../components/Button";
import styled from "styled-components";
import FormControl from "../../components/FormControl";
import Input from "../../components/Input";
import Column from "../../components/Column";
import { useSaveFiltersRequest } from "../../services/apis/save-filters/useSaveFiltersRequest";
import { useAppHandlers } from "../../services/actions/app/useAppHandlers";
import { SidebarTypes } from "../../interfaces/store/appStore";
import { useSaveFilterHandlers } from "../../services/actions/save-filters/useSaveFilterHandlers";

interface ISaveFilterFormProps { 
    onSaveFilter?: (e: any) => void
}

const SaveFilterForm: FunctionComponent<ISaveFilterFormProps> = ({onSaveFilter}) => {
    const { store: { connectionsFilter } } = useConnectionsStore()

    const { saveFilters } = useSaveFiltersRequest()
    const { closeSidebar } = useAppHandlers()
    const { saveFiltersHandler } = useSaveFilterHandlers()

    const { selectedTags, selectedExcludeTags } = connectionsFilter

    const [filterName, setFilterName] = useState<string>('')

    const onClickHandler = async() => {
        const body = {
            name: filterName,
            tags: selectedTags,
            excludeTags: selectedExcludeTags,
            filters: connectionsFilter
        }
        const result = await saveFiltersHandler(body)
        if(result && onSaveFilter) onSaveFilter(body);
    }

    return (
        <Column gap="20px">
            <FormControl>
                <Input placeholder="Enter filter name" onChangeHandler={(e: any) => setFilterName(e)} />
            </FormControl>
            <ButtonWrapper>
                <Button $type="cancel" $bigButton onClickHandler={() => closeSidebar(SidebarTypes.SAVE_FILTER)}>Cancel</Button>
                <Button $bigButton $type="blue" onClickHandler={onClickHandler}>Save</Button>
            </ButtonWrapper>
        </Column>
    )

}

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 10px;
`

export default SaveFilterForm