import { TWorkspaceUsersAction, TWorkspaceUsersState, ActionType, IWorkspaceUsersParametersState, IWorkspaceUsersFiltersState } from "../../interfaces/store/workspaceUsersStore";
import { makeStore } from "../makeStore";

const initialWorkspaceUsersParameters: IWorkspaceUsersParametersState = {
        page: 1,
        totalCount: 0
}

const initialWorkspaceUsersFilters: IWorkspaceUsersFiltersState = {
        page: 1,
        pageSize: 50,
        sort: 'newest', 
        total: 'true',
        active: 'true',
        resolved: 'true'
}

const initialState: TWorkspaceUsersState = {
    workspaceUsers: [],
    workspaceUsersParameters: initialWorkspaceUsersParameters,
    workspaceUsersFilters: initialWorkspaceUsersFilters
}


const reducer = (state: TWorkspaceUsersState, action: TWorkspaceUsersAction): TWorkspaceUsersState => {
    switch (action.type) {
        case ActionType.SET_WORKSPACE_USERS: return { ...state, workspaceUsers: action.payload }
        case ActionType.SET_WORKSPACE_USERS_PARAMETERS: return { ...state, workspaceUsersParameters: { ...state.workspaceUsersParameters, ...action.payload } }
        case ActionType.SET_WORKSPACE_USERS_FILTERS: return { ...state, workspaceUsersFilters: { ...state.workspaceUsersFilters, ...action.payload } }
        case ActionType.RESET_STORE: return { ...initialState }
        default: return state
    }
}

const { StoreProvider: WorkspaceUsersStoreProvider, useStoreContext: useWorkspaceUsersStore } = makeStore(reducer, initialState)

export { WorkspaceUsersStoreProvider, useWorkspaceUsersStore }