import { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import Tag from '../../../components/Tag'
import Text from '../../../components/Text'
import { styles } from '../../../styles/themes/style'
import { IPrice } from '../../../interfaces/models/billingPlan'
import { formatNumber } from '../../../utils'

interface IPlanValueProps {
    isSelected?: boolean
}

interface IPlanToggleProps extends IPlanValueProps {
    selectedInterval?: any,
    prices: IPrice[],
    onClickHandler?: (e: any) => void
}

const calculateSaveAmount = (monthlyPrice?: IPrice, annualPrice?: IPrice): number => {
    if(monthlyPrice && annualPrice) {
        const annualMonthly = monthlyPrice?.price * 12
        return (annualMonthly - annualPrice?.price) / annualMonthly * 10000
    }
    return 0
}

const PlanToggle: FunctionComponent<IPlanToggleProps> = ({ selectedInterval, prices, onClickHandler }) => {

    const plansInitialValues = [
        {
            plan: 'Yearly',
            key: 'year',
            currency: 'usd',
            price: 0,
            save: '30% OFF'
        },
        {
            plan: 'Monthly',
            key: 'month',
            currency: 'usd',
            price: 0,
            save: ''
        }
    ]
    const [plansValues, setPlansValues] = useState<{
        plan: string,
        key: string,
        currency: string,
        price: number,
        save?: string
    }[]> (plansInitialValues)

    useEffect(() => {
        let monthlyPrice: IPrice | undefined
        let annualPrice: IPrice | undefined
        let save: number
        let newPlans = prices.map(price => {
            if(price?.interval === 'month') monthlyPrice = price 
            if(price?.interval === 'year') annualPrice = price 
            return {
                plan: price.interval,
                key: price.interval,
                price: price.price,
                currency: price.currency
            }
        })
        save = calculateSaveAmount(monthlyPrice, annualPrice)
        newPlans = newPlans.map(plan => plan?.key === 'year' && save > 0 ? {...plan, save: `${formatNumber(save / 100)}% OFF`} : {...plan})
        setPlansValues(newPlans)
    }, [prices])

    return (
        <Container>
            {plansValues.sort((a: any, b: any) => {
                if(a.plan === 'year' && b.plan !== 'year') return -1
                if(a.plan !== 'year' && b.plan === 'year') return 1
                return 0
            }).map((value, index) => (
                    <PlanValue key={index} isSelected={selectedInterval?.key === value?.key} onClick={() => onClickHandler && onClickHandler({ plan: value?.plan, key: value?.key })}>
                        <Text $black $bold $capitalize>{`Bill ${value.plan}ly`}</Text>
                        {value?.save  && <Tag $color={styles.colors.green600} title={value?.save} />}
                    </PlanValue>
                ))}
        </Container>
    )
}
const Container = styled.div`
    display: flex
`
const PlanValue = styled.div<IPlanValueProps>`
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid ${({ isSelected }) => isSelected ? styles.colors.primary600 : styles.colors.white};
    border-radius: 4px;
    background-color: #EEF3FF;
    padding: 9px 8px;
    cursor: pointer;

    &:hover > span:first-child {
        color: #545E77;
    }
`
export default PlanToggle