import { useAuthStore } from "../../../stores/auth/useAuthStore";
import { useTemplateActions } from "../../../stores/templates/useTemplateActions";
import { useTemplateStore } from "../../../stores/templates/useTemplateStore";
import { useExtensionRequest } from "../../apis/extension/useExtensionRequest";
import { useTemplateRequest } from "../../apis/templates/useTemplateRequest";


export const useTemplateHandlers = () => {

    const { paginateTemplates,  createTemplate, updateTemplate, deleteTemplate } = useTemplateRequest()
    const { setTemplates, setTemplatesParameters, setTemplatesFilters, resetTemplatesStore } = useTemplateActions()
    const { setupTemplatesContextMenu } = useExtensionRequest()

    const { store: {  templatesParameters, templatesFilters } } = useTemplateStore()
    const { store: { authData }} = useAuthStore()

    const { page, pageSize } = templatesParameters
    const { searchByName } = templatesFilters

    const getTemplatesHandler = async () => {
        const params = {
            page: page, 
            pageSize: pageSize, 
            // sort: sort,
            searchByName: searchByName
        }

        const result = await paginateTemplates(params)

        setTemplates(result.templates)//todo: check this
        setTemplatesParameters({
            ...templatesParameters,
            total: result.metadata.totalPaginated
        })

        setupTemplatesContextMenu(authData)
    }

    const getTemplatesSelectHandler = async() => {
        const result = await paginateTemplates({
            page: 1,
            pageSize: 100
        })
        return result ?? []
    }

    const createTemplateHandler = async (payload: any) => {
        await createTemplate(payload)
        await getTemplatesHandler()
    }

    const updateTemplateHandler = async (payload: any) => {

        const templateId = payload._id

        await updateTemplate(templateId, payload)
        await getTemplatesHandler()
    }

    const deleteTemplateHandler = async (templateId: string) => {
        await deleteTemplate(templateId)
        await getTemplatesHandler()
    }

    const setTemplatesHandler = (payload: any) => {
        setTemplates(payload)
    }

    const setTemplatesParametersHandler = (payload: any) => {
        setTemplatesParameters(payload)
    }

    const setTemplatesFiltersHandler = (payload: any) => {
        setTemplatesFilters(payload)
    }

    const resetTemplatesStoreHandler = () => {
        resetTemplatesStore();
    }

    return {
        getTemplatesHandler,
        createTemplateHandler,
        updateTemplateHandler,
        deleteTemplateHandler,
        setTemplatesHandler,
        setTemplatesParametersHandler,
        setTemplatesFiltersHandler,
        getTemplatesSelectHandler,
        resetTemplatesStoreHandler
    };
}
