import React, { useEffect } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';

import { ReactComponent as IconGoogle } from '../../../assets/img/signup/google.svg';

import Input from '../../../components/Input';
import Checkbox from '../../../components/Checkbox';
import { analytic, getPageTitle, getPageUrl, googleLibrary, isValid } from '../../../utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppHandlers } from '../../../services/actions/app/useAppHandlers';
import { useAuthActions } from '../../../stores/auth/useAuthActions';
import { useAuthHandlers } from '../../../services/actions/auth/useAuthHandlers';
import { useAuthRequest } from '../../../services/apis/auth/useAuthRequest';
import { IInvite } from '../../../interfaces/models/invite';
import { AppRoutes } from '../../../interfaces/store/appStore';
import { styles } from '../../../styles/themes/style';

const SignUpSection = React.forwardRef((props: any, ref: any) => {
    const [values, setValues] = React.useState<any>({});
    const [valid, setValid] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [invite, setInvite] = React.useState<any>();
    const [searchParams, setSearchParams] = useSearchParams();
    const { showSuccessToast, showErrorToast } = useAppHandlers();
    const { resetAuthStore } = useAuthActions()
    const { setAuthDataHandler } = useAuthHandlers()
    const { signUpEmail, signUpGoogle, invitePublic } = useAuthRequest();
    const navigate = useNavigate();

    /* useEffect(() => {
        resetAuthStore()
    }, []); */

    useEffect(() => {
        document.title = "LeadDelta Signup";
      }, []);

    const googleCallback = React.useCallback((...args: any[]) => {
        if (typeof googleLibrary.callback === 'function') googleLibrary.callback(...args);
    }, []);

    const initGoogle = React.useCallback(() => {
        // Google
        if ((window as any).google) {
            // new
            (window as any).google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                callback: googleCallback,
                use_fedcm_for_prompt: true
            });

            // oauth2 init token
            googleLibrary.oauth2 = (window as any).google.accounts.oauth2.initTokenClient({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                scope: 'profile email'
            });
        }
    }, []);

    const googlePrompt = React.useCallback(() => {
        if ((window as any).google) {
            googleLibrary.callback = SignupWithGoogle;

            (window as any).google.accounts.id.prompt();
        }
    }, []);

    const cleanUp = React.useCallback(() => {
        window.history.pushState({}, '', window.location.pathname);
    }, []);

    const SignupWithEmail = React.useCallback(async () => {
        setLoading(true);

        try {
            if (valid) {
                const response = await signUpEmail(
                    {
                        ...values,

                        ...(invite && { inviteCode: invite?.invite?.code })
                    },
                    {
                        params: {
                            fp_uid: Cookies.get('_fprom_ref'),
                            fp_tid: Cookies.get('_fprom_tid')
                        }
                    }
                );

                if (response?.user) {
                    // update auth data 
                    setAuthDataHandler({ accessToken: `Bearer ${response.token}`, refreshToken: response.refreshToken });

                    // analytic 
                    analytic({
                        event: 'free_trial_signup',
                        pageUrl: getPageUrl(),
                        pageTitle: getPageTitle(),
                        method: 'credentials'
                    });

                    //showSuccessToast({ message: `You signed up successfully!` });

                    // clean up 
                    cleanUp();

                    // Auto open the extension 
                    // if all the checks are good 
                    //navigate(AppRoutes.PROCESSING)
                }
                else {
                    //showErrorToast({ message: response?.message });
                }
            }
        }
        catch (error) {
            console.log('Sign up with email', error);
        }

        setLoading(false);
    }, [values, invite, valid]);

    const SignupWithGoogle = React.useCallback(async (...args: any[]) => {
        setLoading(true);
        console.log('On sign up with Google', ...args);

        const googleResponse = args[0];

        const valid_ = googleResponse.access_token || googleResponse.credential;

        const inviteValueObj = localStorage.getItem('inviteValue')

        try {
            if (valid_) {
                const body: any = {};

                if (inviteValueObj) {
                    const inviteValue = JSON.parse(inviteValueObj) as IInvite;
                    body['inviteCode'] = inviteValue?.['invite']?.['code'];
                    localStorage.removeItem('inviteValue');
                }

                if (googleResponse.access_token) body['access_token'] = googleResponse.access_token;

                if (googleResponse.credential) body['credential'] = googleResponse.credential;

                const response = await signUpGoogle(
                    body,
                    {
                        params: {
                            fp_uid: Cookies.get('_fprom_ref'),
                            fp_tid: Cookies.get('_fprom_tid')
                        }
                    }
                );

                if (response?.user) {
                    // update auth data 
                    setAuthDataHandler({ accessToken: `Bearer ${response.token}`, refreshToken: response.refreshToken });

                    // analytic 
                    analytic({
                        event: 'free_trial_signup',
                        pageUrl: getPageUrl(),
                        pageTitle: getPageTitle(),
                        method: 'google'
                    });

                    //showSuccessToast({ message: `You signed up successfully!` });

                    // clean up 
                    cleanUp();

                    // Auto open the extension 
                    // if all the checks are good 
                    //navigate(AppRoutes.PROCESSING)
                }
                else {
                    //showErrorToast({ message: response?.message });
                }
            }
        }
        catch (error) {
            console.log('Sign up with Google', error);
        }

        setLoading(false);
    }, [invite, valid]);

    const init = React.useCallback(async () => {
        // Init Google 
        initGoogle();

        const inviteCode = searchParams.get('invite');

        if (inviteCode) {
            const inviteValue = await invitePublic(inviteCode);

            if (inviteValue?.invite) {
                // Update invite 
                setInvite(inviteValue);
                localStorage.setItem('inviteValue', JSON.stringify(inviteValue));

                // Update values 
                updateValue('email', inviteValue.invite?.to?.email);
            }
        }

        setLoaded(true);

        // Google sign up propmpt 
        googlePrompt();
    }, []);

    React.useEffect(() => {
        init();
    }, []);

    const getGoogleAccessToken = () => {
        return new Promise((resolve, reject) => {
            if (googleLibrary.oauth2) {
                googleLibrary.oauth2.callback = (response: any) => resolve(response);

                googleLibrary.oauth2.error_callback = (response: any) => resolve(response);

                googleLibrary.oauth2.requestAccessToken();
            }
        });
    }

    const onGoogleSignUpClick = React.useCallback(async () => {
        const response = await getGoogleAccessToken();

        // Use SignupWithGoogle method with response as an argument
        SignupWithGoogle(response);
    }, []);

    const updateValid = React.useCallback((valuesNew: any) => {
        const validNew = (
            valuesNew.firstName?.length > 1 &&
            valuesNew.lastName?.length > 1 &&
            isValid.email(valuesNew.email) &&
            valuesNew.password?.length > 7);
        //&& valuesNew.confirmed

        setValid(validNew ?? false);
    }, []);

    const updateValue = React.useCallback((property: string, valueNew: any) => {
        setValues((previous: any) => {
            const valuesNew = {
                ...previous,
                [property]: valueNew
            };

            // Valid 
            updateValid(valuesNew);

            return valuesNew;
        });
    }, []);

    return (
        <>{loaded && <>
        <FormHeader>
        <Title>Let's get you started</Title>

        <Description>Already have an account? <Link onClick={() => navigate(AppRoutes.SIGNIN)}>Log In</Link> instead.</Description>
    </FormHeader>

    <FormMain>
        <Row
            style={{
                gap: 20
            }}
        >
            <Label>
                First name

                <Input
                    placeholder='eg. John'

                    value={values.firstName || ''}

                    onChangeHandler={valueNew => updateValue('firstName', valueNew)}
                />
            </Label>

            <Label>
                Last name

                <Input
                    placeholder='eg. Doe'

                    value={values.lastName || ''}

                    onChangeHandler={valueNew => updateValue('lastName', valueNew)}
                />
            </Label>
        </Row>

        <Label>
            Email address

            <Input
                placeholder='e.g. hey@email.com'

                type='email'

                value={values.email || ''}

                onChangeHandler={valueNew => updateValue('email', valueNew)}

                disabled={!!invite}
            />
        </Label>

        <Label>
            Password

            <Input
                placeholder='At least 8 characters long'

                type={showPassword ? 'text' : 'password'}

                value={values.password || ''}

                onChangeHandler={valueNew => updateValue('password', valueNew)}

                style={{
                    paddingRight: 60
                }}
            />

            <Show
                onClick={() => setShowPassword(previous => !previous)}
            >
                {showPassword ? 'Hide' : 'Show'}
            </Show>
        </Label>

        <Confirm>
            {/* <Checkbox
                checkboxId='signupConfirm'

                checked={values.confirmed !== undefined ? values.confirmed : false}

                onClickHandler={() => updateValue('confirmed', !values.confirmed)}
            /> */}

            <span>
                By proceeding, you agree to the <a href='https://leaddelta.com/terms' target='_blank' rel='noreferrer'>Terms of Service</a> and <a href='https://leaddelta.com/privacy' target='_blank' rel='noreferrer'>Privacy Policy</a>.
            </span>
        </Confirm>
    </FormMain>

    <Column
        style={{
            gap: 24
        }}
    >
        <Button
            onClick={SignupWithEmail}

            disabled={!valid || loading}
        >
            Sign up with Email
        </Button>

        <Or>
            Or
        </Or>

        <Button
            version='light'

            onClick={onGoogleSignUpClick}

            disabled={loading}

            style={{
                padding: '0 34px'
            }}
        >
            <IconGoogle
                style={{
                    position: 'absolute',
                    top: 8,
                    left: 12
                }}
            />

            Sign up with Google
        </Button>
    </Column></>}</>
    );
});

const Link = styled.span`
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    color: ${(props: any) => props.theme.primary600};
    transition: opacity 0.3s ease 0s; 

    &:hover {
        opacity: 0.54;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row; 
`;

const Column = styled.div`
    display: flex;
    flex-direction: column; 
`;

const FormHeader = styled(Column)`
    gap: 6px; 
`;

const FormMain = styled(Column)`
    gap: 16px; 
`;

const Title = styled.h1`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px; 
    color: ${(props: any) => props.theme.primaryColor}; 
    margin: 0px; 
`;

const Description = styled.p`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${(props: any) => props.theme.nonaryColor}; 
    margin: 0px; 
`;

const Label = styled.label`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    gap: 6px; 

    font-family: NunitoSans;
    letter-spacing: 0.5px;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    font-weight: bold;
    color: ${(props: any) => props.theme.nonaryColor}; 
    cursor: default;
`;

const Show = styled.span`
    position: absolute;
    bottom: 8px;
    right: 12px;
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${(props: any) => props.theme.primary600};
    cursor: pointer;
    user-select: none; 
`

const Confirm = styled(Row)`
    gap: 12px;
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px; 
    color: ${(props: any) => props.theme.nonaryColor}; 
    margin-top: 13px;
`;

const Button: any = styled.button` 
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${(props: any) => props.theme.secondaryColor};
    border: none;
    outline: none;
    margin: 0;
    width: 100%;
    background: ${(props: any) => props.theme.primary600};
    padding: 0 10px;
    border-radius: 4px; 
    min-height: 34px;
    user-select: none; 
    transition: color .14s, background .14s, opacity .3s, transform .3s; 

    ${(props: any) => props.version === 'light' ? `
        color: ${props.color === 'default' ? props.theme.primaryColor : props.theme.primary600};
        background: ${props.theme.secondaryColor};
        border: 1px solid ${styles.colors.primary600};
    ` : ''}

    ${(props: any) => props.disabled ? `
        cursor: default;
        color: ${props.theme.disabledState};
        background-color: ${props.theme.senaryColor}; 
        pointer-events: none;
    ` : ''}

    &:hover {
        opacity: 0.74;
    }

    &:active {
        transform: scale(0.97);
    }
`;

const Or = styled.p`
    position: relative; 
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${(props: any) => props.theme.nonaryColor}; 
    margin: 0; 
    overflow: hidden;

    &:before {
        content: '';
        height: 1px;
        width: 50%;
        background: ${(props: any) => props.theme.disabledState};
        top: 50%;
        right: calc(50% + 21px);
        display: block;
        position: absolute;
        transform: translateY(-0.5px);
    }

    &:after {
        content: '';
        height: 1px;
        width: 50%;
        background: ${(props: any) => props.theme.disabledState};
        top: 50%;
        left: calc(50% + 21px);
        display: block;
        position: absolute;
        transform: translateY(-0.5px);
    }
`;

const ImageSuccess = styled.img`
    width: 100%;
    height: auto;
`;

const Meta = styled.p`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${(props: any) => props.theme.nonaryColor}; 
    margin: 0;
    margin-top: 24px; 
`

export default SignUpSection; 
