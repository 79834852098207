import { FunctionComponent, useState } from "react";

import WhyToCancelSection from "./cancel/WhyToCancelSection";
import WhatCanWeDoBetterSection from "./cancel/WhatCanWeDoBetterSection";
import ThanksForFeedbackSection from "./cancel/ThanksForFeedbackSection";
import SubscriptionCanceledSection from "./cancel/SubscriptionCanceledSection";

export interface ICancelationFlowQuestions {
    code?: string,
    description?: string,
    ceo?: string
}

const CancelFlow: FunctionComponent = () => {
    const [section, setSection] = useState(1)
    const [state, setState] = useState<ICancelationFlowQuestions>({
        'code': '',
        'description': '',
        'ceo': ''
    })

    const nextSection = () => setSection(previous => previous + 1)
    const previousSection = () => setSection(previous => previous - 1)

    return (
        <>
            {section === 1 && <WhyToCancelSection nextSection={nextSection} setState={setState} />}
            {section === 2 && <WhatCanWeDoBetterSection nextSection={nextSection} previousSection={previousSection} setState={setState} />}
            {section === 3 && <ThanksForFeedbackSection nextSection={nextSection} state={state} />}
            {section === 4 && <SubscriptionCanceledSection />}
        </>
    )
}

export default CancelFlow