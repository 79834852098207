import { ISubscriptionData } from "../../../interfaces/response/subscriptionData"
import { TCallbackFunction } from "../../../interfaces/common";
import { useAuthStore } from "../../../stores/auth/useAuthStore"
import { useBillingStore } from "../../../stores/billing/useBillingStore"
import { IResponse, useAxios } from "../useAxios"
import { ICancelationFlowQuestions } from "../../../components/modal/modals/cancelation-flow/CancelFlow";

export interface IBillingRequestParams {
    clb?: TCallbackFunction<IResponse>,
    errorClb?: TCallbackFunction<any>
}

export interface IBuySubscriptionParams {
    paymentMethodId: string,
    billingEmail?: string,
    billingName?: string,
    customerId?: string,
    currency?: string,
    licenceQuantity?: string
    priceId?: string
    couponId?: string
    prorationDate?: string
}

export interface IResumeSubscriptionParams {
    resumePause?: boolean,
    resumeCancel?: boolean
}

export interface IUpcomingPaymentParams {
    priceId: string,
    quantity: number,
    currency?: string,
    couponId?: string,
}

export const useBillingRequest = () => {
    const { sendRequest } = useAxios()
    const { store: { authData: { accessToken, user } } } = useAuthStore()
    const { store: { selectedBillingPlan, subscriptionId, appliedCoupon, setupIntent, upcomingPayment, licenceQuantity } } = useBillingStore()
    const route = '/billing/v2'

    //GET
    const getBillingPlansRequestHandler = (params?: IBillingRequestParams) => {
        return sendRequest({
            slug: 'billingPlans',
            url: `${route}/plans`,
            method: 'GET',
            ...params
        })
    }

    const getSetupIntentRequestHandler = (params?: IBillingRequestParams) => {
        return sendRequest({
            slug: 'setupIntent',
            url: `${route}/setup`,
            method: 'GET',
            ...params
        })
    }
    const calculateUpcomingPaymentRequestHandler = (params: IUpcomingPaymentParams) => {
        return sendRequest({
            slug: 'calculateUpcomingPayment',
            url: `${route}/subscription/upcoming`,
            method: 'GET',
            params
        })
    }
    const getInvoicesRequestHandler = (invoiceId?: string, params?: IBillingRequestParams) => {
        return sendRequest({
            slug: 'invoices',
            url: `${route}/invoices`,
            method: 'GET',
            params: {
                limit: 20,
                ...invoiceId && { startingAfterId: invoiceId }
            },
            ...params
        })
    }
    const getCurrentPayingPriceRequestHandler = (priceId?: string) => {
        return sendRequest({
            enabled: !!priceId,
            slug: 'currentPrice',
            url: `${route}/prices/${priceId}`,
            method: 'GET'
        })
    }
    const getBillingPlansFeaturesRequestHandler = (params?: IBillingRequestParams) => {
        return sendRequest({
            slug: 'plansFeatures',
            url: `${route}/features`,
            method: 'GET',
            ...params
        })
    }
    //PUT
    const selectSubscriptionRequestHandler = (params?: IBillingRequestParams) => {
        return sendRequest({
            enabled: !!accessToken && !!selectedBillingPlan,
            slug: 'billingSubscription',
            url: `${route}/subscription`,
            method: 'PUT',
            payload: {
                priceId: selectedBillingPlan?.priceId,
                quantity: 1,
                //couponId: //todo: add coupon
            },
            ...params
        })
    }
    const buySubscriptionRequestHandler = (buySubscriptionParams: IBuySubscriptionParams, params?: IBillingRequestParams) => {
        return sendRequest({
            enabled: !!buySubscriptionParams.paymentMethodId,
            slug: 'buySubscription',
            url: `${route}/subscription`,
            method: 'PUT',
            payload: {
                priceId: buySubscriptionParams.priceId ?? selectedBillingPlan?.priceId,
                paymentMethodId: buySubscriptionParams.paymentMethodId,
                quantity: buySubscriptionParams.licenceQuantity ? +buySubscriptionParams.licenceQuantity : licenceQuantity,
                couponId: buySubscriptionParams.couponId ?? appliedCoupon?.id,
                customerId: buySubscriptionParams.customerId ?? setupIntent?.customer,
                context: "workspace",//todo: workspace
                ...buySubscriptionParams.prorationDate && { prorationDate: +buySubscriptionParams.prorationDate },
                ...buySubscriptionParams.currency && { currency: buySubscriptionParams.currency }
            },
            ...params
        })
    }
    const confirmPaymentRequestHandler = (paymentMethodId: string, params?: IBillingRequestParams) => {//todo: remove
        return sendRequest({
            enabled: !!subscriptionId,
            slug: 'confirmPayment',
            url: `${route}/subscription/confirmation`,
            method: 'PUT',
            payload: {
                subscriptionId: subscriptionId,
            },
            ...params
        })
    }
    const applyCouponRequestHandler = (couponCode: string, params?: IBillingRequestParams) => {
        return sendRequest({
            enabled: !!couponCode,
            slug: 'applyCoupon',
            url: `${route}/promotion`,
            method: 'PUT',
            payload: {
                code: couponCode,
            },
            ...params
        })
    }
    const pauseSubscriptionRequestHandler = (resumesAt: number, params?: IBillingRequestParams) => {
        return sendRequest({
            slug: 'pauseSubscription',
            url: `${route}/subscription/pause`,
            method: 'PUT',
            payload: {
                resumesAt: resumesAt,
            },
            ...params
        })
    }
    const resumeSubscriptionRequestHandler = (resume?: IResumeSubscriptionParams, params?: IBillingRequestParams) => {
        return sendRequest({
            slug: 'resumeSubscription',
            url: `${route}/subscription/resume`,
            method: 'PUT',
            payload: {
                resumePause: resume?.resumePause,
                resumeCancel: resume?.resumeCancel,
                isDefault: true
            },
            ...params
        })
    }
    //POST
    const addPaymentMethodRequestHandler = (paymentMethodId: string, params?: IBillingRequestParams) => {
        return sendRequest({
            enabled: !!paymentMethodId,
            slug: 'addPaymentMethod',
            url: `${route}/payment-methods`,
            method: 'POST',
            payload: {
                paymentMethodId,
                isDefault: true
            },
            ...params
        })
    }
    //DELETE
    const deleteSubscriptionRequestHandler = (cancelationFlowQuestions?: ICancelationFlowQuestions, params?: IBillingRequestParams) => {
        console.log(cancelationFlowQuestions)
        return sendRequest({
            slug: 'cancelSubscription',
            url: `${route}/subscription`,
            method: 'DELETE',
            payload: {
                reason: cancelationFlowQuestions
            },
            ...params
        })
    }
    return {
        getBillingPlansRequestHandler,
        getSetupIntentRequestHandler,
        calculateUpcomingPaymentRequestHandler,
        selectSubscriptionRequestHandler,
        buySubscriptionRequestHandler,
        confirmPaymentRequestHandler,
        applyCouponRequestHandler,
        getInvoicesRequestHandler,
        addPaymentMethodRequestHandler,
        pauseSubscriptionRequestHandler,
        resumeSubscriptionRequestHandler,
        deleteSubscriptionRequestHandler,
        getCurrentPayingPriceRequestHandler,
        getBillingPlansFeaturesRequestHandler
    }

}