

import { ITag } from "../../interfaces/models/connection"
import { ActionType, ITagsFilterState  } from "../../interfaces/store/tagStore"
import { useTagsStore } from "./useTagsStore"


export const useTagsActions = () => {
    const { dispatch } = useTagsStore()

    const setTags = (payload: ITag[]) => {
        return dispatch({
            type: ActionType.SET_TAGS,
            payload
        })
    }
    
    const setTagsFilter = (payload: ITagsFilterState) => {
        return dispatch({
            type: ActionType.SET_TAGS_FILTER,
            payload
        })
    }

    const resetTagsStore = () => {
        return dispatch({
            type: ActionType.RESET_STORE
        })
    }

    const resetTagsPagination = () => {
        return dispatch({
            type: ActionType.RESET_TAGS_PAGINATION
        })
    }

    const setTagsParameters = (payload: any) => {
        return dispatch({
            type: ActionType.SET_TAGS_PARAMETERS,
            payload
        })
    }

    return {
        resetTagsStore,
        setTags,
        setTagsFilter,
        setTagsParameters,
        resetTagsPagination
    }
}


