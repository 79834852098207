import React, { FunctionComponent, useState } from 'react'
import { useAppHandlers } from '../../../../../services/actions/app/useAppHandlers';
import CustomModal from "../../../CustomModal";
import styled from "styled-components";
import Text from '../../../../Text';
import Checkbox from '../../../../Checkbox';
import Column from '../../../../Column';

enum ActionTypeQuestions {
    HARD_TO_USE = "HARD_TO_USE",
    TOO_EXPENSIVE = "TOO_EXPENSIVE",
    MISSING_FEATURES = "MISSING_FEATURES",
    TECHNICAL_ISSUES = "TECHNICAL_ISSUES",
    SWITCHING_PRODUCTS = "SWITCHING_PRODUCTS",
    OTHER = "OTHER"
}

interface IWhyToCancelSection {
    setState?: (e: any) => void
    nextSection: () => void
}

const WhyToCancelSection: FunctionComponent<IWhyToCancelSection> = ({ nextSection, setState }) => {
    const { setPauseFlow } = useAppHandlers()
    const [answer, setAnswer] = useState('')
    const [textarea, setTextarea] = useState('')

    const onClickHandler = (e: any) => {
        setTextarea('')
        setAnswer(e.currentTarget.id)
    }

    const onSubmitHandler = () => {
        if (answer === ActionTypeQuestions.OTHER) {
            setState?.({ code: ActionTypeQuestions.OTHER.toLowerCase(), description: textarea })
        } else {
            setState?.({ code: answer.toLowerCase(), description: null })
        }
        nextSection()
    }

    return (
        <CustomModal
            headingText={`We’re sad to see you go.`}
            descriptionText={<StyledText>{'Can you help us understand why?'}</StyledText>}
            closeButton
            cancelButton={{
                text: 'Go Back',
                mediumButton: true,
                onClickHandler: setPauseFlow
            }}
            confirmButton={{
                text: 'Next',
                mediumButton: true,
                disable: (answer.length === 0) || (answer === ActionTypeQuestions.OTHER && textarea.length < 1),
                onClickHandler: onSubmitHandler
            }}
        >
            <Column gap='10px'>
                {Object.keys(ActionTypeQuestions).map(question => {
                    return (
                        <QuestionWrapper key={question}>
                            <StyledCheckbox checkboxId={question} onClickHandler={onClickHandler} checked={answer === question} />
                            <Text $capitalize>{question.replace(/_/g, ' ').toLowerCase()}</Text>
                        </QuestionWrapper>
                    )
                })}
                <StyledTextArea
                    placeholder='Describe this up to 200 characters......'
                    spellCheck='true'
                    maxLength={200}
                    value={textarea}
                    disabled={!(answer === ActionTypeQuestions.OTHER)}
                    onChange={({ currentTarget: { value } }) => setTextarea(value)}
                />
            </Column>
        </CustomModal>
    )

}

const StyledTextArea = styled.textarea`
    width: 100%;
    height: 85px;
    padding: 10px 7px 10px 7px;
    font-family: NunitoSans;
    font-size: 14px;
    border: 2px solid ${({ theme: { quinaryColor } }) => `${quinaryColor}`};
    border-radius: 4px; 
    white-space: pre-line;
    resize: none;
    transition: all .2s ease;

    &:focus, &:hover { outline: none; }

`
const StyledCheckbox = styled(Checkbox)`
    height: fit-content;
`
const StyledText = styled.div`
    display: inline-block;
    text-align: left;
    font-weight: normal;
`

const QuestionWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`

export default WhyToCancelSection