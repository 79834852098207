import { useAxios } from "../useAxios"
import { useAuthStore } from "../../../stores/auth/useAuthStore"

export interface IWorkspaceCreateParams { 
    name: string,
    invites?: { email: string }[]
    companyName?: string
    websiteUrl?: string
    isPersonal?: boolean
}

export const useWorkspaceRequest = () => {
    const { sendRequest } = useAxios()
    const { store: { workspace } } = useAuthStore()

    const route = '/workspaces'

    //GET
    const getExistsWorkspace = (params: { name: string }) => {
        return sendRequest({
            slug: 'existsWorkspace',
            url: `${route}/exists`,
            method: 'GET',
            params
        })
    }

    const getWorkspaceUsersPublic = () => {
        return sendRequest({
            slug: 'getWorkspaceUsersPublic',
            url: `${route}/${workspace._id}/users/public`,
            method: 'GET'
        })
    }

    const getWorkspace = () => {
        return sendRequest({
            slug: 'getWorkspace',
            url: `${route}/${workspace._id}`,
            method: 'GET'
        })
    }

    const getWorkspaceUsersPublicAll = (params: any) => {
        return sendRequest({
            slug: 'workspaceUsersPublicAll',
            url: `${route}/${workspace._id}/users/public/all`,
            method: 'GET',
            params
        })
    }

    const getWorkspaceUsers = (params: any) => {
        return sendRequest({
            slug: 'getWorkspaceUsers',
            url: `${route}/${workspace._id}/users?${params}`,
            method: 'GET'
        })
    }

    const updateWorkspaceUsers = (params: {userId: string, body: any}) => {
        return sendRequest({
            slug: 'updateWorkspaceUsers',
            url: `${route}/${workspace._id}/users/${params.userId}`,
            method: 'PATCH',
            payload: params.body
        })
    }

    const getInvites = (params: any) => {
        return sendRequest({
            slug: 'getInvites',
            url: `${route}/${workspace._id}/invites?${params}`,
            method: 'GET'
        })
    };

    //POST
    const createWorkspace = (payload: IWorkspaceCreateParams, displayToast?: boolean) => {
        return sendRequest({
            slug: 'createWorkspace',
            url: `${route}`,
            method: 'POST',
            payload,
            displayToast
        })
    };

    const sendInvites = (payload: any) => {
        return sendRequest({
            slug: 'sendInvites',
            url: `${route}/${workspace._id}/invites`,
            method: 'POST',
            payload
        })
    };

    //PATCH
    const updateWorkspace = (payload: any) => {
        return sendRequest({
            slug: 'updateWorkspace',
            url: `${route}/${workspace._id}`,
            method: 'PATCH',
            payload
        })
    };

    const resendInvite = (inviteId: string) => {
        return sendRequest({
            slug: 'resendInvite',
            url: `${route}/${workspace._id}/invites/${inviteId}/resend`,
            method: 'PATCH'
        })
    };

    const revokeInvite = (params: {inviteId: string, body: any}) => {
        return sendRequest({
            slug: 'revokeInvite',
            url: `${route}/${workspace._id}/invites/${params.inviteId}`,
            method: 'PATCH',
            payload: params.body
        })
    };

    return {
        getExistsWorkspace,
        getWorkspaceUsersPublic,
        getWorkspace,
        getWorkspaceUsersPublicAll,
        createWorkspace,
        getWorkspaceUsers,
        updateWorkspaceUsers,
        updateWorkspace,
        getInvites,
        sendInvites,
        resendInvite,
        revokeInvite
    };
}

