import { useNotificationsRequest } from "../../apis/notifications/useNotificationsRequest";


export const useNotificationsHandlers = () => {

    const { markAsRead } = useNotificationsRequest()

    const markAsReadHandler = async (payload: any) => {
        const result = await markAsRead(payload)
    }

    return {
        markAsReadHandler
    };
}
