import { ActionType } from "../../interfaces/store/notesStore"
import { useNotesStore } from "./useNotesStore"


export const useNotesActions = () => {
    const { dispatch } = useNotesStore()

    const setNotes = (payload: any) => {
        return dispatch({
            type: ActionType.SET_NOTES,
            payload
        })
    }

    const setNotesParameters = (payload: any) => {
        return dispatch({
            type: ActionType.SET_NOTES_PARAMETERS,
            payload
        })
    }

    const setInitialNotesFilters = (payload: any) => {
        return dispatch({
            type: ActionType.SET_INITIAL_NOTES_FILTERS,
            payload
        })
    }

    const resetNotesStore = () => {
        return dispatch({
            type: ActionType.RESET_STORE
        })
    }

    const resetNotesPagination = () => {
        return dispatch({
            type: ActionType.RESET_NOTES_PAGINATION
        })
    }

    return {
        setNotes,
        setInitialNotesFilters,
        setNotesParameters,
        resetNotesStore,
        resetNotesPagination
    }
}


