import React, { FunctionComponent, useEffect, useState } from 'react'
import { styles } from '../../../../styles/themes/style';
import styled from 'styled-components'
import Column from '../../../../components/Column';
import Button from '../../../../components/Button';
import EmptyPage from '../../../../components/EmptyPage';
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers';
import { SidebarTypes } from '../../../../interfaces/store/appStore';
import { usePabblyWebhook } from '../../../../services/apis/pabbly/usePabblyWebhook';
import Table from '../../../../components/Table';
import Text from '../../../../components/Text';
import Row from '../../../../components/Row';
import EditDeleteActionDropdown from '../../../../fragments/dropdowns/EditDeleteActionDropdown';
import { useAppStore } from '../../../../stores/application/useAppStore';
import { usePabblyHandlers } from '../../../../services/actions/pabbly/usePabblyHandlers';


interface IPabblyProps { }

const Pabbly: FunctionComponent<IPabblyProps> = () => {
    const { openSidebar } = useAppHandlers()
    const { getPabblyWebhooksHandler, deletePabblyWebhooksHandler } = usePabblyHandlers()
    
    const { store: { requestLoading } } = useAppStore()

    const [pabblyWebhooks, setPabblyWebhooks] = useState<any>({})

    const setPabblyWebhooksHandler = async () => {
        const result = await getPabblyWebhooksHandler()
        if(result) setPabblyWebhooks(result)
    }

    useEffect(() => {
        setPabblyWebhooksHandler()
    }, [requestLoading['edit-pabbly-webhook'], requestLoading['create-pabbly-webhook'], requestLoading['delete-pabbly-webhook']])

    return (
        <Container>
            <Button $type='blue' $mediumBigButton onClickHandler={() => openSidebar(SidebarTypes.PABBLY, { events: pabblyWebhooks?.events })} >New Webhook</Button>
            <Column alignItems gap='12px'>
            {pabblyWebhooks?.webhooks?.length ? 
                <Table
                    columns={[
                        { header: 'Webhook Name & URL', key: ['nameAndUrl'], show: true, showSmall: true },
                        { header: 'Events in LeadDelta', key: ['event'], width:'225px', show: true, showSmall: true },
                        { header: 'Actions', key: ['actions'], show: true, showSmall: true }
                    ]}
                    data={pabblyWebhooks?.webhooks.map((webhook: any) => ({
                            nameAndUrl: 
                                <Column>
                                    <Text $bold>{webhook?.name}</Text>
                                    <Text $ellipsis='1'>{webhook?.url}</Text>
                                </Column>,
                            event:
                                <Text $bold>
                                    {webhook?.eventName}
                                </Text>,
                            actions:
                                <div style={{display: 'flex', justifyContent: 'flex-end'}} onClick={(e: any) => e.stopPropagation()}>
                                    <EditDeleteActionDropdown
                                        object={webhook}
                                        type='webhook'
                                        onDeleteHandler={() => deletePabblyWebhooksHandler(webhook._id)}
                                        onEditHandler={(e: any) => openSidebar(SidebarTypes.PABBLY, { events: pabblyWebhooks?.events, webhook })}
                                    />
                                </div>,
                    }))}
                />
                :
                <EmptyPage
                    svgType='integrations'
                    title='No Pabbly Connect Webhook'
                    labelNode={<Link>Create a Pabbly webhook on their website <span onClick={() => { window.open('https://www.pabbly.com/connect/', "_blank") }}>pabbly.com/connect</span>, copy it and then click the New Webhook button here, choose the LeadDelta event and see the magic happen.</Link>}
                />
            }
            </Column>
        </Container>
    )
}

const Container = styled(Column)`
    width: 100%;
    padding: 50px 16px 16px 16px;
`
const Link = styled.p`
    width: 600px;
    line-height: 20px;
    font-family: NunitoSans;
    font-size: 14px;
    color: #545e77;
    margin: 0 auto;

    & span {
        font-weight: bold;
        color: ${styles.colors.primary600};
        cursor: pointer;
    }

    & span:hover { color: #7a89fc; }
`
export default Pabbly
