import React, { FunctionComponent, useState } from 'react'
import { styles } from './../../../styles/themes/style';
import styled from 'styled-components'
import Column from '../../../components/Column';
import Row from '../../../components/Row';
import ApiKey from '../components/integrations/ApiKey';
import Pabbly from '../components/integrations/Pabbly';
import Hubspot from '../components/integrations/hubspot/Hubspot';

interface IIntegrationsSectionProps { }

const IntegrationsSection: FunctionComponent<IIntegrationsSectionProps> = () => {
    const [selectedPage, setSelectedPage] = useState<string>('apiKey')

    const sections = [
        {id: 'apiKey', label: 'API Key', enabled: true},
        {id: 'pabbly', label: 'Pabbly Connect', enabled: true},
        {id: 'hubspot', label: 'Hubspot', enabled: true}
    ]

    const renderRoute = () => {
        switch (selectedPage) {
            case 'pabbly':
                return <Pabbly />
            case 'hubspot':
                return <Hubspot />
            case 'apiKey':
            default:
                return <ApiKey />
        }
    }

    return (
        <Container>
            <Column>
                <Row gap='10px'>
                    {sections.map((integration) => integration.enabled && (
                        <NavButton key={integration.id} $disabled={false} $selected={selectedPage === integration.id}>
                            <a onClick={() => setSelectedPage(integration.id)}>{integration.label}</a>
                        </NavButton>
                    ))}
                </Row>
                {renderRoute()}
            </Column>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    padding: 16px;
`
const NavButton = styled.div<{$disabled?: boolean, $selected?: boolean}>`
    line-height: 20px;
    font-family: NunitoSans;
    font-size: 14px;
    font-weight: bold;
    padding: 0px 12px;
    height: 100%;
    display: flex;
    align-items: center;
    a {
      ${({ theme: { primaryColor } }) => primaryColor && `color: ${primaryColor};`}
    }
    ${({ $disabled }) => $disabled && `
        cursor: default;
        a, &:hover a, &:focus a{
            cursor: default;
            color: ${styles.colors.disabledState} !important;
        }
    `}
    ${({ $selected }) => $selected && `
        border-bottom: 2px solid #5964FF;
        cursor: default;
        a {
            cursor: default;
            color: ${styles.colors.primary600} !important;
        }
    `}
`
export default IntegrationsSection
