import { useAxios } from "../useAxios"
import { useAuthStore } from "../../../stores/auth/useAuthStore"


export const useRemindersRequest = () => {
    const { sendRequest } = useAxios()
    const { store: { authData: { accessToken } } } = useAuthStore()

    const route = '/reminders'

    const reminderBulk = (payload: any) => {
        return sendRequest({
            slug: 'reminderBulk',
            url: `${route}/bulk`,
            method: 'POST',
            payload
        })
    };

    const removeReminder = (reminderId: any) => {
        return sendRequest({
            slug: 'removeReminder',
            url: `${route}/${reminderId}`,
            method: 'DELETE'
        })
    };

    const bulkRemoveReminders = (body: any) => {
        return sendRequest({
            slug: 'bulkRemoveReminders',
            url: `${route}/bulk/remove`,
            method: 'PUT',
            payload: body
        })
    };

    return {
        reminderBulk,
        removeReminder,
        bulkRemoveReminders
    };
}