import { createContext, ReactNode, useContext, useReducer, Dispatch, FunctionComponent } from 'react'
import { AnyAction, Reducer } from 'redux'

/**
 * Gets store and dispatch from the reducer hook and exposes them in the context store provider wrapper
 * @param reducer 
 * @param initialState 
 * @returns { StoreProvider, useStoreContext }
 */
export const makeStore = (reducer: Reducer, initialState: any) => {

    const storeContext = createContext<{
        store: typeof initialState,
        dispatch: Dispatch<AnyAction>
    }>({
        store: initialState,
        dispatch: (() => undefined) as Dispatch<AnyAction>
    })

    interface IStoreProps {
        children?: ReactNode
    }

    const StoreProvider: FunctionComponent<IStoreProps> = ({ children }: IStoreProps) => {
        const [store, dispatch] = useReducer(reducer, initialState)

        return (
            <storeContext.Provider value={{ store, dispatch }}>
                {children}
            </storeContext.Provider>
        )
    }

    const useStoreContext = () => {
        return useContext(storeContext)
    }

    return { StoreProvider, useStoreContext }
}