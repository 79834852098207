import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Row from '../../../components/Row';
import BoxContainer from '../../../fragments/BoxContainer';
import { useAppStore } from '../../../stores/application/useAppStore';
import { TMenuItemState } from '../../../interfaces/store/appStore';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppHandlers } from '../../../services/actions/app/useAppHandlers';
import Button from '../../../components/Button';
import { useAuthStore } from '../../../stores/auth/useAuthStore';
import List from '../../../components/list/List';

interface ISettingsSectionProps { }

const SettingsSection: FunctionComponent<ISettingsSectionProps> = () => {

    const { store: { settingsState } } = useAppStore()
    const { store: { workspaceOwner, disablePremiumFeatures, workspaceAdmin }} = useAuthStore()
    const [settingsList, setSettingsList] = useState<TMenuItemState[]>([]);
    const navigate = useNavigate()
    const { openExtension } = useAppHandlers()
    const location = useLocation()
    const pathname = location.pathname;

    useEffect(() => {
        let settingsList: any = [];
        if (settingsState) {
            settingsState['workspaceAdmin'].visible = workspaceOwner || workspaceAdmin
            settingsState['billing'].visible = workspaceOwner || workspaceAdmin
            settingsList = Object.keys(settingsState).map((key) => ({
                ...settingsState[key],
                ...disablePremiumFeatures && key !== 'billing' && key !== 'workspaceAdmin' && { visible: false }
            }));
        }
          setSettingsList(settingsList);
    }, [settingsState, workspaceOwner, workspaceAdmin]);

    const handleClick = useCallback( (settingsItem: TMenuItemState) => {
          if (settingsItem.routeInternal) navigate(settingsItem.routeInternal);
          if (settingsItem.extension) openExtension();
        },
        [settingsState]
    );

    //check for selected and then display outlet if nothing selected on bile we should show the menu only

    return <Container>
        
        <StyledRow gap='24px'>
            <StyledNavigation>
                <StyledList>
                    {settingsList.map((headerItem, index) => (
                        headerItem.visible && <Button
                            $justifyLeft
                            key={index}
                            disabled={!headerItem.enabled}
                            {...pathname === headerItem.routeInternal && { $type: 'focused' }}
                            onClickHandler={() => handleClick(headerItem)}
                            $SVGtype={headerItem.icon}
                        >
                            {headerItem.title}
                        </Button>
                        ))}
                </StyledList>
            </StyledNavigation>
            <StyledBox>
                <Outlet />
            </StyledBox>
        </StyledRow>
    </Container>
}

const Container = styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    overflow-y: auto;
`
const StyledRow = styled(Row)`
    flex-wrap: wrap;
`
const StyledNavigation = styled(BoxContainer)`
    min-width: 300px;
    max-width: fit-content;
    height: fit-content;

    @media (max-width: 1240px) {
        width: 100%;
        max-width: 100%;
    }
`
const StyledBox = styled(BoxContainer)`
    width: calc(100% - 330px);

    @media (max-width: 1240px) {
        width: 100%;
        height: 100%;
    }
`
const StyledList = styled(List)`
    width: 100%;

    @media (max-width: 1240px) {
        flex-direction: row;
    }
    @media (max-width: 760px) {
        flex-direction: column;
    }
`
export default SettingsSection
