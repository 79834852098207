import React, { FunctionComponent, useState } from "react";
import { ICustomField } from "../../interfaces/models/customFields";
import Column from "../../components/Column";
import Text from "../../components/Text";
import Input from "../../components/Input";
import Select from "../../components/select/Select";
import styled from "styled-components";
import Button from "../../components/Button";
import { useAppHandlers } from "../../services/actions/app/useAppHandlers";
import { SidebarTypes } from "../../interfaces/store/appStore";

interface ICustomFieldSidebarProps {
    customField?: ICustomField
}
interface ICustomFieldStyles { }

const CreateEditCustomFieldForm: FunctionComponent<ICustomFieldSidebarProps> = ({customField}) => {
    const { closeSidebar } = useAppHandlers()

    const [fieldName, setFieldName] = useState<string>('')
    const [fieldType, setFieldType] = useState<any>({ value: 'Nothing selected', label: 'Nothing selected' })

    const fieldTypeOptions = [
        { value: 'shortText', label: 'Text field', tooltip: 'e.g. John Doe' },
        { value: 'timestamp', label: 'Date', tooltip: 'e.g 2024-05-24 11:00' },
        { value: 'number', label: 'Number', tooltip: 'e.g. 12345' },
        { value: 'boolean', label: 'True/False', tooltip: 'e.g. True or False' }
    ]

    return (
        <Column gap="10px">
            <section>
                <Text $label>{`Field Name ${fieldName?.length === 0 ? '(Required)' : ''}`}</Text>
                <Input placeholder="Enter field name" onChangeHandler={(e: any) => setFieldName(e)} />
            </section>
            <section>
                <Text $label>Field Type</Text>
                <Select
                    placeholder="Nothing Selected"
                    isSearchable={false}
                    options={fieldTypeOptions}
                    value={fieldType}
                    onChange={(e: any) => setFieldType(e)}
                />
            </section>
            <ButtonWrapper>
                <Button $type="cancel" onClickHandler={() => closeSidebar(SidebarTypes.CUSTOM_FIELD_EDIT_CREATE)}>Cancel</Button>
                <Button $type="blue" onClickHandler={() => console.log(fieldName, fieldType)}>Add field</Button>
            </ButtonWrapper>
        </Column>
    )

}

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 10px;
`

export default CreateEditCustomFieldForm

