import React, { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import { AppRoutes } from '../interfaces/store/appStore';
import { useAppStore } from '../stores/application/useAppStore';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useInterval } from './IntervalContext';
import { useAuthStore } from '../stores/auth/useAuthStore';
import moment from 'moment';
import { useAuthHandlers } from '../services/actions/auth/useAuthHandlers';
import { useAppHandlers } from '../services/actions/app/useAppHandlers';
import _ from 'lodash';
import { useAppActions } from '../stores/application/useAppActions';

interface IRouteGuardProps { }

const RouteGuard: FunctionComponent<IRouteGuardProps> = () => {
    const { store: { onboardingStep, extensionInstalled } } = useAppStore()
    const { store: { user } } = useAuthStore()
    const { stopInterval, startInterval } = useInterval();
    const { getUserHandler } = useAuthHandlers();
    const { checkExtensionStatus } = useAppHandlers()
    const { setOnboardingStep } = useAppActions()
    const lastSyncAtLocalStorage = window.localStorage.getItem('lastSyncAt')
    const lastSyncAt = lastSyncAtLocalStorage && lastSyncAtLocalStorage !== 'undefined' ? lastSyncAtLocalStorage : 0

    useEffect(() => {
        if(user?.isSynced){
            stopInterval()
        }
        if(!user?.isSynced && moment().isBefore(moment(lastSyncAt).add(20, 'minutes'))){
            startInterval(async () => getUserHandler(), 300000)
        }
      }, [user]);


    useEffect(() => {
        if (!extensionInstalled) {
            startInterval(async () => {
                if (!extensionInstalled) {
                    checkExtensionStatus();
                }
            }, 2000);
        } else if (extensionInstalled && user?.linkedinData?.linkedinNetworkInfo &&  _.isEmpty(user?.linkedinData?.linkedinNetworkInfo)) {
            stopInterval()
            setOnboardingStep(2)
        } else {
            stopInterval()
        }
    }, [user, extensionInstalled]);




    return <>
        {onboardingStep >= 0 ?
            <Navigate to={AppRoutes.PROCESSING}/> :
            <Outlet /> }
    </>
}

export default RouteGuard
