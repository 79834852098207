import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { styles } from './../styles/themes/style';
import SvgFragment from '../fragments/SvgFragment';

interface ICarouselNavigationProps {
    currentValue: number;
    finiteNumber?: number; // in case we want to make this a whole circular carousel
    clickable?: boolean;
    onClickHandler?: (e: any) => void;
}

interface IDotProps {
    $active?: boolean
}

const CarouselNavigation: FunctionComponent<ICarouselNavigationProps> = ({ currentValue, finiteNumber, clickable, onClickHandler }) => {

    return (
        <Container>
            { finiteNumber && Array(finiteNumber).fill(undefined).map((value: any, index: number) => (
                <Dot $active={currentValue === index} onClick={(e) => clickable && onClickHandler && onClickHandler(index)} key={index}><SvgFragment type={'dot'} /></Dot>
            ))}
        </Container>
    )
}

const Container = styled.div`
    height: ${styles.lengths.navbarHeight};
    display: flex;
    gap: 7px;
    align-items: center;
`
const Dot = styled.div<IDotProps>`
    ${({ $active }) => $active && `.svg .path { fill: ${styles.colors.black300}; } `}
`

export default CarouselNavigation