import React, { FormEvent, FunctionComponent, useCallback, useState } from 'react'
import styled from 'styled-components'
import Text from '../../../components/Text'
import { useAuthStore } from '../../../stores/auth/useAuthStore'
import Button from '../../../components/Button'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../interfaces/store/appStore'
import { useExternalHandlers } from '../../../services/actions/navigation/useExternalHandlers'

interface IDisabledSectionProps { }

const DisabledSection: FunctionComponent<IDisabledSectionProps> = () => {
    const { store: { workspaceOwner } } = useAuthStore()
    const { openPricing } = useExternalHandlers() 
    const navigate = useNavigate()

    const onClickHandler = useCallback(() => {
        if(workspaceOwner) navigate(AppRoutes.BILLING)
        else openPricing()
    }, [workspaceOwner])
    return <Container>
        <Text $heading3>Your LeadDelta trial has expired 🙏</Text>
        <Text $paragraph1>{workspaceOwner ? 'Check out our plans or watch a quick video' : 'Contact your workspace admin to purchase'}</Text>
        {workspaceOwner && <Button $type= 'blue' $bigButton onClickHandler={() => onClickHandler()}>{'View Plans'}</Button>}

        <VideoContainer>
                <iframe 
                            src="https://www.loom.com/embed/0e5cd4bec17a45dab4fede942c4463cb?sid=f802cd83-323d-409b-a2e4-ce9087d0d4ec" 
                            frameBorder="0"
                            allowFullScreen
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%'
                            }}
                    />
        </VideoContainer>
    </Container>
}

const Container = styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    overflow-y: auto;
    align-items: center;
    background: ${({ theme: { secondaryColor }}) => secondaryColor};
`
const VideoContainer = styled.div`
    position: relative;
    width: 50%;
    overflow: hidden;
    border-radius: 4px;
    flex-grow: 1;
    flex: 1 1;
    min-width: 400px;
`

export default DisabledSection
