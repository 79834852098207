import React, { FunctionComponent, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import styled, { DefaultTheme, useTheme } from "styled-components"
import CarouselNavigation from '../../components/CarouselNavigation'
import { useAppStore } from '../../stores/application/useAppStore'
import { AppRoutes } from '../../interfaces/store/appStore'
import Text from '../../components/Text'

interface IOnboardingPageProps { }

const onboardingTitles = [
    {
        title: "Create your LeadDelta Workspace",
        description: "Use LeadDelta solo or as part of a team. Your team can include coworkers, partners, community members, alumni, or anyone you want to share networks with."
    },
   /*{
        title: "Let's onboard you",
        description: "Tell us about yourself so we can help you set up your workspace."
    },*/
    {
        title: "Download LeadDelta Chrome App",
        description: "LeadDelta’s Chrome app boosts productivity by streamlining network management and delivering enriched contact data directly in your browser."
    },
    {
        title: "Finish the LeadDelta setup",
        description: "Follow the instructions to finish the setup of your account."
    }
]

const OnboardingPage: FunctionComponent<IOnboardingPageProps> = () => {
    const theme: DefaultTheme = useTheme()
    const [currentStep, setCurrentStep] = useState<number>(0)
    const [currentTitles, setCurrentTitles] = useState<{ title: string, description: string }>(onboardingTitles[0])
    const { store: { onboardingStep, extensionInstalled } } = useAppStore()
    const navigate = useNavigate();

    useEffect(() => {
        if(onboardingStep < 0) navigate(AppRoutes.HOME)
        setCurrentStep(onboardingStep)
        setCurrentTitles(onboardingTitles[onboardingStep])
    }, [onboardingStep])

    return <Container>
        <CenterContainer><Text $heading2 $bold $black>{currentTitles.title}</Text></CenterContainer>
        <CenterContainer><Text $lighter>{currentTitles.description}</Text></CenterContainer>
            <Outlet />
        <CenterContainer><CarouselNavigation finiteNumber={extensionInstalled ? 3 : 2} currentValue={currentStep} onClickHandler={(value) => setCurrentStep(value)} /></CenterContainer>
    </Container>
}

const Container = styled.div`
    width: 100%;
    padding: 100px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow-y:auto;
    height: 100%;
    background: ${({theme: { secondaryColor }}) => secondaryColor};
`

const CenterContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 600px;
    text-align: center;
`

export default OnboardingPage