import { useAxios } from "../useAxios"
import { useAuthStore } from "../../../stores/auth/useAuthStore"


export const useTemplateRequest = () => {
    const { sendRequest } = useAxios()

    const route = '/templates'

    const getTemplates = (params: any) => {
        return sendRequest({
            slug: 'getTemplates',
            url: `${route}`,
            method: 'GET',
            params
        })
    };

    const paginateTemplates = (params: any) => {
        return sendRequest({
            slug: 'paginateTemplates',
            url: `${route}/paginate`,
            method: 'GET',
            params
        })
    };

    const createTemplate = (payload: any) => {
        return sendRequest({
            slug: 'createTemplate',
            url: `${route}`,
            method: 'POST',
            payload
        })
    };

    const updateTemplate = (templateId: string, payload: any) => {
        return sendRequest({
            slug: 'updateTemplate',
            url: `${route}/${templateId}`,
            method: 'PUT',
            payload
        })
    };

    const deleteTemplate = (templateId: string) => {
        return sendRequest({
            slug: 'deleteTemplate',
            url: `${route}/${templateId}`,
            method: 'DELETE'
        })
    };

    return {
        getTemplates,
        paginateTemplates,
        createTemplate,
        updateTemplate,
        deleteTemplate
    };
}