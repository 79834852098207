import { SidebarTypes } from "../../../interfaces/store/appStore"
import { useConnectionsStore } from "../../../stores/connections/useConnectionsStore"
import { useSaveFiltersRequest } from "../../apis/save-filters/useSaveFiltersRequest"
import { useAppHandlers } from "../app/useAppHandlers"
import { useConnectionsHandlers } from "../connections/useConnectionsHandlers"


export const useSaveFilterHandlers = () => {

    const { getSavedFilters, saveFilters} = useSaveFiltersRequest()
    const { closeSidebar } = useAppHandlers()
    const { setConnectionsFilterHandler } = useConnectionsHandlers();
    const { store: {connectionsFilter} } = useConnectionsStore()

    const getSavedFiltersHandler = async() => {
        const result = await getSavedFilters()
        return result
    }
    

    const saveFiltersHandler = async (body: any) => {
        const result = await saveFilters(body)
        if (result) {
            closeSidebar(SidebarTypes.SAVE_FILTER);
            const updatedFilter = {
                ...connectionsFilter,
                savedFilter: !connectionsFilter.savedFilter
            };
            setConnectionsFilterHandler(updatedFilter);
        }
        return result
    }

    return {
        getSavedFiltersHandler,
        saveFiltersHandler
    }
}
