import { FunctionComponent, useState } from 'react'
import CustomModal from '../../CustomModal'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers';
import Row from '../../../Row'
import Text from '../../../Text'
import styled from 'styled-components'
import Switch from '../../../Switch';
import Column from '../../../Column';

interface IExportConnectionsModalProps {
    numberOfConnections?: number,
    selectedAllConnections?: boolean,
    onClickHandler: (includeTags: boolean, includeNotes: boolean, exportAll: boolean) => void
}

const ExportConnectionsModal: FunctionComponent<IExportConnectionsModalProps> = ({ onClickHandler, numberOfConnections, selectedAllConnections = false }) => {

    const { hideModal } = useAppHandlers()
    const [includeTags, setIncludeTags] = useState(false)
    const [includeNotes, setIncludeNotes] = useState(false)

    const description = `Export selected or all connections as a CSV file together with or without custom tags and notes.` 
    return (
        <CustomModal
            headingText={'Export Connections'}
            closeButton
            descriptionTextBold={<StyledText>{description}</StyledText>}
            confirmButton={{
                text: `Export selected (${numberOfConnections ?? 0})`,
                extraBigButton: true,
                disable: !numberOfConnections,
                onClickHandler: () => (onClickHandler(includeTags, includeNotes, selectedAllConnections), hideModal())
            }}
            infoButton={{
                text: 'Export All',
                mediumButton: true,
                onClickHandler: () => (onClickHandler(includeTags, includeNotes, true), hideModal())
            }}
            cancelButton={{
                text: 'Cancel',
                mediumButton: true,
                onClickHandler: () => hideModal()
            }}>
                <Column gap="10px">
                    <Row gap="10px">
                        <Switch prefilledValue={includeTags} onClickHandler={() => setIncludeTags(!includeTags)} />
                         <Text>{`Include Tags`}</Text>
                    </Row>
                    <Row gap="10px">
                        <Switch prefilledValue={includeNotes} onClickHandler={() => setIncludeNotes(!includeNotes)} />
                        <Text>{`Include Notes`}</Text>
                    </Row>
                </Column>
            </CustomModal>
    )
}

const StyledText =  styled(Text)`
    display: inline-block;
    text-align: left;
    font-weight: normal;
`
const StyledBoldText = styled(Text)`
    display: inline-block;
    font-weight: bold;

`

export default ExportConnectionsModal