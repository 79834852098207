import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import BoxContainer from '../../../fragments/BoxContainer'
import { useAuthStore } from '../../../stores/auth/useAuthStore'
import Text from '../../../components/Text'
import Button from '../../../components/Button'
import List from '../../../components/list/List'
import SvgFragment from '../../../fragments/SvgFragment'
import ListItem, { IListItemProps } from '../../../components/list/ListItem'
import Column from '../../../components/Column'
import Row from '../../../components/Row'
import { styles } from "../../../styles/themes/style";
import { formatNumber } from '../../../utils'
import { useTasksHandlers } from '../../../services/actions/tasks/useTasksHandlers'
import { ITaskResponse } from '../../../interfaces/response/task'
import { useNavigate } from 'react-router-dom'
import { AppRoutes, SidebarTypes } from '../../../interfaces/store/appStore'
import { useAuthHandlers } from '../../../services/actions/auth/useAuthHandlers'
import { useAppHandlers } from '../../../services/actions/app/useAppHandlers'
import { useAppStore } from '../../../stores/application/useAppStore'
import { useExternalHandlers } from '../../../services/actions/navigation/useExternalHandlers'
import { useAnalyticsHandlers } from '../../../services/actions/analytics/useAnalyticsHandlers'
import { ReactComponent as OnboardingSvg } from '../../../assets/img/Onboarding.svg'
import { ReactComponent as RoadmapSvg } from '../../../assets/img/Roadmap.svg'
import { ReactComponent as SupportSvg } from '../../../assets/img/Support.svg'
import TasksTable from '../components/home/HomeTasksTable'
import moment from 'moment'
import { useInterval } from '../../../wrappers/IntervalContext'

interface IHomeSectionProps { }

interface IAppTaskProps extends IListItemProps { $grey?: boolean, $green?: boolean, $blue?: boolean, disabled?: boolean } 

const HomeSection: FunctionComponent<IHomeSectionProps> = () => {
    useEffect(() => {
        document.title = "LeadDelta Home";
      }, []);
    const { store: { user, workspace, authData } } = useAuthStore()
    const { getHomePageTasks, getAndResolveTasksHandler } = useTasksHandlers()
    const { syncConnectionsHandler, getUserHandler, syncConversationsHandler } = useAuthHandlers()
    const { setSyncConnectionsModalHandler, openSidebar, setConnectionsOverviewModal } = useAppHandlers()
    const { store: { extensionInstalled } } = useAppStore()
    const { visitChromeStore, openOnboarding, openRoadmap, chatWithUs } = useExternalHandlers()
    const [ tasks, setTasks ] = useState<ITaskResponse[]>([])
    const navigate = useNavigate()
    const { getTotalConnectionsOverviewAnalytics } = useAnalyticsHandlers()
    const { startInterval } = useInterval();

    // const [connectionsOverviewModal, setConnectionsOverviewModal] = useState<boolean>(false)
    const [ analytics, setAnalytics ] = useState<any>({
        'connections-total': 0,
        "connections-imported-csv-total": 0,
        'connections-updated': 0,
        'connections-updated-connected-to-linkedin': 0
    })

    useEffect(() => {
        if(workspace) reloadTasksHandler();
        getTotalConnectionsOverviewAnalyticsHandler()
    }, [workspace])

    const reloadTasksHandler = useCallback(async (resolved?: boolean) => {
        let tasks = await getHomePageTasks(true)
        if(tasks.length && !resolved) loadTasksStateHandler(tasks)
        tasks = !tasks.length ? await getHomePageTasks() : tasks
        setTasks(tasks)
    }, [workspace])

    const loadTasksStateHandler = useCallback(async (tasks: ITaskResponse[]) => {
        await getAndResolveTasksHandler(tasks)
        reloadTasksHandler(true)
    }, [])

    const setLastSyncAt = useCallback(async() => {
        if(extensionInstalled) setSyncConnectionsModalHandler((user) => { 
            syncConnectionsHandler(user, false, authData); 
            if(!user?.isSynced) {
                startInterval(async () => getUserHandler(), 300000)
            }
            syncConversationsHandler() //todo: runtime external messages called only from background, not inbox background
        })
    }, [user, extensionInstalled, workspace, authData])

    const getTotalConnectionsOverviewAnalyticsHandler = useCallback( async () => {
        const statistics = await getTotalConnectionsOverviewAnalytics();
        let statisticsObject = statistics.reduce((accumulator, value) => ({...accumulator, ...{[value.version]: value.value}}), {});
        setAnalytics({...analytics, ...statisticsObject})
    }, [])

    const setConnectionsOverviewModalHandler = async () => {
        await getTotalConnectionsOverviewAnalyticsHandler()
        // setConnectionsOverviewModal(true)
    }
    const lastSyncAtLocalStorage = window.localStorage.getItem('lastSyncAt')
    const lastSyncAt = lastSyncAtLocalStorage && lastSyncAtLocalStorage !== 'undefined' ? lastSyncAtLocalStorage : 0



    return <Container>
        <StyledBoxedContainer>
            <HeaderRow>
                <Column gap='12px'>
                    <Text $black $heading2 $bold $capitalize>{`Hi, ${user?.firstName || 'LeadDelta User'}!`}</Text>
                    <Text $lighter>{`Let's work your network.`}</Text>
                </Column>
                {/* <Button $extraBigButton $type='grey' onClickHandler={setConnectionsOverviewModalHandler}> */}
                <Button $extraBigButton $type='grey' onClickHandler={() => setConnectionsOverviewModal(analytics)}>
                    <ButtonRow gap='7px'>
                        <Text $black $bold>{formatNumber(analytics['connections-total'])}</Text>
                        <Text $lighter>{'Number of connections'}</Text>
                    </ButtonRow>
                </Button>
            </HeaderRow>
            <List>
           {!extensionInstalled && <AppTask $blue onClickHandler={() => visitChromeStore()}>
                    <SvgFragment type='import'/>
                    <Column>
                        <Text $bold>{'Download LeadDelta Chrome App'}</Text>
                        <Text $lighter>{'Experience full productivity boost and data enrichment.'}</Text>
                    </Column>
                </AppTask>
            }
            {!(user?.isSynced || moment().isBefore(moment(lastSyncAt).add(20, 'minutes'))) && 
            <AppTask $green disabled={!extensionInstalled} onClickHandler={()=>setLastSyncAt()}>
                    <SvgFragment type='refresh'/>
                    <Column>
                        <Text $bold $heading5>{'Sync your LinkedIn connections'}</Text>
                        <Text $lighter>{extensionInstalled ? 'Auto-sync you LinkedIn connections daily.' : 'Download LeadDelta Chrome app first and then sync your connections.'}</Text>
                        {/* getLinkedinConnections background script, figure out how to do it in the background, check extension and so  */}
                    </Column>
                </AppTask>}
                <AppTask $grey onClickHandler={() => navigate(AppRoutes.CSV_IMPORT)}>
                    <SvgFragment type='import'/>
                    <Column>
                        <Text $bold>{'Import CSV file'}</Text>
                        <Text $lighter>{'Import CSV file.'}</Text>
                    </Column>
                </AppTask>
                <AppTask $grey onClickHandler={() => openSidebar(SidebarTypes.WORKSPACE_CREATE, { invite: true })}>
                    <SvgFragment type='profileAdd'/>
                    <Column>
                        <Text $bold>{'Add co-workers to your workspace'}</Text>
                        <Text $lighter>{'Add colleagues and key stakeholders and start collaborating.'}</Text>
                    </Column>
                </AppTask>
            </List>

            <WrapRow gap="16px">
                <TaskColumn gap="16px">
                    <HeaderRow>
                        <Text $heading4>{'Your tasks'}</Text>
                        <Button $type='grey' $bigButton onClickHandler={() => navigate(AppRoutes.TASKS)}>{'Manage tasks'}</Button>
                    </HeaderRow>
                    <TasksTable tasks={tasks} />
                </TaskColumn>
                <TaskColumn gap="16px">
                    <iframe 
                            src="https://www.loom.com/embed/0e5cd4bec17a45dab4fede942c4463cb?sid=f802cd83-323d-409b-a2e4-ce9087d0d4ec" 
                            frameBorder="0"
                            allowFullScreen
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%'
                            }}
                    />
                </TaskColumn>
            </WrapRow>
        </StyledBoxedContainer>
        <StyledBoxedContainer>
            <HeadingRow><Text $heading4>{'Help center'}</Text></HeadingRow>
            <WrapRow gap="16px">
                <StyledSupport gap="12px" onClick={()=>openOnboarding()}>
                    <OnboardingSvg />
                    <Column gap="8px">
                        <Text $heading4>{'Onboarding'}</Text>
                        <Text $lighter>{'Just joined LeadDelta? Discover how to maximize your experience and unlock its full potential.'}</Text>
                    </Column>
                </StyledSupport>
                <StyledSupport gap="12px" onClick={()=>openRoadmap()}>
                    <RoadmapSvg />
                    <Column gap="8px">
                        <Text $heading4>{'Roadmap'}</Text>
                        <Text $lighter>{'We build for you! Share your thoughts and contribute your ideas to shape the future of LeadDelta.'}</Text>
                    </Column>
                </StyledSupport>
                <StyledSupport gap="12px" onClick={()=>chatWithUs()}>
                    <SupportSvg />
                    <Column gap="8px">
                        <Text $heading4>{'Support'}</Text>
                        <Text $lighter>{'Need help? Explore our support hub and helpful articles or connect with our LeadDelta support team.'}</Text>
                    </Column>
                </StyledSupport>
            </WrapRow>
        </StyledBoxedContainer>
    </Container>
}

const Container = styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    overflow-y: auto;
    align-items: center;
`
const StyledBoxedContainer = styled(BoxContainer)`
    max-width: 1024px;
`

const HeaderRow = styled(Row)`
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const ButtonRow = styled(Row)`
    justify-content: center;
`

const WrapRow = styled(Row)`
    width: 100%;
    flex-wrap: wrap;
`
const TaskColumn = styled(Column)`
    position: relative;
    height: 400px;
    overflow: hidden;
    border-radius: 4px;
    flex-grow: 1;
    flex: 1 1;
    min-width: 400px;
`

const AppTask = styled(ListItem)<IAppTaskProps>`
    padding: 10px 16px;
    border-radius: 4px;
    gap: 10px;
    align-items: flex-start;
    ${({ $grey, theme: { quinaryColor, senaryColor } }) => $grey && `
        background: ${senaryColor};
        border: 1px solid ${quinaryColor};
        & .svg .path {
            fill: ${styles.colors.primary600}
        }
    `}
    ${({ $blue }) => $blue && `
        background: ${styles.colors.blue200};
        border: 1px solid ${styles.colors.primary600};
        & .svg .path {
            fill: ${styles.colors.primary600}
        }
    `}
    ${({ $green }) => $green && `
        background: ${styles.colors.green200};
        border: 1px solid ${styles.colors.green600};
        & .svg .path {
            fill: ${styles.colors.green600}
        }
    `}
    ${({ disabled, theme: { quinaryColor, senaryColor } }) => disabled && `
        background-color: ${senaryColor};
        border: 1px solid ${quinaryColor};
        color: ${quinaryColor};
        & .svg .path {
            fill: ${styles.colors.black300}
        }
        span  {
            color: ${styles.colors.black300}
        }
    `}
    &:hover { 
        cursor: pointer;
        box-shadow: 0px 0px 12px 0px rgba(51, 57, 81, 0.10);
    }
`
const StyledImg = styled.img`
    width: 320px;
`
const StyledSupport = styled(Column)`
    width: 320px;
    &:hover {
        opacity: 0.75;
        cursor: pointer;
    }
`
const HeadingRow = styled(Row)`
    width: 100%;
`

export default HomeSection

