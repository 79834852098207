import { useEffect } from "react"
import { IBillingPlan, IBillingPlanV2, IPrice } from "../../../interfaces/models/billingPlan"
import { IUpcomingPayment } from "../../../interfaces/response/upcomingPayment"
import { useAppActions } from "../../../stores/application/useAppActions"
import { useAuthActions } from "../../../stores/auth/useAuthActions"
import { useAuthStore } from "../../../stores/auth/useAuthStore"
import { useBillingActions } from "../../../stores/billing/useBillingActions"
import { useBillingStore } from "../../../stores/billing/useBillingStore"
import { IBillingRequestParams, IBuySubscriptionParams, IResumeSubscriptionParams, IUpcomingPaymentParams, useBillingRequest } from "../../apis/billing/useBillingRequest"
import { useAuthHandlers } from "../auth/useAuthHandlers"

export const useBillingHandlers = () => {

    //hooks
    const { setToast } = useAppActions()
    const { setBillingPlans,
        setSelectedBillingPlan,
        setSelectedPrice,
        setSetupIntent,
        setAppliedCoupon,
        setInvoices,
        setUpcomingPayment,
        setBillingPlansFeatures } = useBillingActions()
    const { store: { billingPlans, invoices } } = useBillingStore()
    const { getBillingPlansRequestHandler,
        getSetupIntentRequestHandler,
        applyCouponRequestHandler,
        buySubscriptionRequestHandler,
        getInvoicesRequestHandler,
        addPaymentMethodRequestHandler,
        calculateUpcomingPaymentRequestHandler,
        getCurrentPayingPriceRequestHandler,
        resumeSubscriptionRequestHandler,
        getBillingPlansFeaturesRequestHandler } = useBillingRequest()
    const { store: { user, context, subscription, validSubscription } } = useAuthStore()
    const { setLifetime, setSubscription, setValidSubscription, setTrial } = useAuthActions()
    const { getUserHandler } = useAuthHandlers()



    //handlers
    const setSelectedBillingPlanHandler = (payload: string) => {
        //Calculate upcoming payment - apply coupon isto
        setSelectedBillingPlan(billingPlans.find((billingPlan: IBillingPlan) => billingPlan.interval === payload))
    }

    const setSelectedPlanAndPriceHandler = (plan: IBillingPlanV2, interval: string, currency: string) => {
        setSelectedBillingPlan(plan)
        const price = plan.prices.find((price: IPrice) => price.interval === interval && price.currency === currency)
        setSelectedPrice(price ?? undefined)
        return price
    }

    const getBillingPlansHandler = async () => {
        const result = await getBillingPlansRequestHandler()
        if (result && Array.isArray(result.plans)) {
            let { plans, version } = result;
            plans = plans.filter((plan: any) => plan.version === 7)

            //read through these and select the best plan based on the current subscription
            /* let selectedPlan;
            const promoCouponCode = !!localStorage.getItem('promoCouponCode')

            if (validSubscription) {
                subscription?.status !== 'active' ?
                    selectedPlan = plans.find((billingPlan: IBillingPlan) => billingPlan.interval === 'year' && version === billingPlan.version) :
                    selectedPlan = plans.find((billingPlan: IBillingPlan) => billingPlan.priceId === subscription.priceId)
                version = selectedPlan ? selectedPlan.version : version
            }

            plans = result.plans.filter((billingPlan: IBillingPlan) => billingPlan.currency === (subscription?.currency ?? 'usd') && version === billingPlan.version) */

            /* if (validSubscription && context !== "workspace") {
                selectedPlan =
                    promoCouponCode ?
                        plans.find((billingPlan: IBillingPlan) => billingPlan.interval === 'year' && version === billingPlan.version) :
                        plans.find((billingPlan: IBillingPlan) => billingPlan.interval !== subscription.interval && version === billingPlan.version)
            } */

            /* if (promoCouponCode && !validSubscription && context !== 'workspace') {
                selectedPlan = plans.find((billingPlan: IBillingPlan) => billingPlan.interval === 'year' && version === billingPlan.version)
            } */

            setBillingPlans(plans)
            //let selectedPlan = plans.find((plan: any) => plan._id === "666b05b255c9f602da7db111")
            plans.forEach((plan: any) => {
                if(plan.prices.some((price: any) => subscription.priceId === price.priceId))
                setSelectedPlanAndPriceHandler(plan, subscription.interval, subscription.currency)
            });
        }
    }

    const getBillingPlansSettingsHandler = async () => {
        const result = await getBillingPlansRequestHandler()
        if (result && Array.isArray(result.plans)) {
            let { plans, version } = result;
            plans = plans.filter((plan: any) => plan.version === 7)
            return plans
        }
        return []
    }

    const getSetupIntentHandler = async () => {
        const result = await getSetupIntentRequestHandler()
        //result && setSetupIntent(result)
        return result
    }

    const buySubscriptionHandler = async (buySubscriptionParams: IBuySubscriptionParams) => {
        const result = await buySubscriptionRequestHandler(buySubscriptionParams)
        if(result) {
            setSubscription(result)
            getUserHandler()
        }
        return result
    }

    const applyCouponHandler = async (couponCode: string) => {
        const result = await applyCouponRequestHandler(couponCode)
        result?.coupon && result?.type === 'stripe' && setAppliedCoupon({ ...result.coupon, couponCode })
        result?.type === 'native' && setLifetime(true) //todo: check to see what happens
    }
    const removeCouponHandler = async () => {
        setAppliedCoupon(undefined)
        setToast({ type: 'success', message: "Action succeeded!" })
    }
    const getInvoicesHandler = async (invoiceId?: string) => {
        const result = await getInvoicesRequestHandler(invoiceId)
        return result ?? []
    }

    const addPaymentMethodHandler = async (paymentMethodId: string) => {
        const result = await addPaymentMethodRequestHandler(paymentMethodId)
        getUserHandler()
    }

    const calculateUpcomingPaymentHandler = async (params: IUpcomingPaymentParams) => {
        const result = await calculateUpcomingPaymentRequestHandler(params)
        setUpcomingPayment(result)
    }

    const getCurrentPayingPriceHandler = async (priceId?: string) => {
        const result = await getCurrentPayingPriceRequestHandler(priceId)
        return result
    }

    const onResumeSubscriptionHandler = async (resume?: IResumeSubscriptionParams) => {
        const data = await resumeSubscriptionRequestHandler(resume)
        data && !data.pausedUntil && getUserHandler()
    }

    const getPlansFeaturesHandler = async () => {
        const result = await getBillingPlansFeaturesRequestHandler()
        console.log('billingPlansFeatures-result', result)
        if (result) setBillingPlansFeatures(result.features)
    }

    return {
        setSelectedBillingPlanHandler,
        setSelectedPlanAndPriceHandler,
        getBillingPlansHandler,
        getBillingPlansSettingsHandler,
        getSetupIntentHandler,
        buySubscriptionHandler,
        applyCouponHandler,
        removeCouponHandler,
        getInvoicesHandler,
        addPaymentMethodHandler,
        calculateUpcomingPaymentHandler,
        getCurrentPayingPriceHandler,
        onResumeSubscriptionHandler,
        getPlansFeaturesHandler
    }
}