
import { ActionType, IWorkspaceUsersFiltersState } from "../../interfaces/store/workspaceUsersStore"
import { useWorkspaceUsersStore } from "./useWorkspaceUsersStore"


export const useWorkspaceUsersActions = () => {
    const { dispatch } = useWorkspaceUsersStore()

    const setWorkspaceUsers = (payload: any[]) => {
        return dispatch({
            type: ActionType.SET_WORKSPACE_USERS,
            payload
        })
    }

    const setWorkspaceUsersParameters = (payload: any) => {
        return dispatch({
            type: ActionType.SET_WORKSPACE_USERS_PARAMETERS,
            payload
        })
    }

    const setWorkspaceUsersFilters = (payload: IWorkspaceUsersFiltersState) => {
        return dispatch({
            type: ActionType.SET_WORKSPACE_USERS_FILTERS,
            payload
        })
    }

    const resetWorkspaceUsersStore = () => {
        return dispatch({
            type: ActionType.RESET_STORE
        })
    }

    return {
        resetWorkspaceUsersStore,
        setWorkspaceUsers,
        setWorkspaceUsersParameters,
        setWorkspaceUsersFilters,
    }
}


