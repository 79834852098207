import { ActionType, ITemplatesFiltersState, ITemplatesParametersState, TTemplatesAction, TTemplateState,  } from "../../interfaces/store/templateStore";
import { makeStore } from "../makeStore";

const initialTemplatesParameters: ITemplatesParametersState = {
    page: 1,
    pageSize: 25,
    sort: 'name.az',
    total: undefined
}

const initialTemplatesFilters: ITemplatesFiltersState = {
    searchByName: ''
}

const initialState: TTemplateState = {
    templates: [],
    templatesParameters: initialTemplatesParameters,
    templatesFilters: initialTemplatesFilters
}

const reducer = (state: TTemplateState, action: TTemplatesAction): TTemplateState => {
    switch (action.type) {
        case ActionType.SET_TEMPLATES: return { ...state, templates: action.payload }
        case ActionType.SET_TEMPLATES_PARAMETERS: return { ...state, templatesParameters: { ...state.templatesParameters, ...action.payload } }
        case ActionType.SET_TEMPLATES_FILTERS: return { ...state, templatesFilters: { ...state.templatesFilters, ...action.payload } }
        case ActionType.RESET_STORE: return { ...initialState }
        default: return state
    }
}

const { StoreProvider: TemplateStoreProvider, useStoreContext: useTemplateStore } = makeStore(reducer, initialState)

export { TemplateStoreProvider, useTemplateStore }