import { FunctionComponent } from 'react'
import { useAppHandlers } from '../../../../../services/actions/app/useAppHandlers';
import { useBillingRequest } from '../../../../../services/apis/billing/useBillingRequest';
import CustomModal from "../../../CustomModal";
import { ICancelationFlowQuestions } from '../CancelFlow';
import styled from 'styled-components';

interface IThanksForFeedbackSection {
    state?: ICancelationFlowQuestions
    nextSection: () => void
}

const ThanksForFeedbackSection: FunctionComponent<IThanksForFeedbackSection> = ({ nextSection, state }) => {
    const { hideModal } = useAppHandlers()
    const { deleteSubscriptionRequestHandler } = useBillingRequest()

    const onConfirmHandler = async () => {
        const data = await deleteSubscriptionRequestHandler(state)
        data && data.cancelDate && nextSection()
    }

    return (
        <CustomModal
            headingText={'Thanks for the feedback! Here’s what happens next.'}
            descriptionText={<StyledText>{'Once your billing period expires you will lose access to your LeadDelta account and all its data: tags, notes, etc.'}</StyledText>}
            closeButton
            cancelButton={{
                text: 'I changed my mind',
                bigButton: true,
                onClickHandler: hideModal
            }}
            confirmButton={{
                text: 'Cancel subscription',
                bigButton: true,
                onClickHandler: onConfirmHandler
            }}
        >
        </CustomModal>
    )

}
const StyledText = styled.div`
    display: inline-block;
    text-align: left;
    font-weight: normal;
`


export default ThanksForFeedbackSection