import React, { useState, useContext, FunctionComponent } from 'react'
import styled, { ThemeContext } from 'styled-components'

import { Dropdown, DropdownButton } from 'react-bootstrap'
import Checkbox from '../components/Checkbox';
import Row from '../components/Row';
import Column from '../components/Column';
import DropdownMenu from '../components/dropdown/DropdownMenu';
import { styles } from '../styles/themes/style';
import DropdownItem from '../components/dropdown/DropdownItem';
import Text from '../components/Text';
import SvgFragment from './SvgFragment';

export interface ICustomColorModalProps {
    color?: string,
    disabled?: boolean,
    customDesign?: boolean,
    onColorChangedHandler: (e: any) => void
    openCustomColorModalHandler: (e: any) => void
}

const ColorSelectDropdownFragment: FunctionComponent<ICustomColorModalProps> = ({ color, disabled, customDesign = false, onColorChangedHandler, openCustomColorModalHandler }) => {
    const colors = [
        { colorName: 'Default', colorCode: styles.colors.primary600, id: 'default-color' },
        { colorName: 'Blue', colorCode: styles.colors.blue600 },
        { colorName: 'Turquoise', colorCode: styles.colors.turquise600 },
        { colorName: 'Green', colorCode: styles.colors.green600 },
        { colorName: 'Yellow', colorCode: styles.colors.yellow600 },
        { colorName: 'Orange', colorCode: styles.colors.orange600},
        { colorName: 'Red', colorCode: styles.colors.red600 },
        { colorName: 'Gray', colorCode: styles.colors.black600 },
    ]

    return (
        <DropdownMenu 
            $hideToggle={disabled}
            disabled={disabled}
            title={<ColorDropdown style={!customDesign ? { backgroundColor: color } : { backgroundColor: 'transparent', border: `2px solid ${styles.colors.primary600}` }} />}
        >
            {colors.map((color: any) => (
                <DropdownItem key={color?.colorCode} onClickHandler={() => onColorChangedHandler(color.colorCode)}>
                    <Row>
                        <ColorCircle style={{ backgroundColor: color.colorCode }} />
                        <StyledText>{color.colorName}</StyledText>
                    </Row>
                </DropdownItem>
            ))}
            <DropdownItem onClickHandler={openCustomColorModalHandler}>
                <Row alignItems gap='15px'>
                    <SvgFragment type='plus' />
                    <StyledText>Add Custom Color</StyledText>
                </Row>
            </DropdownItem>
        </DropdownMenu>
    )
}

const ColorDropdown = styled.span`
    height: 16px;
    width: 16px;
    display: inline-block;
    border-radius: 50%;
    background-color: red;
    margin-right: 6px;
`
const ColorCircle = styled.span`
    height: 16px;
    width: 16px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px;
    line-height: 32px;
    vertical-align: middle;
`
const StyledText = styled(Text)`
    font-weight: normal;
`

export default ColorSelectDropdownFragment