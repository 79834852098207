export const useExternalHandlers = () => {

    const LIDomain = 'https://www.linkedin.com'
    const LIPagePrefix = 'https://www.linkedin.com/in/'

    const navigateExternalAndFocus = (link: string, newTab = true) => {
        window.open(link, !newTab ? '_self' : '_blank')?.focus();
    }

    const navigateExternal = (link: string, newTab = true) => {
        window.open(link, !newTab ? '_self' : '_blank');
    }

    const visitLIProfile = (publicIdentifier: string) => {
        navigateExternal(`${LIPagePrefix}${publicIdentifier}`)
    }

    const visitChromeStore = () => {
        navigateExternalAndFocus('http://url1530.leaddelta.com/ls/click?upn=WQjiRiZ5QXBrlipRDLcJClxxOdohzRaMOuO4cA-2BwU8mGUyWmJXhaSejB0L-2FzWeSXyeQASLvoLADDdm2hM8RN-2Fmb8AP-2FF8XgvUbJXJL7sOg0A-2FaA0KhONHiDG4lh3v-2FbLg1pavd9SGBTlQ36a1zJA7Q-3D-3DKPpv_bpBWbMOC6QRgMfkjC5vUAm5tc7MgjQYPBIgyJDgrwM3gOf26HrXUbWknSGQoRp6-2Fabp-2BLayI6Q2AxQJGAyRC6SVrbuGzXVQgToGQW7DjIL-2B0tZRJJNlsQS36qXOCnLN-2Bx7bZIQ6AV17R5I5nX-2BFqy8KH39UMEGrgsLHC73f-2BnMzLWvdJgulk-2BL2JflzzpK-2Fu2vJGRifepXvAB1Gl3MCde-2FT-2FJKBE986L18leU8UF254W5F8aNUiNL8QZXLx7QPjm1LmfaH99vQWqC72wONcSOG7IQRFj8aaLod5eOYNQcynaNPO0xG-2FA-2FfhXV1umiW3aWoKHkmaMJUhk1X3-2Fh4h-2FIw-3D-3D')
    }

    const openHelpDesk = () => {
        navigateExternal('https://help.leaddelta.com/en/')
    }

    const openOnboarding = () => {
        navigateExternal('https://onboarding.leaddelta.com/')
    }

    const openAbout = () => {
        navigateExternal('https://leaddelta.com/about/')
    }

    const openRoadmap = () => {
        navigateExternal('https://roadmap.leaddelta.com/')
    }

    const chatWithUs = () => {
        navigateExternal('https://help.leaddelta.com/en/')
    }

    const openCSVImportStart = () => {
        navigateExternal("https://help.leaddelta.com/en/article/csv-import-130ngwd/")
    }

    const openCSVRequirements = () => {
        navigateExternal("https://help.leaddelta.com/en/article/what-is-the-right-format-for-the-csv-file-import-1dc2aax/")
    }

    const openPermissionHandler = () => {
        navigateExternal(`https://help.leaddelta.com/en/article/give-permission-to-leaddelta-to-sync-your-linkedin-connections-13d9ydl/`)
    }

    const openDemoProduct = () => {
        navigateExternal(`https://try.leaddelta.com/meetings/leaddelta-marketing/demo`)
    }

    const openTermsAndConditions = () => {
        navigateExternal(`https://leaddelta.com/terms`)
    }

    const openPrivacyPolicy = () => {
        navigateExternal(`https://leaddelta.com/privacy`)
    }

    const openPricing = () => {
        navigateExternal(`https://leaddelta.com/pricing`)
    }
    
    const openSyncMessage = () => {
        navigateExternal(`https://help.leaddelta.com/en/article/maximizing-the-power-of-tags-oa8b7j/`)
    }

    const updateLeadDeltaExtension = () => {
        navigateExternal(`https://help.leaddelta.com/en/article/how-to-ensure-you-have-the-latest-version-of-leaddelta-chrome-app-updates-autoupdating-1d592fu/`)
    }

    const openLinkedinWebsite = () => {
        navigateExternal(`https://linkedin.com/`)
    }

    return {
        LIPagePrefix,
        visitLIProfile,
        visitChromeStore,
        openHelpDesk, 
        openOnboarding, 
        openAbout,
        openRoadmap,
        chatWithUs,
        openCSVImportStart,
        openCSVRequirements,
        openPermissionHandler,
        openDemoProduct,
        openTermsAndConditions,
        openPrivacyPolicy,
        openPricing,
        openSyncMessage,
        updateLeadDeltaExtension,
        openLinkedinWebsite
    }
}