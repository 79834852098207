import React, { FormEvent, FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import DropdownMenu from '../../../components/dropdown/DropdownMenu'
import { ReactComponent as USDSvg } from '../../../assets/img/currency/usd.svg';
import { ReactComponent as EURSvg } from '../../../assets/img/currency/eur.svg';
import DropdownItem from '../../../components/dropdown/DropdownItem';
import Row from '../../../components/Row';
import Text from '../../../components/Text';

interface ICurrencySelectProps {
    prefilledValue?: string
    onChangeHandler: (e: string) => void
    className?: string
 }


const currencyValues: any[] = [
    {
        svg: USDSvg,
        currency: 'USD',
        key: 'usd'
    },
    /* {
        svg: CADSvg,
        currency: 'CAD',
        key: 'cad'
    },
    {
        svg: AUDSvg,
        currency: 'AUD',
        key: 'aud'
    }, */
    {
        svg: EURSvg,
        currency: 'EURO',
        key: 'eur'
    }
]

const CurrencySelect: FunctionComponent<ICurrencySelectProps> = ({prefilledValue, onChangeHandler, ...props}) => {

    const [currency, setCurrency] = useState<any>(currencyValues[0])

    useEffect(() => {
        let newCurrency = currencyValues.find(currencyValue => currencyValue.key === prefilledValue)
        setCurrency(newCurrency ?? currencyValues[0])
    }, [])

    const onClickHandler = (currencyValue: any) => {
        setCurrency(currencyValue)
        onChangeHandler(currencyValue.key)
    }

    const menuTitle =  <Row gap='5px' alignItems>
                <currency.svg />
                <Text $lighter>{currency.currency}</Text>
            </Row>
    
    return <DropdownMenu title={menuTitle} {...props}>
            {currencyValues.map((value, index) => (
                <DropdownItem key={index} onClickHandler={() => onClickHandler(value)}>
                    <Row gap='5px' alignItems>
                        <value.svg />
                        <Text $lighter>{value.currency}</Text>
                    </Row>
                </DropdownItem>))}
        </DropdownMenu>
}

const Container = styled.div`
    width: 100%;
    padding: 20px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow-y:auto;
    height: 100%;
`

export default CurrencySelect
