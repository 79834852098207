import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import DashboardChart from './DashboardChart';
import LoaderCell from '../../../../components/LoaderCell';
import Row from '../../../../components/Row';
import NoDataPlaceholder from './NoDataPlaceholder';
import { styles } from '../../../../styles/themes/style';

const ChartsPreloader: FunctionComponent<{title: string}> = ({title}) => {
    const listPreloader = [ ...Array(10)].map( (value, index) => {
        return <ListLinePreloader key={index}>
                <Row gap='10px' alignItems>
                    <LoaderCell $circle $height='12px' $width='12px'/>
                    <LoaderCell $height='20px' $width='110px'/>
                </Row>
                <LoaderCell $height='20px' $width='45px'/>
            </ListLinePreloader>
    })

    return <>
        <ChartAreaPreloader>
            <LoaderCell $circle $height='150px' $width='150px'/>
        </ChartAreaPreloader>
        <LegendTitle>{title}</LegendTitle>
        <ChartLegendPreloader>
            {listPreloader}
        </ChartLegendPreloader>
    </>
}

interface DashboardChartBoxProps {
    type: string,
    title: string,
    data: any[]
    fetchingData: boolean
    total: number,
    dataLineClickHandler: (e: any) => void
}

const DashboardChartBox: FunctionComponent<DashboardChartBoxProps> = ({ type, fetchingData, data, title, total, dataLineClickHandler }) => {
    const [graphData, setGraphData] = useState({});
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [init, setInit] = React.useState(false); 

    useEffect(() => {
      if (data?.length) {
        data = data.sort((a: any,b: any) => b.count - a.count)
        let labels: any = []
        let values: any = []
        let slicedData = data.slice(0, 10);
        slicedData.forEach((element: any) => {
            values.push(element.count);
            labels.push(element._id ?? 'Not disclosed')
        }); 
        setFilteredData(slicedData);
        setGraphData({
          labels,
          datasets: [
            {
              data: values,
              backgroundColor: [
                '#5964FF',
                '#A1FBFF',
                '#03C9F7',
                '#FCD9B4',
                '#FCB977',
                '#A7FCD7',
                '#34E09E',
                '#FFECC7',
                '#FCCF5E',
                '#9FABFF'
              ],
              borderColor: [
                '#5964FF',
                '#A1FBFF',
                '#03C9F7',
                '#FCD9B4',
                '#FCB977',
                '#A7FCD7',
                '#34E09E',
                '#FFECC7',
                '#FCCF5E',
                '#9FABFF'
              ],
              borderWidth: 1,
            },
          ],
        })
      }
    }, [data]) 

    React.useEffect(() => {
      if (!fetchingData && !init) setInit(true); 
    }, [fetchingData]);

    return (
        <Container verticalCenter={!fetchingData && !data?.length}>
            { !fetchingData && !data?.length ? 
                <NoDataPlaceholder svgType={type} title={`No ${type} to show`} /> :
                <>
                  <Title> {title && title} </Title>
                  { (fetchingData || !init) ? <ChartsPreloader title={type} /> : 
                    <DashboardChart dataSet={graphData} 
                        data={filteredData} 
                        total={total} 
                        title={type} 
                        dataLineClickHandler={dataLineClickHandler} /> }
                </> 
            }
        </Container>
    )
}
const Container = styled.div<{verticalCenter: boolean}>`
    display: flex;
    ${({ verticalCenter }) => verticalCenter && 'justify-content: center;'}
    flex-direction: column;
    align-items: center;
    padding: 20px;
    flex: 1 1 auto; 
    background: ${({theme: { secondaryColor }}) => secondaryColor};
    border-radius: 16px;
    min-height: 375px;
    max-height: 700px;
`
const Title = styled.span`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: ${styles.colors.black500};
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
`
const ListLinePreloader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
`
const ChartAreaPreloader = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`
const LegendTitle = styled.div`
    display: flex;
    width: 100%;
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: ${({theme: { quaternaryColor }}) => quaternaryColor};
    margin-bottom: 10px;
    text-transform: capitalize;
`
const ChartLegendPreloader = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    overflow-y: auto;
`
export default DashboardChartBox