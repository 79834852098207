import React, {useEffect, useState, FunctionComponent} from 'react'
import styled from 'styled-components'
import { styles } from '../styles/themes/style'
import { useAuthHandlers } from '../services/actions/auth/useAuthHandlers'
import { useAuthStore } from '../stores/auth/useAuthStore'
import SvgFragment from './SvgFragment'

const CrispChat: FunctionComponent = () => {
    const { saveUserPreferencesHandler } = useAuthHandlers()
    const { store: { user } } = useAuthStore()
    const [toggleChat, setToggleChat] = useState(true)

    useEffect(() => { if(window.$crisp) window.$crisp.push(["on", "chat:closed", () => { setToggleChat(true) }]) }, [])

    const onCloseHandler = async () => {
        setToggleChat(user?.preferences?.crispChat === undefined ? false : !user?.preferences?.crispChat)
        await saveUserPreferencesHandler({crispChat: user?.preferences?.crispChat === undefined ? false : !user?.preferences?.crispChat})
    }

    return (        
        <>
            {toggleChat &&
                <Container>
                    <SvgClose onClick={onCloseHandler}><SvgFragment type='close' /></SvgClose>
                    <ChrispChat onClick={() => { window.$crisp.push(['do', 'chat:open']); setToggleChat(false) }}/>
                </Container>
            }
        </>        
    )
}

const Container = styled.div`
    position: absolute;
    z-index: 2147483647; // The maximum range is ±2147483647
    bottom: 105px;
    right: 15px;
    width: 80px;
    height: 80px;
    cursor: pointer;

    &:hover div { display: block }
`
const SvgClose = styled.div`
    display: none;
    position: absolute;
    left: 65px;
    bottom: 65px;
    cursor: pointer;
`
const ChrispChat = styled.div`
    width: 60px;
    height: 60px;
    cursor: pointer;
`
export default CrispChat