import { FunctionComponent } from "react";
import styled from "styled-components";
import { styles } from "../styles/themes/style";

interface ILoaderCheckProps {
    $width?: string,
    $height?: string,
    $circle?: boolean
}

const LoaderCell: FunctionComponent<ILoaderCheckProps> = ({ $width, $height, $circle }) => {
    return (
        <LoaderCellComponent
            $width={$width}
            $height={$height}
            $circle={$circle}
        />
    )
}

const LoaderCellComponent = styled.div<ILoaderCheckProps>`
    width: ${({ $width }) => `${$width}`};
    height: ${({ $height }) => $height ? `${$height}` : '16px'};
    border-radius: ${({ $circle }) => $circle ? '50%' : '3px'};
    background-color: ${({ theme: { tertiaryColor }}) => tertiaryColor};
`

export default LoaderCell