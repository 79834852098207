import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import NoDataPlaceholder from './NoDataPlaceholder'
import StatisticsArea from './StatisticsArea'
import { styles } from '../../../../styles/themes/style'

interface StatisticsAreaBoxProps {
    type: string,
    title: string,
    data: any[]
    fetchingData: boolean
    total: number,
    dataLineClickHandler: (e: any) => void
}

const StatisticsAreaBox: FunctionComponent<StatisticsAreaBoxProps> = ({ type, fetchingData, data, title, total, dataLineClickHandler }) => {

    return (
        <Container verticalCenter={!fetchingData && !data?.length}>
            { !fetchingData && !data?.length ? 
                <NoDataPlaceholder svgType={type} title={`No ${type} to show`} /> :
                <>
                  <Title> {title && title} </Title>
                  <StatisticsArea 
                        data={data} 
                        fetchingData={fetchingData} 
                        total={total} 
                        dataLineClickHandler={dataLineClickHandler} />
                </> 
            }
        </Container>
    )
}
const Container = styled.div<{verticalCenter: boolean}>`
    display: flex;
    ${({ verticalCenter }) => verticalCenter && 'justify-content: center;'}
    flex-direction: column;
    align-items: center;
    padding: 20px;
    flex: 1 1 auto; 
    background: ${({theme: { secondaryColor }}) => secondaryColor};
    border-radius: 16px;
    min-height: 375px;
    max-height: 700px;
`
const Title = styled.span`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: ${styles.colors.black500};
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
`
export default StatisticsAreaBox