import { FunctionComponent, useEffect } from "react";

import { styles } from "../styles/themes/style";
import Text from "./Text";
import styled from "styled-components"
import SvgFragment from "../fragments/SvgFragment";

import { useAppHandlers } from "../services/actions/app/useAppHandlers";
import { useAppStore } from "../stores/application/useAppStore";
import ActionText from "./ActionText";

const Toast: FunctionComponent = () => {
    const { store: { toast } } = useAppStore()
    const { hideToast } = useAppHandlers()

    useEffect(() => {
        let timeout: NodeJS.Timeout// todo: check this

        if (toast) { timeout = setTimeout(() => hideToast(), (toast.duration ? toast.duration : 3) * 1000) }
        return () => clearTimeout(timeout)
    }, [toast])

    const onClickHandler = () => {
        toast?.onClick?.onClickHandler()
        hideToast()
    }

    return (
        toast &&
        <ToastBox className={`${toast.type} fade-in`}>
            <Text $white $bold>{toast.message}</Text> 
            {toast?.onClick ? <ActionText invertedVariable onClickHandler={onClickHandler}>{toast?.onClick?.label}</ActionText> : null}
            <X onClick={() => hideToast()}> <SvgFragment type="close" /> </X>
        </ToastBox>
    )
}

const ToastBox = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    position: fixed;
    bottom: 30px;
    left: 20px;
    color: white;
    padding: 10px 20px;
    height: 50px;
    box-sizing: border-box;
    border-radius: 5px;
    z-index: 3009;

    &.success { background-color: ${styles.colors.green600}; }
    &.info { background-color: ${styles.colors.blue600}; }
    &.warning { background-color: ${styles.colors.yellow600}; }
    &.error { background-color: ${styles.colors.red600};}
    &.reSendMessage { background-color: ${styles.colors.red600};}
`

const X = styled.div`
    margin-left: 50px;
    cursor: pointer;
`

export default Toast