import { FunctionComponent} from 'react'
import styled from 'styled-components'
import { useAppHandlers } from '../services/actions/app/useAppHandlers'
import Row from '../components/Row'
import Button from '../components/Button'
import DropdownMenu from '../components/dropdown/DropdownMenu'
import DropdownItem from '../components/dropdown/DropdownItem'
import { styles } from '../styles/themes/style'
import Column from '../components/Column'
import Text from '../components/Text'
import DropdownEmptyState from '../components/DropdownEmptyState'

interface IFilterButtonProps{ 
    sidebar: string,
    savedFilters?: any[],
    numberOfFilters?: number,
    onSetSavedFilter?: (e: any) => void
    resetFilter?: () => void
}

const FilterButton: FunctionComponent<IFilterButtonProps> = ({ sidebar, savedFilters, numberOfFilters, onSetSavedFilter, resetFilter }) => {
    const { openSidebar } = useAppHandlers()
    return <Row gap="2px">
        {/* todo: include loading when we are fetching mutliple condition filters etc */}
        <Button $mediumButton={!numberOfFilters} $mediumBigButton={!!numberOfFilters}  $SVGtype={numberOfFilters ? '' : 'funnel'} $type='white' onClickHandler={() => openSidebar(sidebar)}>
            {numberOfFilters ? <Number>{numberOfFilters}</Number> : null}
            {'Filters'}

            {(numberOfFilters && resetFilter) ?
            <ClearSvg  onClick={(e) => {e.stopPropagation(); resetFilter();}} className='clear-svg' width="12" height="12" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.96 11.825 6 7.785l4.04 4.04a.6.6 0 0 0 .846 0l.94-.939a.6.6 0 0 0 0-.847L7.785 6l4.039-4.04a.6.6 0 0 0 0-.846l-.939-.939a.6.6 0 0 0-.847 0L6 4.215 1.96.175a.6.6 0 0 0-.846 0l-.939.939a.6.6 0 0 0 0 .847L4.215 6 .175 10.04a.6.6 0 0 0 0 .847l.94.938a.6.6 0 0 0 .846 0z" fill="#8C96AD" fillRule="evenodd"/>
            </ClearSvg > : null}
        </Button>

        

        {savedFilters && <StyledDropdown 
            selectStyle
        >
            {savedFilters.length ? (
                <StyledColumn>
                    <Text $heading6 $capitalizeAll>Saved Filters</Text>
                    {savedFilters.map((savedFilter: any, index: number) => 
                        <DropdownItem key={index} onClickHandler={() => onSetSavedFilter && onSetSavedFilter(savedFilter)}>{ savedFilter.name }</DropdownItem>
                    )}
                </StyledColumn>
            ) : <DropdownEmptyState text={'No Saved Filters to Show'} />}
        </StyledDropdown>}
    </Row>
}

const StyledDropdown = styled(DropdownMenu)`
    border: none;
    &.dropdown {
        .dropdown-toggle {
            height: 34px;
            outline: none;
        }
        &:hover .dropdown-toggle {
            outline: 1px solid ${styles.colors.primary600}
        }
    }
`
const Number = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-family: NunitoSans;
    font-size: 12px;
    text-align: center;
    color: ${styles.colors.white};
    background: ${styles.colors.primary600};
`
const StyledColumn = styled(Column)`
    padding: 0 5px;
`
const ClearSvg = styled.svg`
    cursor: pointer;
    path {fill: ${styles.colors.red600};}
    &:hover path {fill: ${styles.colors.red500};}
`
export default FilterButton
