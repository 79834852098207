import React, { FunctionComponent, useEffect, useState } from 'react'
import { styles } from '../../../../../styles/themes/style';
import styled from 'styled-components'
import Column from '../../../../../components/Column';
import Row from '../../../../../components/Row';
import SvgFragment from '../../../../../fragments/SvgFragment';
import Text from '../../../../../components/Text';
import Input from '../../../../../components/Input';
import Button from '../../../../../components/Button';
import { useHubspotWebhook } from '../../../../../services/apis/hubspot/useHubspotWebhook';
import { useAppStore } from '../../../../../stores/application/useAppStore';
import { HubspotAccessToken } from '../../../../../interfaces/models/hubspot';
import { useHubspotHandlers } from '../../../../../services/actions/hubspot/useHubspotHandlers';
import { useHubspotStore } from '../../../../../stores/hubspot/useHubspotStore';

interface IAccessTokenHubspotProps {
    accessToken?: HubspotAccessToken
}

const AccessTokenHubspot: FunctionComponent<IAccessTokenHubspotProps> = () => {
    const { store: { requestLoading } } = useAppStore()
    const { store: { hubspotAccessToken } } = useHubspotStore()
    
    const { addHubspotAccessTokenHandler, removeHubspotAccessTokenHandler, setAccessTokenHandler  } = useHubspotHandlers()

    const AccessTokenHandler = async (accessToken: HubspotAccessToken) => {
        if(accessToken.exists) {
            if(accessToken._id) await removeHubspotAccessTokenHandler(accessToken._id)
        } else {
            await addHubspotAccessTokenHandler({value: accessToken.value})
        }
    }

    return (
        <Container>    
            <StyledColumn alignItems gap='12px'>
                <SvgFragment type={'integrations'} />
                <Text $heading3>HubSpot Access Token</Text>
                <Text $paragraph1 $lighter>HubSpot access token is what you provide to us, by creating a private app in your <br /> HubSpot account, in which you have to allow all the scopes that are required for us <br /> to send you data from the LeadDelta, related to your account</Text>
                <InputWrapper gap='5px' justifyCenter>
                    <Input
                        prefilledValue={hubspotAccessToken.value}
                        $disabled={hubspotAccessToken.exists || requestLoading['get-hubspot-access-token'] || requestLoading['add-hubspot-access-token'] || requestLoading['remove-hubspot-access-token']}
                        onChangeHandler={(e: any) => setAccessTokenHandler({...hubspotAccessToken, value: e})}
                    />
                    <ButtonWrapper>
                        <Button
                            $type={hubspotAccessToken.exists ? 'red' : 'blue'}
                            disabled={requestLoading['get-hubspot-access-token'] || requestLoading['add-hubspot-access-token'] || requestLoading['remove-hubspot-access-token']}
                            onClickHandler={(e: any) => AccessTokenHandler(hubspotAccessToken)}>{hubspotAccessToken.exists ? 'Remove access token' : 'Add access token'}
                        </Button>
                    </ButtonWrapper>
                </InputWrapper>
            </StyledColumn>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    padding: 16px;
`
const StyledColumn = styled(Column)`
    text-align: center;
`
const InputWrapper = styled(Row)`
    width: 600px;
`
const ButtonWrapper = styled.div`
    width: 225px;
`
export default AccessTokenHubspot
