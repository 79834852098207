//Enums
export enum ActionType {
  SET_NOTES = "SET_NOTES",
  SET_NOTES_PARAMETERS = "SET_NOTES_PARAMETERS",
  SET_INITIAL_NOTES_FILTERS = "SET_INITIAL_NOTES_FILTERS",
  RESET_STORE = "RESET_STORE",
  RESET_NOTES_PAGINATION = "RESET_NOTES_PAGINATION",
}

//States
export type TNotesState = {
  notes: any;
  notesParameters: TInitialNotesParameters;
  notesFilters: TInitialNotesFilters;
};

export type TInitialNotesParameters = {
  page: number;
  pageSize: number;
  hasNext: boolean;
  total: number | undefined;
  sort: string;
};

export type TInitialNotesFilters = {
  searchByText: string;
  searchByConnectionName: string;
  selectedUsersMutual: object[];
  usersMutualCondition: string;
  usersTagged: object[];
  usersTaggedCondition: string;
  createdAtTo: any;
  createdAtFrom: any;
  usersFilter: object[];
  usersSelected: object[];
  userCondition: string;
  selectedNotes: object[];
  noteAccessType: object;
};

interface ISetNotes {
  type: ActionType.SET_NOTES;
  payload: TNotesState["notes"];
}

interface ISetNotesParameters {
  type: ActionType.SET_NOTES_PARAMETERS;
  payload: TNotesState["notesParameters"];
}

interface ISetInitialNotesFilters {
  type: ActionType.SET_INITIAL_NOTES_FILTERS;
  payload: TNotesState["notesFilters"];
}

interface IResetStoreAction {
  type: ActionType.RESET_STORE;
}

interface IResetNotesPaginationAction {
  type: ActionType.RESET_NOTES_PAGINATION;
}

export type TNotesAction =
  | ISetNotes
  | ISetNotesParameters
  | ISetInitialNotesFilters
  | IResetStoreAction
  | IResetNotesPaginationAction;
