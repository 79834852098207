import { TriggerObject } from "../../../interfaces/models/hubspot";
import { useHubspotActions } from "../../../stores/hubspot/useHubspotActions";
import { useHubspotStore } from "../../../stores/hubspot/useHubspotStore";
import { useHubspotWebhook } from "../../apis/hubspot/useHubspotWebhook";

export const useHubspotHandlers = () => {
    const { getHubspotAccessToken, addHubspotAccessToken, removeHubspotAccessToken, getHubspotTriggers, removeHubspotTrigger, addHubspotTrigger, editHubspotTrigger  } = useHubspotWebhook()
    const { setHubspotTriggers, setHubspotAccessToken } = useHubspotActions()

    const { store: { triggers } } = useHubspotStore()

    const setAccessTokenHandler = (accessToken: any) => {
        setHubspotAccessToken(accessToken)
    }

    const getHubspotAccessTokenHandler = async () => {
        const result = await getHubspotAccessToken()
        if(result) setHubspotAccessToken({ exists: true, ...result })
    }

    const addHubspotAccessTokenHandler = async (value: object) => {
        const result = await addHubspotAccessToken(value)
        if(result) setHubspotAccessToken({ ...result, exists: true })
    }

    const removeHubspotAccessTokenHandler = async (id: string) => {
        await removeHubspotAccessToken(id)
        setHubspotAccessToken({ exists: false, value: '' })
    }

    const getHubspotTriggersHandler = async () => {
        const result = await getHubspotTriggers()
        setHubspotTriggers(result?.response ?? [])
    }

    const addHubspotTriggerHandler = async (payload: any) => {
        const result = await addHubspotTrigger(payload)
        setHubspotTriggers([result, ...triggers])
    }

    const editHubspotTriggerHandler = async (payload: any) => {
        const id = payload.id
        const trigger = payload.body

        const newArray = triggers.map((item: TriggerObject) => {
            if(item._id === id) {
                return {...item, ...trigger}
            } else {
                return item
            }
        })
        setHubspotTriggers(newArray)

        await editHubspotTrigger(payload)
    }

    const removeHubspotTriggerHandler = async (id: any) => {
        const newArray = triggers.filter((trigger: TriggerObject) => trigger._id !== id)
        setHubspotTriggers(newArray)

        await removeHubspotTrigger(id)
    }

    return {
        setAccessTokenHandler,
        getHubspotAccessTokenHandler,
        addHubspotAccessTokenHandler,
        removeHubspotAccessTokenHandler,
        getHubspotTriggersHandler,
        addHubspotTriggerHandler,
        editHubspotTriggerHandler,
        removeHubspotTriggerHandler
    };
}
