import React from 'react';

const useMediaQuery = (props: string) => {
    const [response, setResponse] = React.useState<MediaQueryList | MediaQueryListEvent>(window.matchMedia(props));

    const refs = {
        mediaQuery: React.useRef<MediaQueryList>()
    };

    const method = React.useCallback((event: MediaQueryListEvent) => setResponse(event), []);

    // Watch
    React.useEffect(() => {
        if (refs.mediaQuery.current) refs.mediaQuery.current.removeEventListener('change', method);

        refs.mediaQuery.current = window.matchMedia(props);

        // Add new event listener
        refs.mediaQuery.current.addEventListener('change', method);

        // Update the response
        setResponse(refs.mediaQuery.current);

        return () => {
            // Remove event listener
            if (refs.mediaQuery.current) refs.mediaQuery.current.removeEventListener('change', method);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    return response?.matches;
};

export default useMediaQuery;