import React, { FormEvent, FunctionComponent, useCallback, useState } from 'react'
import styled from 'styled-components'
import Column from '../../../../../components/Column'
import Row from '../../../../../components/Row'
import Text from '../../../../../components/Text'
import Button from '../../../../../components/Button'
import { useBillingHandlers } from '../../../../../services/actions/billing/useBillingHandlers'
import { useAuthStore } from '../../../../../stores/auth/useAuthStore'
import { IBillingPlanV2, IPrice } from '../../../../../interfaces/models/billingPlan'
import { AppRoutes } from '../../../../../interfaces/store/appStore'
import { useNavigate } from 'react-router-dom'
import Tag from '../../../../../components/Tag'
import { styles } from '../../../../../styles/themes/style'
import ActionText from '../../../../../components/ActionText'
import moment from 'moment'
import { useAppHandlers } from '../../../../../services/actions/app/useAppHandlers'

interface ISubscriptionOverviewProps {
    selectedBillingPlan?: IBillingPlanV2,
    selectedPrice?: IPrice
}

const SubscriptionOverview: FunctionComponent<ISubscriptionOverviewProps> = ({ selectedBillingPlan, selectedPrice }) => {

    const navigate = useNavigate();
    const { onResumeSubscriptionHandler, setSelectedPlanAndPriceHandler, getSetupIntentHandler } = useBillingHandlers();
    const { store: { subscription, trial, lifetime, workspace } } = useAuthStore() 
    const { setPauseFlow, setConfirmationModal, hideModal } = useAppHandlers()

    const handleAdditionalSeats = useCallback(async () => {
        if (selectedBillingPlan && selectedPrice) navigate(AppRoutes.BILLING_PLAN, { state: { changeSeats: true, preSelectedBillingPlan: selectedBillingPlan, preSelectedPrice: selectedPrice }})
        else navigate(AppRoutes.BILLING)
    },
    [subscription, selectedPrice, selectedBillingPlan])

    const handleCycleChange = useCallback(async () => {
        if (selectedBillingPlan && selectedPrice) {
            const newPrice = setSelectedPlanAndPriceHandler(selectedBillingPlan, selectedPrice.interval === 'year' ? 'month' : 'year', selectedPrice.currency)
            navigate(AppRoutes.BILLING_PLAN, { state: { changeCycle: true, preSelectedBillingPlan: selectedBillingPlan, preSelectedPrice: newPrice }})
        }
        else navigate(AppRoutes.BILLING)
    },
    [subscription, selectedPrice, selectedBillingPlan])

    const handlePaymentNavigation = useCallback(async () => {
        const result = await getSetupIntentHandler()
        if (result && selectedBillingPlan && selectedPrice) navigate(AppRoutes.BILLING_PAYMENT, { state: { setupIntent: result, selectedPrice, selectedBillingPlan, quantity: subscription.quantity, payNow: false} })
    },
    [subscription, selectedPrice, selectedBillingPlan])

    const onReactivatePress = () => {
        setConfirmationModal({
            headingText: "Reactivate subscription",
            descriptionText: "Reactivate your subscription and pick up right where you left off!",
            onCancelHandler: () => hideModal(),
            onConfirmHandler: async () => {
                hideModal()
                onResumeSubscriptionHandler((!subscription?.isValid && subscription?.pausedUntil) ? { resumePause: true } : { resumeCancel: true })
            }
        })
    }

    const expiring = subscription?.periodEnd ? moment(subscription?.periodEnd * 1000) : undefined

    return <Column gap="16px">
            <StyledRow spaceBetween alignItems>
                <Row gap='5px' alignItems>
                    <Text $heading4 $capitalize>{`LeadDelta ${selectedBillingPlan?.name ?? ''} ${selectedPrice?.interval ? `${selectedPrice?.interval}ly` : ''}`}</Text>
                    <Tag
                            title={trial ? 'Trial' : (!subscription?.pausedUntil && !subscription?.cancelDate || subscription?.cancelDate && subscription?.isValid && subscription?.cancelDate < moment().add(process.env.REACT_APP_SUBSCRIPTION_EXPIRING_DAYS ?? '5', 'days').unix()) ? `Active` : subscription?.pausedUntil ? `Paused` : subscription?.cancelDate > moment().add(process.env.REACT_APP_SUBSCRIPTION_EXPIRING_DAYS ?? '5', 'days').unix() ? 'Expiring soon' : `Deactivated`}
                            $borderColor={(!subscription?.pausedUntil && !subscription?.cancelDate || subscription?.cancelDate && subscription?.isValid && subscription?.cancelDate < moment().add(process.env.REACT_APP_SUBSCRIPTION_EXPIRING_DAYS ?? '5', 'days').unix()) ? styles.colors.green600 : subscription?.pausedUntil ? styles.colors.red600 : subscription?.cancelDate > moment().add(process.env.REACT_APP_SUBSCRIPTION_EXPIRING_DAYS ?? '5', 'days').unix() ? styles.colors.orange600 : styles.colors.black400}
                            $color={(!subscription?.pausedUntil && !subscription?.cancelDate || subscription?.cancelDate && subscription?.isValid && subscription?.cancelDate < moment().add(process.env.REACT_APP_SUBSCRIPTION_EXPIRING_DAYS ?? '5', 'days').unix()) ? styles.colors.green200 : subscription?.pausedUntil ? styles.colors.red200 : subscription?.cancelDate > moment().add(process.env.REACT_APP_SUBSCRIPTION_EXPIRING_DAYS ?? '5', 'days').unix() ? styles.colors.orange200 : styles.colors.black200}
                        />
                </Row>
                {!(subscription?.cancelDate && subscription?.isValid) && !subscription?.pausedUntil && <Button $bigButton $type='blue' onClickHandler={() => navigate(AppRoutes.BILLING)}>{'View plans'}</Button>}
                {/* Add paused by admin */}
                {((subscription?.cancelDate && subscription?.isValid) || subscription?.pausedUntil) && <Button $type='blue' $mediumButton onClickHandler={() => onReactivatePress()}>Reactivate</Button>}
            </StyledRow>
            {workspace && <StyledRow gap='5px' alignItems>
                <Row gap='5px'>
                    <Text>{`You have ${workspace?.seatsUsed} user(s)`}</Text>
                    <Text>{`of`}</Text>
                    <Text $label>{`${trial ? 'unlimited' : (subscription?.pausedUntil || subscription?.isValid ? subscription?.quantity : 0) + (lifetime ? 1 : 0)} seats`}</Text>
                </Row>
                {lifetime && <Text $lighter>{`(1 lifetime)`}</Text>}
                {!((subscription?.cancelDate && subscription?.isValid) || subscription?.pausedUntil) && <ActionText $label onClickHandler={()=>handleAdditionalSeats()}>Buy seats</ActionText>}
            </StyledRow>}
            <StyledRow gap='5px' alignItems>
                <Text $lighter>{`${expiring && moment() > expiring ? 'Expired' : expiring && moment() < expiring && !trial ? 'Your subscription will renew' : 'Expiring'} on ${expiring ? expiring.format('MMM D, YYYY') : 'N/A'}`}</Text>
                {!((subscription?.cancelDate && subscription?.isValid) || subscription?.pausedUntil) && <ActionText $label onClickHandler={()=>handleCycleChange()}>Change cycle</ActionText>}
            </StyledRow>
            {subscription?.isValid && !trial && !(subscription?.cancelDate && subscription?.isValid) && <><Divider />
            <StyledRow spaceBetween alignItems>
                <Column gap='12px'>
                    <Text $label>{'Cancel your subscription'}</Text>
                    <StyledRow gap="2px" alignItems>
                        <Text $black>Note: do not cancel your subscription if you just want to</Text>
                        <ActionText $label onClickHandler={()=>handleAdditionalSeats()}>change team size,</ActionText>
                        <ActionText $label onClickHandler={()=>handlePaymentNavigation()}>payment method,</ActionText>
                        <Text $black>or </Text>
                        <ActionText $label onClickHandler={()=>handleCycleChange()}>billing period.</ActionText>
                    </StyledRow>
                </Column>
                <Button $type='white' $mediumButton onClickHandler={setPauseFlow}>Cancel</Button>
            </StyledRow></>}

        </Column>
}

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: ${({ theme: { quinaryColor } }) => `${quinaryColor}`};
    margin: 10px 0;
`
const StyledRow = styled(Row)`

    @media (max-width: 786px) {
        flex-direction: column;
        align-items: normal;
        gap: 10px;
    }
`
export default SubscriptionOverview
