import React, { FunctionComponent, useState } from "react";
import { ICustomField } from "../../interfaces/models/customFields";
import Column from "../../components/Column";
import Text from "../../components/Text";
import Input from "../../components/Input";
import Select from "../../components/select/Select";
import styled from "styled-components";
import Button from "../../components/Button";
import { useAppHandlers } from "../../services/actions/app/useAppHandlers";
import { SidebarTypes } from "../../interfaces/store/appStore";
import { usePabblyWebhook } from "../../services/apis/pabbly/usePabblyWebhook";
import { usePabblyHandlers } from "../../services/actions/pabbly/usePabblyHandlers";

interface IPabblyIntegrationProps {
    events?: any
    webhook?: any
}

const PabblyIntegrationForm: FunctionComponent<IPabblyIntegrationProps> = ({events, webhook}) => {
    const { closeSidebar, showSpinner, hideSpinner } = useAppHandlers()
    const { createPabblyWebhookHandler, editPabblyWebhookHandler } = usePabblyHandlers()

    const editWebhook = webhook ?? false

console.log('e', events, editWebhook)
    const [name, setName] = useState<string>(editWebhook ? webhook.name : '')
    const [webhookUrl, setWebhookUrl] = useState<string>(editWebhook ? webhook.url : '')
    const [event, setEvent] = useState<any>({
        value: editWebhook ? webhook.event : 'Choose event', 
        label: editWebhook ? webhook.eventName : 'Choose event'
    })

    const createEditPabblyWebhookHandler = async () => {
        closeSidebar(SidebarTypes.PABBLY)
        showSpinner()

        if(editWebhook) {
            await editPabblyWebhookHandler({
                _id: webhook._id, 
                body: { 
                    name: name,
                    url: webhookUrl,
                    event: event.value,
                }
            })
        } else {
            await createPabblyWebhookHandler({
                name: name,
                url: webhookUrl,
                event: event.value,
            })
        }

        hideSpinner()
    }

    return (
        <Column gap="10px">
            <section>
                <Text $label>{'Webhook Name'}</Text>
                <Input placeholder="Name..." prefilledValue={name ?? ''} onChangeHandler={(e: any) => setName(e)} />
            </section>
            <section>
                <Text $label>{'Pabbly Connect Webhook URL'}</Text>
                <Input placeholder="Pabbly Webhook URL" prefilledValue={webhookUrl ?? ''} onChangeHandler={(e: any) => setWebhookUrl(e)} />
            </section>
            <section>
                <Text $label>Choose LeadDelta Event</Text>
                <Select
                    placeholder="Choose event"
                    isSearchable={false}
                    options={events?.map((event: any) => ({
                        value: event.key,
                        label: event.name
                    }))}
                    value={event}
                    onChange={(e: any) => setEvent(e)}
                />
            </section>
            <ButtonWrapper>
                <Button $type="cancel" onClickHandler={() => closeSidebar(SidebarTypes.PABBLY)}>Cancel</Button>
                <Button $type="blue" onClickHandler={createEditPabblyWebhookHandler}>{editWebhook ? 'Edit' : 'Add'}</Button>
            </ButtonWrapper>
        </Column>
    )

}

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 10px;
`

export default PabblyIntegrationForm

