import { FunctionComponent, useEffect, useState } from "react";
import { useAuthStore } from "../stores/auth/useAuthStore";
import SvgFragment from "./SvgFragment";
import DropdownMenu from "../components/dropdown/DropdownMenu";
import styled from "styled-components";
import Text from "../components/Text";
import ImageNoNotifications from '../assets/img/no-notifications.png';
import Column from "../components/Column";
import { useNotificationsRequest } from "../services/apis/notifications/useNotificationsRequest";
import ProfilePhoto from "../components/ProfilePhoto";
import Row from "../components/Row";
import moment from "moment";
import DropdownItem from "../components/dropdown/DropdownItem";
import { styles } from "../styles/themes/style";
import { useNotificationsHandlers } from "../services/actions/notifications/useNotificationsHandlers";
import { useAuthActions } from "../stores/auth/useAuthActions";
import Badge from "../components/Badge";
import DropdownEmptyState from "../components/DropdownEmptyState";

interface INotiicationsProps { }

const Notifications: FunctionComponent<INotiicationsProps> = () => {

    const { store: { userWorkspaces, authData: { accessToken, refreshToken } } } = useAuthStore()

    const { setUserWorkspaces } = useAuthActions()
    const { markAsReadHandler } = useNotificationsHandlers() 
    const { getNotifications } = useNotificationsRequest() 

    const [notifications, setNotifications] = useState<any>({
        unread: null,
        notifications: null
    })

    const markAsRead = async () => {
        if(notifications.unread) {
            let selectedWorkspace = userWorkspaces.find((item: any) => item.selected)

            if(selectedWorkspace) selectedWorkspace.notifications.unread = 0

            const updatedUserWorkspaces = userWorkspaces.map((workspace: any) => {
                if(workspace.isSelected) {
                    return workspace.notifications.unread = 0
                } else {
                    return workspace
                }
            }) 

            setUserWorkspaces(updatedUserWorkspaces)

            setNotifications({ ...notifications, unread: null })


            await markAsReadHandler({all: true})
        }
    } 

    useEffect(() => {
        if(!notifications.notifications && (accessToken && refreshToken )) {
            let selectedWorkspace = userWorkspaces.find((item: any) => item.selected)

            const fetch = async () => {
                let { response } = await getNotifications()

                selectedWorkspace && setNotifications({
                    unread: selectedWorkspace.notifications.unread,
                    notifications: response
                })
            }
            fetch()
        }
    }, [userWorkspaces])
    return (
        <DropdownMenu autoClose='outside' $hideToggle title={ <Badge top="-5px" left="20px" number={notifications.unread} onClick={markAsRead}><SvgFragmentWrapper><SvgFragment type='notificationBell' /></SvgFragmentWrapper></Badge>}>
            <div style={{padding: '0 0 20px 20px'}}><Text $heading4>Notifications</Text></div>
            {notifications?.notifications?.length ? (<>
                {notifications.notifications.map((notification: any, index: number) => {
                    const sender = notification.senders?.[0].firstName + ' ' + notification.senders?.[0].lastName
                    
                    return (
                        <DropdownItem key={index}>
                            <Row alignItems gap="10px">
                                <ProfilePhoto capitalLetters={`${notification.senders?.[0]?.firstName.charAt(0).toUpperCase()}${notification.senders?.[0]?.lastName.charAt(0).toUpperCase()}`} />
                                <Text>{notification.type === 'note-create-tag' ? `${sender} created a new note and tagged you` : `${sender} updated the note where you are tagged`}</Text>
                            </Row>
                            <Text $lighter>Notes ∙ {moment(notification.createdAt).fromNow()}</Text>
                        </DropdownItem>
                    )
                })}
            </>) : (<>
                <DropdownEmptyState text={"No new notifications yet"} image={ImageNoNotifications} />
            </>)}
       </DropdownMenu>
    )
}

const SvgFragmentWrapper = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${styles.colors.primary100};
    border-radius: 50%;

    & svg {
        width: 16px;
        fill: transparent !important;
    }

    &:hover svg {
        opacity: 0.5;
    }
`

export default Notifications