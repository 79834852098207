import { FunctionComponent } from 'react'
import styled from 'styled-components'

interface IColorCircleProps { 
    color: string
}

const ColorCircle: FunctionComponent<IColorCircleProps> = (props) => {
    return <Container {...props}/>
}

const Container = styled.span<IColorCircleProps>`
    width: 12px;
    height: 12px;
    background: ${({ color }) => color};
    border-radius: 2px;
    margin-right: 10px;
`

export default ColorCircle