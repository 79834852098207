import React, { FunctionComponent, useEffect } from 'react'
import styled from 'styled-components'

import EditDeleteActionDropdown from '../../../../../fragments/dropdowns/EditDeleteActionDropdown';

import Column from '../../../../../components/Column';
import Text from '../../../../../components/Text';
import Button from '../../../../../components/Button';
import EmptyPage from '../../../../../components/EmptyPage';
import Table from '../../../../../components/Table';

import { useAppStore } from '../../../../../stores/application/useAppStore';
import { useAppHandlers } from '../../../../../services/actions/app/useAppHandlers';
import { SidebarTypes } from '../../../../../interfaces/store/appStore';
import { useHubspotHandlers } from '../../../../../services/actions/hubspot/useHubspotHandlers';
import { useHubspotStore } from '../../../../../stores/hubspot/useHubspotStore';

interface ITriggersHubspotProps { }

const TriggersHubspot: FunctionComponent<ITriggersHubspotProps> = () => {
    const { store: { requestLoading } } = useAppStore()
    const { store: { triggers } } = useHubspotStore()
    const { openSidebar } = useAppHandlers()
    const { removeHubspotTriggerHandler } = useHubspotHandlers()

    const resolve = (value: string) => {
        if (!value) return value; 
    
        const newValue = value.replace(/-/g, ' '); 
    
        return `${newValue.charAt(0).toUpperCase()}${newValue.slice(1)}`;
    }

    return (
        <Container>
            <Button $type='blue' $mediumBigButton onClickHandler={() => openSidebar(SidebarTypes.HUBSPOT)}>New Trigger</Button>
            <Column alignItems gap='12px'>
            <Table
                pageLoading={requestLoading['get-hubspot-triggers'] && 10}
                noDataPage={
                    <EmptyPage
                    svgType='integrations'
                    title='No HubSpot Triggers'
                    label='Create some triggers so we are able to send you your data, to your HubSpot account.'
                />}
                columns={[
                    { header: 'Name', key: ['name'], show: true, showSmall: true },
                    { header: 'Action', key: ['action'], width:'225px', show: true, showSmall: true },
                    { header: 'Event', key: ['event'], width:'125px', show: true, showSmall: true },
                    { header: 'Value', key: ['value'], show: true, showSmall: true },
                    { header: 'Actions', key: ['actions'], width:'125px', show: true, showSmall: true }
                ]}
                data={triggers?.map((trigger: any) => ({
                        name: <Text $bold $ellipsis='1'>{trigger?.name}</Text>,
                        action: <Text $bold $ellipsis='1'>{resolve(trigger?.action)}</Text>,
                        event: <Text $bold $ellipsis='1'>{resolve(trigger?.event)}</Text>,
                        value: <Text $bold $ellipsis='1'>{resolve(trigger?.value)}</Text>,
                        actions:
                            <div style={{display: 'flex', justifyContent: 'flex-end'}} onClick={(e: any) => e.stopPropagation()}>
                                <EditDeleteActionDropdown
                                    object={trigger}
                                    type='trigger'
                                    onEditHandler={(e: any) => openSidebar(SidebarTypes.HUBSPOT, { editTrigger: trigger })}
                                    onDeleteHandler={(e: any) => removeHubspotTriggerHandler(trigger._id)}
                                />
                            </div>
                    })
                )}
            />
            </Column>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    padding: 16px 0;
`
export default TriggersHubspot
