import { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import { styles } from "../../styles/themes/style";
import styled from "styled-components"
import Button from '../../components/Button'
import ColorSelectDropdownFragment from '../ColorSelectDropdownFragment';
import Row from '../../components/Row';
import { useTagsHandlers } from '../../services/actions/tags/useTagsHandlers'
import { useAppHandlers } from '../../services/actions/app/useAppHandlers';
import { ITag } from '../../interfaces/models/tag';
import { useTagsStore } from '../../stores/tags/useTagsStore';
import PrivateCheckBoxControl from '../PrivateCheckBoxControl';
import { useAuthStore } from "../../stores/auth/useAuthStore"
import FormControl from '../../components/FormControl';
import Text from '../../components/Text';


interface ICreateEditTagFormProps {
    tag?: ITag
}

interface ITagFormState extends Record<string,any> {
}

const CreateEditTagForm: FunctionComponent<ICreateEditTagFormProps> = ({tag}) => {
    //todo: later imporove  for this to be a form with validations
    const { createTagHandler, editTagHandler } = useTagsHandlers();
    const { store: { tags } } = useTagsStore()
    const { store: { workspace} } = useAuthStore()
    const { setCustomColorModalHandler } = useAppHandlers();
    const isPrivate = true;
    const [tagValue, setTagValue] = useState({ title: '', color: styles.colors.primary600, private: isPrivate }); 

    useEffect(() => {
        if(tag) setTagValue(tag)
    }, [tag])
    const onInputChangeHandler = ({ target: { value: title }}: any) => title.length <= 30 ? setTagValue(previousState => ({ ...previousState, title })) : null
    const onColorChangedHandler = (color: any) => { setTagValue(previousState => ({ ...previousState, color: color }))}


    const openCustomColorModalHandler = () => setCustomColorModalHandler(tagValue?.color ?? styles.colors.primary600, onColorChangedHandler)
    const onPrivate = (value: boolean) => setTagValue(previousState => ({ ...previousState, private: value }))
    const onAddNewTagHandler = () => {createTagHandler({ title: tagValue.title.trim(), color: tagValue.color, private: tagValue.private })}
    const onEditTagHandler = useCallback(async () => {
        if (tag) await editTagHandler(tag?._id, { title: tagValue.title.trim(), color: tagValue.color, private: tagValue.private })
    }, [tags, tagValue])
    const onSaveHandler = (e: FormEvent) => {
        e.preventDefault();
        if (tag) { onEditTagHandler() }
        else onAddNewTagHandler()
    }

    return (
        <StyledForm>
            <FormControl>
                <Text $label>Tag name</Text>
                <StyledInput
                    placeholder='Name your tag...'
                    spellCheck='false'
                    value={tagValue.title}
                    onChange={onInputChangeHandler}
                    id='tag-name-input'
                />
            </FormControl>
            <FormControl>
                <Text $label>Color</Text>
                    <ColorSelectDropdownFragment
                    color={tagValue?.color}
                    onColorChangedHandler={onColorChangedHandler}
                    openCustomColorModalHandler={openCustomColorModalHandler}
                />
            </FormControl>
            { 
            workspace.isBusiness && <CheckboxWrapper>
                <PrivateCheckBoxControl 
                    type='tag'
                    value={tagValue.private}
                    onChangeHandler={onPrivate}
                />
            </CheckboxWrapper>
            }
            <Row alignItems>
                <Button
                    $type="blue"
                    tooltipMessage='Save Changes'
                    onClickHandler={onSaveHandler}
                    //  _.some(existingTags, ['title', tagValue.title])
                    disabled={!tagValue.title.trim()}
                >
                    Save Changes
                </Button>
            </Row>
        </StyledForm>
    )
}

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const BodySectionName = styled.p`
    color: ${props => props.theme.primaryColor};
    font-family: NunitoSans;
    font-size: 11px;
    line-height: 11px;
    margin: 0 0 6px 0;
`

const StyledInput = styled.input`
    width: 100%;
    height: 34px;
    background-color: ${props => props.theme.primary ? 'white' : '#4e515c'};
    border: 2px solid ${props => props.theme.primary ? '#dae0e8' : '#4e515c'};
    border-radius: 4px;
    padding: 0 8px;
    font-family: NunitoSans;
    font-size: 14px;
    color: ${styles.colors.primary600};

    &:hover, &:focus { 
        outline: none; 
        border: 2px solid ${styles.colors.primary600};
    }

    ::placeholder { color: #8c96ad; }
`

const CheckboxWrapper = styled.div`
`




export default CreateEditTagForm;