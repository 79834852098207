import React, { FunctionComponent, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { styles } from '../../styles/themes/style'

interface ICSVPageProps { }

const CSVPage: FunctionComponent<ICSVPageProps> = () => {

    return (
        <Container>
            <Outlet />
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${styles.colors.uiBackground};
`

export default CSVPage
