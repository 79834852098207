import { FunctionComponent } from 'react'
import Tag from '../components/Tag'
import { styles } from '../styles/themes/style'
import { ITag } from '../interfaces/models/connection'

interface IAppTagProps {
    tag: ITag
    $width?: string
    shortVersion?: boolean
    onDestructiveClickHandler?: (e: any) => void
 }

const AppShortTag: FunctionComponent<IAppTagProps> = ({ tag, $width, shortVersion = false, onDestructiveClickHandler }) => {

    let SVGtype

    if (['LinkedIn', 'LinkedIn 1st', 'Imported'].includes(tag.title)) SVGtype = "linkedin"  
    //else if (['Imported'].includes(tag.title)) SVGtype = "imported" 
    else if (['New Connection'].includes(tag.title)) SVGtype = "sync" 
    else if (['CSV'].includes(tag.title)) SVGtype = "csv"
    else if (['Disconnected'].includes(tag.title)) SVGtype = "disconnectedWhite"
    else if (['Prospect'].includes(tag.title)) SVGtype = "leadDelta"
    else if (!tag.private) SVGtype = "tagCompany"
    else SVGtype = ""
    return  <Tag $height='18px'
        onDestructiveClickHandler={onDestructiveClickHandler}
        $backgroundColor={SVGtype !== 'disconnectedWhite' ? styles.colors.white : tag.color}
        $borderColor={SVGtype.length > 0 && tag.isForbidden ? (SVGtype === 'disconnectedWhite' ? tag.color : styles.colors.primary600) : styles.colors.white} 
        $color={SVGtype.length > 0 && tag.isForbidden ? '' : tag.color} 
        title={SVGtype === 'sync' ? 'New' : (tag.title === 'LinkedIn 1st' ? '1st' : '')} 
        SVGtype={SVGtype === 'sync' ? undefined : SVGtype}
        $width={$width}
        $isDisconnected={SVGtype==='disconnectedWhite'}    
        />
}

export default AppShortTag
