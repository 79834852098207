import styled from 'styled-components';

function AppContainer(props: any) {

    return (
        <Root>
            {props.children}
        </Root>
    );
}

const Root = styled.div`
    width: 100%;
    height: 100vh;
    overflow: hidden;
`

export default AppContainer;
