
import { HubspotAccessToken, TriggerObject } from "../../interfaces/models/hubspot"
import { ActionType } from "../../interfaces/store/hubspotStore"
import { useHubspotStore } from "./useHubspotStore"


export const useHubspotActions = () => {
    const { dispatch } = useHubspotStore()

    const setHubspotTriggers = (payload: TriggerObject[]) => {
        return dispatch({
            type: ActionType.SET_HUBSPOT_TRIGGERS,
            payload
        })
    }

    const setHubspotAccessToken = (payload: HubspotAccessToken) => {
        return dispatch({
            type: ActionType.SET_HUBSPOT_ACCESS_TOKEN,
            payload
        })
    }

    return {
        setHubspotAccessToken,
        setHubspotTriggers
    }
}


