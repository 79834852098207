import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import Text from "../../../components/Text";
import Column from "../../../components/Column";

import { ReactComponent as ImportCSVOne } from "../../../assets/csv/import-csv-one.svg";
import { ReactComponent as ImportCSVArrow } from "../../../assets/csv/import-csv-arrow.svg";
import { ReactComponent as ImportCSVTwo } from "../../../assets/csv/import-csv-two.svg";
import { ReactComponent as ImportCSVIcon } from "../../../assets/csv/import-csv-icon.svg";
import { styles } from "../../../styles/themes/style";
import SvgFragment from "../../../fragments/SvgFragment";
import { useCSVImportRequest } from "../../../services/apis/csv/useCSVimportRequest";
import ActionText from "../../../components/ActionText";
import BottomBarCSV from "../components/BottomBarCSV";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../interfaces/store/appStore";
import Row from "../../../components/Row";
import { useExternalHandlers } from "../../../services/actions/navigation/useExternalHandlers";


interface IImportCSVProps { }

const ImportCSV: FunctionComponent<IImportCSVProps> = () => {
    const navigate = useNavigate()
    const { openCSVImportStart, openCSVRequirements } = useExternalHandlers()
    const formData = new FormData()

    const { importCSV } = useCSVImportRequest()

    const [csvData, setCSVData] = useState<any>(undefined)

    const handleFileInputChange = async (e: any) => {
        const csvData = e.target.files[0];
        e.target.value = null; // Clear the value of the file input element
        
        if(csvData && csvData.size <= 5 * 1024 * 1024) {
            formData.append('csv', csvData)

            const result = await importCSV(formData)

            setCSVData(result)
        }
    }

    useEffect(() => {
        console.log(csvData)
    }, [])

    return (
        <Container alignItems>
            <Column alignItems gap="30px">
                <Text $heading3 $bold>Import files</Text>
                <Wrapper alignItems gap="30px">
                    <Row alignItems gap="30px">
                        <ImportCSVOne />
                        <ImportCSVArrow />
                        <ImportCSVTwo />
                    </Row>
                    <Column alignItems gap="20px">
                        <TextWrapper>
                            <Text>Upload any CSV files with Contact information with a maximum file size limit of 5MB.</Text>
                        </TextWrapper>
                        <Row alignItems gap="10px">
                            {csvData && (
                                <ImportPreview alignItems gap="5px">
                                    <ImportCSVIcon />
                                    <Text>{csvData?.originalFilename?.replace(/(.{15})..+/, "$1...csv")}</Text>
                                </ImportPreview>
                            )}
                            <StyledText>
                                {csvData ? undefined : <SvgFragment type="folderIcon" />}
                                {csvData ? 'Change' : 'Import your file'}
                                <StyledInput type="file" accept='.csv' onChange={(e: any) => handleFileInputChange(e)}/>
                            </StyledText>
                        </Row>
                    </Column>
                    <Column alignItems gap="5px">
                        <Text $bold>Need help getting started?</Text>
                        <Row>
                            <ActionText onClickHandler={() => openCSVImportStart()}>How the CSV import works</ActionText>&nbsp;|&nbsp;<ActionText onClickHandler={() => openCSVRequirements()}>CSV file requirements</ActionText>
                        </Row>
                    </Column>
                    <BottomBarCSV disabled={!csvData} onClickHandler={() => navigate(AppRoutes.CSV_MAPPING,  { state: { csvData } })} />
                </Wrapper>
            </Column>
            
        </Container>
    )
}

const Container = styled(Column)`
    width: 100%;
    height: 100%; //BottomBarCSV height
    padding-top: 40px;
`
const StyledColum = styled(Column)`
    flex-direction: row;
`
const TextWrapper = styled.div`
    width: 290px;
    text-align: center; 
`
const Wrapper = styled(Column)`
    padding: 30px;
    border: 1px solid ${styles.colors.black200};
    background-color: ${styles.colors.white};
`
const StyledInput = styled.input`
    display: none;
`
/* todo: align this with the new design and other input fields */
const StyledText = styled.label`
    display: flex;
    align-items: center;
    background-color: ${styles.colors.primary600};
    padding: 10px;
    border-radius: 5px;
    color: ${styles.colors.white};
    font-weight: 600;
    cursor: pointer;
`
const ImportPreview = styled(Row)`
    height: 40px;
    padding: 5px;
    border: 2px solid ${styles.colors.primary600};
    border-radius: 4px;
`
export default ImportCSV