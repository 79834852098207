import { FunctionComponent, useState } from 'react'
import CustomModal from '../../CustomModal'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers';
import Row from '../../../Row'
import Text from '../../../Text'
import styled from 'styled-components'
import Switch from '../../../Switch';
import Column from '../../../Column';
import { useAuthStore } from '../../../../stores/auth/useAuthStore';

interface IDisconnectConnectionsModalProps {
    isAllSelected?: boolean,
    numberOfConnections?: number,
    newDisconnectedCount?: number
    enableLinkedinAction?: boolean
    onClickHandler: (disconnectFromLI: boolean, removeFromLD: boolean) => void
}

const DisconnectConnectionsModal: FunctionComponent<IDisconnectConnectionsModalProps> = ({ onClickHandler, isAllSelected, newDisconnectedCount = 0, numberOfConnections = 0, enableLinkedinAction }) => {
    const { hideModal } = useAppHandlers()
    const { store: { workspaceOwner, disablePremiumFeatures, trial } } = useAuthStore();
    const [disconnectFromLI, setDisconnectFromLI] = useState(false)
    const [removeFromLD, setRemoveFromLD] = useState(false)

    const description = `You can choose to Disconnect from LinkedIn and Remove Connection from LeadDelta or to perform either of these actions by setting the below switches to ON state.` 

    return (
        <CustomModal
            headingText={'Disconnect from LinkedIn/ Remove from LeadDelta'}
            closeButton
            descriptionTextBold={<StyledText>{description}</StyledText>}
            confirmButton={{
                text: 'Confirm',
                bigButton: true,
                disable: !disconnectFromLI && !removeFromLD,
                onClickHandler: () => (disconnectFromLI || removeFromLD) && (onClickHandler(disconnectFromLI, removeFromLD), hideModal())
            }}
            cancelButton={{
                text: 'Cancel',
                bigButton: true,
                onClickHandler: () =>hideModal()
            }}>
                <Column gap="10px">
                    <Row gap="10px">
                        <Switch tooltipMessage={
                                (!enableLinkedinAction) ? 'You can only disconnect your own 1st degree connections.' :
                                    (disablePremiumFeatures || trial) && newDisconnectedCount > 25 ? 'You cannot disconnect more than 25 connections in trial mode'
                                        : numberOfConnections > 25 ? 'You cannot disconnect from more than 25 Connections at once' : ''} $disabled={isAllSelected || ((disablePremiumFeatures || trial) && newDisconnectedCount > 25) || (numberOfConnections > 25) || (!enableLinkedinAction) } prefilledValue={disconnectFromLI} onClickHandler={() => setDisconnectFromLI(!disconnectFromLI)} />
                            <Text tooltipMessage={
                                (!enableLinkedinAction) ? 'You can only disconnect your own 1st degree connections.' :
                                    (disablePremiumFeatures || trial) && newDisconnectedCount > 25 ? 'You cannot disconnect more than 25 connections in trial mode'
                                        : numberOfConnections > 25 ? 'You cannot disconnect from more than 25 Connections at once' : ''}>{`Disconnect ${numberOfConnections !== 0 ? numberOfConnections : ''} from `}<StyledBoldText $bold>LinkedIn</StyledBoldText></Text>
                    </Row>
                    <Row gap="10px">
                        <Switch tooltipMessage={!workspaceOwner ? 'Only admins can remove connections from the workspace.' : ''} $disabled={!workspaceOwner} prefilledValue={removeFromLD} onClickHandler={() => setRemoveFromLD(!removeFromLD)} />
                        <Text tooltipMessage={!workspaceOwner ? 'Only admins can remove connections from the workspace.' : ''}>{`Remove  ${numberOfConnections !== 0 ? numberOfConnections : ''} from `}<StyledBoldText $bold>LeadDelta</StyledBoldText></Text>
                    </Row>
                </Column>
            </CustomModal>
    )
}

const StyledText =  styled(Text)`
    display: inline-block;
    text-align: left;
    font-weight: normal;
`
const StyledBoldText = styled(Text)`
    display: inline-block;
    font-weight: bold;

`

export default DisconnectConnectionsModal