import { FunctionComponent, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import Calendar from 'react-calendar'

import { Dropdown } from "react-bootstrap";
import Text from "../components/Text";
import SvgFragment from "./SvgFragment";
import { styles } from "../styles/themes/style";
import Select from "../components/select/Select";
import { ITask } from "../interfaces/models/task";

interface IReminderStyles {
    $selected?: boolean
    $spaceBetween?: boolean
    $disabled?: boolean,
    $isCalendar?: boolean
}

interface SvgContainerProps {
    isOpen?: boolean
}

interface IReminderProps {
    end?: number
    onChangeHandler?: (e: any) => void
}

const Reminder: FunctionComponent<IReminderProps> = ({ end, onChangeHandler }) => {


    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
    const [isCalendarOpened, setIsCalendarOpened] = useState<boolean>(false)

    const [reminder, setReminder] = useState<Date | null>(end ? moment.unix(end).toDate() : null)
    const [picker, setPicker] = useState<number>(0)

    const generateTimeOptions = () => {
        const options = [
            { value: 0, label: 'No time', component: <Text $black>No time</Text> }
        ]

        for (let i = 1; i <= 24; i++) {
            const hour = i.toString().padStart(2, '0');
            options.push({ value: i, label: `${hour}:00`, component: <Text $black>{`${hour}:00`}</Text> })
        }

        return options
    }

    const timeValuesObjects = [
        {
            valueA: 'Later today',
            valueB: '18:00',
            date: moment().set('hours', 18).set('minutes', 0).toDate(),
        },
        {
            valueA: 'Tomorrow',
            valueB: '08:00',
            date: moment().add(1, 'day').set('hours', 8).set('minutes', 0).toDate(),
        },
        {
            valueA: 'Next week',
            valueB: 'Mon, 08:00',
            date: moment().add(1, 'week').isoWeekday(1).set('hours', 8).set('minutes', 0).toDate(),
        },
    ]

    const dateChangedHandler = (props: string, event: any) => {
        if (props === 'reminder') {
            if (picker === 0) {
                setReminder(moment(event[1]).set('hours', 8).set('minutes', 0).toDate())
                onChangeHandler && onChangeHandler(moment(event[1]).set('hours', 8).set('minutes', 0).unix())
            } else {
                setReminder(moment(event[1]).set('hours', picker).set('minutes', 0).toDate())
                onChangeHandler && onChangeHandler(moment(event[1]).set('hours', picker).set('minutes', 0).unix())
            }
        }
        if (props === 'picker') {
            setPicker(Number(event.value))
            setReminder(moment(reminder).set('hours', event.value).toDate())
            onChangeHandler && onChangeHandler(moment(reminder).set('hours', event.value).unix())
        }
    }

    const resetReminder = () => {
        setReminder(null)
        setPicker(0)
        onChangeHandler && onChangeHandler(undefined)
    }

    return (
        <Container>
            <StyledDropdown
                $selected={reminder}
                onToggle={() => {
                    setIsDropdownOpen(!isDropdownOpen)
                    setIsCalendarOpened(false)
                }}
                show={isDropdownOpen}
            >
                {reminder ? <Text $black>{moment.unix(reminder.getTime() / 1000).format('MMM D, YYYY [at] HH:mm')}</Text> : <Text $placeholder>No reminder</Text>}
                <StyledDropdownToggle>
                    {reminder ? (
                        <SvgContainer onClick={resetReminder}> <SvgFragment type="dropdownClose" /> </SvgContainer>
                    ) : (
                        <SvgTransformContainer isOpen={isDropdownOpen}> <SvgFragment type="dropdownArrow" /> </SvgTransformContainer>
                    )}
                </StyledDropdownToggle>
                <StyledDropdownMenu>
                    {!isCalendarOpened ? (<>
                        {timeValuesObjects.map((v: any, index: number) => (
                            <StyledDropdownItem
                                key={index}
                                $spaceBetween
                                onClick={(event: any) => {
                                    event.stopPropagation()
                                    setReminder(v.date)
                                    onChangeHandler && onChangeHandler(moment(v.date).unix())
                                }}
                            >
                                <Text $black>{v.valueA}</Text>
                                <Text $placeholder>{v.valueB}</Text>
                            </StyledDropdownItem>
                        ))}
                        <Divider />
                        <StyledDropdownItem onClick={(event: any) => {
                            event.stopPropagation()
                            setIsCalendarOpened(true)
                        }}>
                            <SvgFragment type="clock" />
                            <Text $black>Pick Date and Time</Text>
                        </StyledDropdownItem>
                    </>) : (<>
                        <StyledDropdownItem $isCalendar={isCalendarOpened} onClick={(event: any) => event.stopPropagation()}>
                            <StyledCalendar
                                value={reminder}
                                onChange={event => dateChangedHandler('reminder', event)}
                                showNeighboringMonth={false}
                                minDate={moment().toDate()}
                                returnValue='range'
                                minDetail='month'
                                formatMonthYear={(locale, date) => moment(date).format('MMMM')}
                                defaultActiveStartDate={reminder ? reminder : moment().toDate()}
                                locale='en-US'
                            />
                        </StyledDropdownItem>
                        <Divider />
                        <StyledDropdownItem $isCalendar={isCalendarOpened} onClick={(event: any) => event.stopPropagation()}>
                            <section>
                                <Text $label $lighter>Time</Text>
                                <Select
                                    placeholder="Never"
                                    isSearchable={false}
                                    options={generateTimeOptions()}
                                    onChange={(e: any) => dateChangedHandler('picker', e)}
                                />
                            </section>
                        </StyledDropdownItem>
                    </>)}



                </StyledDropdownMenu>
            </StyledDropdown>
        </Container >
    )
}

const Container = styled.div``
const StyledDropdownToggle = styled(Dropdown.Toggle)`
    width: 100%;
    height: 24px;
    display: flex;
    position: absolute;
    padding: 0;
    background-color: transparent;
    border: transparent;

    &:hover, &:active, &:focus-visible, &:checked {
        background-color: transparent !important;
    }

    .actions-dropdown-button::after {
        content: none;
    }

    .dropdown-toggle::after {
        display: none;
    }

    &.btn-check:checked + .btn, &:not(.btn-check) + .btn:active, &.btn:first-child:active, &.btn.active, &.btn.show {
        background-color: transparent !important;
    }

    svg {
        margin-left: auto;
    }
`
const StyledDropdown = styled(Dropdown) <IReminderStyles>`
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    padding: 0 0 0 10px;
    outline: none;
    border-radius: 4px;
    color: #333951;
    background-color: #FFFFFF;
    border: 2px solid ${({ $selected }) => $selected ? styles.colors.primary600 : '#DAE0E8'};
    cursor: pointer;

    &:hover {
        border: 2px solid ${styles.colors.primary600};
    }
`
const StyledDropdownMenu = styled(Dropdown.Menu)`
    width: 100%;
    margin: 5px 0 0 -10px;
    background-color: white;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 12px 0px;
    border: transparent;
    cursor: pointer;
`
const StyledDropdownItem = styled(Dropdown.Item) <IReminderStyles>`
    display: flex;

    ${({ $isCalendar }) => !$isCalendar ? `
        height: 32px;
        align-items: center;

        &:focus {
            background-color: #f6f8fb !important;
        }

        &:hover {
            background-color: #f6f8fb;
            opacity: 100;
        }
    ` : `
        flex-direction: column;

         &:hover {
            background-color: transparent;
            opacity: 100;
        }
    `}
    ${({ $spaceBetween }) => $spaceBetween && 'justify-content: space-between;'}
    padding: 0 15px;
    gap: 16px;

    &:active, &:focus-visible, &:checked, &:focus {
        background-color: transparent !important;
    }

    
`
const SvgContainer = styled.div`
    display: inline-block;
    transition: transform 0.3s ease-in-out;
    margin-left: auto;
`
const SvgTransformContainer = styled(SvgContainer) <SvgContainerProps>`
    ${({ isOpen }) => (isOpen ? 'transform: rotate(180deg)' : 'transform: rotate(0deg)')};
`
/*  */
const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: #EBEFF4;
    margin: 10px 0;
`
const StyledCalendar = styled(Calendar)`
    height: 220px;
    width: 240px;
    font-family: NunitoSans;
    font-size: 14px;
    font-weight: bold;
    margin: auto;
    color: ${styles.colors.black600};

    & abbr { text-decoration: none; }    

    & button {
        border: none;
        background-color: transparent;

        &:disabled, &:disabled:hover { color: ${styles.colors.disabledState}; }
        &:not(:disabled) {
            color: ${styles.colors.black600};
            cursor: pointer;
        }
    }

    & .react-calendar__navigation {
        display: flex;
        height: 20px;
        margin-bottom: 10px;
    }

    & .react-calendar__navigation__label {
        font-weight: bold;
        color: ${styles.colors.black600};
    }

    & .react-calendar__navigation__arrow {
        line-height: 20px;
        font-size: 20px;
        font-weight: bold;
        color: #8c96ad;
    }

    & .react-calendar__viewContainer {
        height: 174px;
    }

    & .react-calendar__month-view__weekdays {
        height: 20px;
        margin-bottom: 4px;
    }

    & .react-calendar__month-view__weekdays__weekday {
        text-align: center;
    }

    & .react-calendar__month-view__days {
        height: 150px;
    }

    & .react-calendar__tile {
        height: 28px;
        width: 33px;
        margin-bottom: 2px;
        
        &:hover {color: ${styles.colors.primary600};}
    }

    & .react-calendar__tile--active {        
        background-color: ${styles.colors.primary100};
        color: ${styles.colors.black600} !important;

        &:hover { background-color: #F6FBFF; }
    }

    & .react-calendar__tile--rangeStart, & .react-calendar__tile--rangeEnd {
        border-radius: 6px;
        background-color: ${styles.colors.primary600};
        color: ${styles.colors.white} !important;
        
        &:hover {
            background-color: ${styles.colors.primary400};
            color: ${styles.colors.black600};
        }
    }
`
export default Reminder