import { ActionType } from "../../interfaces/store/templateStore"
import { useTemplateStore } from "./useTemplateStore"


export const useTemplateActions = () => {
    const { dispatch } = useTemplateStore()

    const setTemplates = (payload: any[]) => {
        return dispatch({
            type: ActionType.SET_TEMPLATES,
            payload
        })
    }

    const setTemplatesParameters = (payload: any) => {
        return dispatch({
            type: ActionType.SET_TEMPLATES_PARAMETERS,
            payload
        })
    }

    const setTemplatesFilters = (payload: any) => {
        return dispatch({
            type: ActionType.SET_TEMPLATES_FILTERS,
            payload
        })
    }

    const resetTemplatesStore = () => {
        return dispatch({
            type: ActionType.RESET_STORE
        })
    }

    return {
        setTemplates,
        setTemplatesParameters,
        setTemplatesFilters,
        resetTemplatesStore
    }
}


