import { v4 as uuidv4 } from 'uuid';
import DOMPurify from 'dompurify';

export const isValid = {
    email: (value: string) => String(value)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
    url: (value: string) => String(value).match(/^((https?|ftp|smtp):\/\/)?(www\.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/)
};

export const googleLibrary: any = {
    oauth2: '',
    callback: ''
};

export const getID = () => uuidv4();

export const analytic = (body: any) => {
    const local = window.location.host.includes('localhost');

    if (!local) {
        try {
            (window as any).dataLayer = (window as any).dataLayer || [];

            (window as any).dataLayer.push({
                ...body
            });

            console.log('Analytic', body);
        }
        catch (error) {
            console.log('Analytic error', error);
        }
    }
    else {
        console.log('Analytic', body);
    }
};

export const getPageUrl = () => `${window.location.host}${window.location.pathname}`;

export const getPageTitle = () => {
    if (window.location.pathname === '/') return 'Billing';

    if (window.location.pathname.includes('/processing')) return 'Processing';

    if (window.location.pathname.includes('/subscription')) return 'Subscription';

    if (window.location.pathname.includes('/paymentMethods')) return 'Payment methods';

    if (window.location.pathname.includes('/updateSubscription')) return 'Update subscription';

    if (window.location.pathname.includes('/info')) return 'Info';

    if (window.location.pathname.includes('/signup')) return 'Sign up';

    if (window.location.pathname.includes('/integrations')) return 'Integrations';

    if (window.location.pathname.includes('/redirect')) return 'Redirect';
};

export const calculateColumnWidth = (offsetWidth: number | undefined, columns: any, isSmall: boolean = false, isMedium: boolean = false) => {
    if (offsetWidth) {
        // Filter columns based on isSmall or isMedium flags
        const filteredColumns = columns.filter((column: any) => {
            if (isSmall && column.showSmall && column.show) return true;
            if (isMedium && (column.showSmall || column.showMedium) && column.show) return true;
            if (!isSmall && !isMedium && column.show) return true;
            return false;
        });

        const fixedWidths: number = filteredColumns.reduce((total: number, column: any) => {
            if (column.show) {
                if (typeof column.width === 'string' && column.width.endsWith('px')) {
                    return total + parseInt(column.width); // Parse width from string to number
                }
                return total + (column.width || 0); // Use as number directly if already a number
            }
            return total;
        }, 0);

        // Calculate the number of columns without a fixed width but with show: true
        const variableColumns = filteredColumns.filter((column: any) => !column.width && column.show);
        const variableColumnsCount: number = variableColumns.length;

        // Calculate the width for each variable column
        let variableWidth: number;
        if (variableColumnsCount > 0) {
            variableWidth = (offsetWidth - fixedWidths) / variableColumnsCount;
        } else {
            variableWidth = 0;
        }

        // Assign the calculated width to each variable column
        const adjustedColumns = columns.map((column: any) => {
            if (column.show) {
                // Only apply width calculations to filtered columns
                if (filteredColumns.includes(column)) {
                    if (!column.width) {
                        return { ...column, width: `${variableWidth}px` }; // Convert variableWidth to string with 'px' suffix
                    } else if (typeof column.width === 'string' && column.width.endsWith('px')) {
                        return { ...column, width: column.width }; // Keep specified width as string
                    } else {
                        return { ...column, width: column.width }; // Keep specified width as number
                    }
                }
            }
            return column; // Return the column as is if show is not true
        });

        return adjustedColumns;
    }
}


export const sanitize = (value: string | string[] | undefined): string => {
    if (!value) return '';
    const sanitizedValue = Array.isArray(value) ? value.join('') : value;
    return DOMPurify.sanitize(sanitizedValue)
}

export const capitalLetters = (props: any, fullName = false) => {
    let firstName = ''
    let lastName = ''

    if(props?.firstName !== undefined) firstName = fullName ? props.firstName : props.firstName.charAt(0).toUpperCase()
    if(props?.lastName !== undefined) lastName = fullName ? props.lastName : props.lastName.charAt(0).toUpperCase()

    return fullName ? firstName + ' ' + lastName : firstName + lastName
}

export const userVersionToString = (value: string) => {
    if (value === 'in-1st') return 'Linkedin 1st degree'; 

    if (value === 'in-2nd') return 'Linkedin 2nd degree'; 

    if (value === 'imported') return 'Imported'; 

    if (value === 'in-prospect') return 'imported via AI prospector'; 

    return ''; 
};

//Pipes

export const formatNumber = (value: number) => {
    return value.toLocaleString();
}

export const formatPrice = (value: number) => {
    return (value / 100).toLocaleString(undefined, {minimumFractionDigits: 2})
}

export const formatCurrency = (value: string) => {
    if(value === 'usd') return "$";
    return "€";
}
