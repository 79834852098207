import { useAxios } from "../useAxios"

export const useSaveFiltersRequest = () => {
    const { sendRequest } = useAxios()

    const route = '/saved-filters'

    //GET
    const getSavedFilters = () => {
        return sendRequest({
            slug: 'saveFilter',
            url: `${route}`,
            method: 'GET',
        })
    }


    //POST
    const saveFilters = (payload: any) => {
        return sendRequest({
            slug: 'saveFilter',
            url: `${route}`,
            method: 'POST',
            payload
        })
    }

    return {
        getSavedFilters,
        saveFilters
    }
}