import React, { FunctionComponent, useEffect, useState } from 'react'
import { styles } from './../../../../styles/themes/style';
import styled from 'styled-components'
import Column from '../../../../components/Column';
import Row from '../../../../components/Row';
import SvgFragment from '../../../../fragments/SvgFragment';
import Text from '../../../../components/Text';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import { useAuthStore } from '../../../../stores/auth/useAuthStore';
import { useAuthRequest } from '../../../../services/apis/auth/useAuthRequest';
import { useAuthHandlers } from '../../../../services/actions/auth/useAuthHandlers';
import { useAuthActions } from '../../../../stores/auth/useAuthActions';


interface IApiKeyProps { }

const ApiKey: FunctionComponent<IApiKeyProps> = () => {

    const { store: { workspace } } = useAuthStore()
    const { generateAPIKeyHandler, revokeAPIKeyHandler } = useAuthHandlers()
    const { setWorkspace } = useAuthActions()

    const [APIKey, setAPIKey] = useState('')

    useEffect(() => {
        if(workspace) setAPIKey(workspace?.apiKey)
    }, [workspace])

    const ApiKeyHandler = async (APIKey: string) => {
        if(APIKey) {
            await revokeAPIKeyHandler()
            setAPIKey('')
            setWorkspace({...workspace, apiKey: null})
        } else {
            const apiKey = await generateAPIKeyHandler()
            setAPIKey(apiKey)
            setWorkspace({...workspace, apiKey: apiKey})
        }
    }

    return (
        <Container>
            <Column alignItems gap='12px'>
                <SvgFragment type={'integrations'} />
                <Text $heading3>LeadDelta API Key</Text>
                <Text $paragraph1 $lighter>API key can be used for LeadDelta integration services.</Text>
                <InputWrapper gap='5px' justifyCenter>
                    {APIKey && <Input enableDoubleClickCopy prefilledValue={APIKey} />}
                    <ButtonWrapper>
                        <Button $type={APIKey ? 'red' : 'blue'} onClickHandler={(e: any) => ApiKeyHandler(APIKey)}>{APIKey ? 'Revoke API Key' : 'Generate API Key'}</Button>
                    </ButtonWrapper>
                </InputWrapper>
            </Column>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    padding: 50px 16px 16px 16px;
`
const InputWrapper = styled(Row)`
    width: 500px;
`
const ButtonWrapper = styled.div`
    width: 200px;
`
export default ApiKey
