import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components'; 
import Tooltip from '../../fragments/Tooltip';
import { styles } from '../../styles/themes/style';
import SvgFragment from '../../fragments/SvgFragment';

export interface IListItemProps { 
    children?: ReactNode;
    description?: string;
    onClickHandler?: (e: any) => void
    onDestructiveClickHandler?: (e: any) => void;
    className?: string
}

const ListItem: FunctionComponent<IListItemProps> = ({ children, description, onClickHandler, onDestructiveClickHandler, ...props }) => {

	return (
		<Container {...props} onClick={(e) => onClickHandler && onClickHandler(e)}>
			{children}
            {description && <Tooltip title={description} />}
            {onDestructiveClickHandler && <StyledDestructiveAction onClick={(e) => onDestructiveClickHandler(e)}>
                <SvgFragment type={'close'} />
            </StyledDestructiveAction>}
		</Container>
	);
}; 

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;  
    width: 100%;
    &:hover { 
        .LDtooltip { display: block; }
        svg path{
            display: flex;
        }
    }
`;

const StyledDestructiveAction = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    & svg path{
        display: none;
    }
    &:hover, &:focus{
        opacity: 0.7;
    }
`

export default ListItem;