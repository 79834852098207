import { FunctionComponent, useState } from "react";
import TakeShortBrakeSection from "./pause/TakeShortBrakeSection";

import WhyDontWePauseSection from "./pause/WhyDontWePauseSection";

const PauseFlow: FunctionComponent = () => {
    const [section, setSection] = useState(1)

    const nextSection = () => setSection(previous => previous + 1)

    return (
        <>
            {section === 1 && <WhyDontWePauseSection nextSection={nextSection} />}
            {section === 2 && <TakeShortBrakeSection />}
        </>
    )
}

export default PauseFlow