import React, { FormEvent, FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import DropdownMenu from '../../components/dropdown/DropdownMenu'
import SvgFragment from '../SvgFragment'
import DropdownItem from '../../components/dropdown/DropdownItem'
import { styles } from '../../styles/themes/style'
import { useAppHandlers } from '../../services/actions/app/useAppHandlers'
import { SidebarTypes } from '../../interfaces/store/appStore'
import { IConnection } from '../../interfaces/models/connection'
import Row from '../../components/Row'
import { useConnectionsHandlers } from '../../services/actions/connections/useConnectionsHandlers'
import FilterDropdownButton from './FilterDropdownButton'
import Text from '../../components/Text'
import BoxContainer from '../BoxContainer'
import Column from '../../components/Column'
import FormControl from '../../components/FormControl'
import InputNumber from '../../components/InputNumber'
import Button from '../../components/Button'

interface IMinMaxRangeDropdownProps {
    prefilledMinValue?: number,
    prefilledMaxValue?: number,
    className?: string
    title?: string
    description?: ReactNode,
    resetFilter: () => void,
    onChangeHandler: (values: { minValue?: number, maxValue?: number}) => void
}

const MinMaxRangeDropdown: FunctionComponent<IMinMaxRangeDropdownProps> = ({ className, title, description, resetFilter, onChangeHandler, prefilledMinValue, prefilledMaxValue }) => {

    const [ minValue, setMinValue ] = useState<number | undefined>(undefined)
    const [ maxValue, setMaxValue ] = useState<number | undefined>(undefined)

    useEffect(() => {
        setMinValue(prefilledMinValue ?? undefined)
        setMaxValue(prefilledMaxValue ?? undefined)
    }, [])

    const resetFilterHandler = () => {
        setMinValue(undefined)
        setMaxValue(undefined)
        resetFilter()
    }

    const submitFormHandler = useCallback(() => {
        onChangeHandler({ minValue, maxValue})
    }, [minValue, maxValue])

    const dropdownButton = <FilterDropdownButton 
            svgType='profileAdd' 
            placeholder={minValue || maxValue ? <>
                    <Text $black>{minValue ?? 'Min'}</Text>
                    - 
                    <Text $black>{maxValue ?? 'Max'}</Text>
            </> : <>{ title }</>}
            resetFilter={(minValue || maxValue) ? () => resetFilterHandler() : undefined}
        />

    return <StyledDropdownMenu className={className} title={dropdownButton} autoClose='outside' selectStyle $hideToggle={minValue !== undefined || maxValue !== undefined}>
        <DropdownItem $nonHoverable>
            <Column gap="10px">
                <Text $heading5>{title}</Text>
                {description && <Text>{description}</Text>}
                <Row spaceBetween>
                    <FormControl>
                        <Text $label>Min</Text>
                        <InputNumber prefilledValue={minValue} onChangeHandler={(number: number) => setMinValue(maxValue && number > maxValue ? maxValue : number)}/>
                    </FormControl>
                    <FormControl>
                        <Text $label>Max</Text>
                        <InputNumber prefilledValue={maxValue} onChangeHandler={(number: number) => setMaxValue(minValue && number < minValue ? minValue : number)}/>
                    </FormControl>
                </Row>
                <FooterButton>
                    <Button $mediumButton $type="blue" onClickHandler={(e: any) => submitFormHandler()}>{'Search'}</Button>
                </FooterButton>
            </Column>
        </DropdownItem>
    </StyledDropdownMenu>
}

const FooterButton = styled(Row)`
    justify-content: flex-end;
`

const StyledDropdownMenu = styled(DropdownMenu)`
    .actions-dropdown + div{
        width: 100%;
    }
`
export default MinMaxRangeDropdown
