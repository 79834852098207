import React, { FunctionComponent, useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom';
import ActionText from '../../components/ActionText'
import { styles } from '../../styles/themes/style';
import styled, { DefaultTheme, useTheme } from 'styled-components'
import Text from '../../components/Text';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../stores/auth/useAuthStore';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useBillingHandlers } from '../../services/actions/billing/useBillingHandlers';
import { useBillingStore } from '../../stores/billing/useBillingStore';
import { useAppStore } from '../../stores/application/useAppStore';
import { useAppHandlers } from '../../services/actions/app/useAppHandlers';
import { analytic, getPageTitle, getPageUrl } from '../../utils';
import { AppRoutes } from '../../interfaces/store/appStore';
import InitialLoading from '../../components/InitialLoading';

interface IBillingPageProps { }

const BillingPage: FunctionComponent<IBillingPageProps> = () => {
    const theme: DefaultTheme = useTheme()
    const [billingPlansLoading, setBillingPlansLoading] = useState<boolean>(true)
    const { getBillingPlansHandler, getPlansFeaturesHandler } = useBillingHandlers()
    const { store: { requestLoading } } = useAppStore()
    const { store: { subscription, workspaceOwner, workspaceAdmin } } = useAuthStore()
    const navigate = useNavigate()

    useEffect(() => {
        getBillingPlansHandler()
        getPlansFeaturesHandler()
    }, [subscription])

    useEffect(() => {
        if(workspaceOwner === false && workspaceAdmin === false) navigate(AppRoutes.HOME)
    }, [workspaceOwner, workspaceAdmin])

    useEffect(() => {
        if('billingPlans' in requestLoading) setBillingPlansLoading(requestLoading?.['billingPlans'])
    }, [requestLoading])

    

    if (billingPlansLoading) {
        return (
            <InitialLoading showInitialLoading={true} /> 
        )
    }

    return (
        <Container>
            {/* <Text $black $heading1>Billing</Text>
            <Text $black>Need any help? Contact <ActionText onClickHandler={() => window.open("mailto:support@leaddelta.com")}>support@leaddelta.com</ActionText> or <ActionText onClickHandler={() => (window as any).$crisp.push(['do', 'chat:open'])}>chat now</ActionText>.</Text> */}
            <Outlet />
        </Container>
    )
}
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    height: 100%;
    position: relative;
`

export default BillingPage