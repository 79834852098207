

import { ActionType, ITasksFiltersState } from "../../interfaces/store/taskStore"
import { useTasksStore } from "./useTasksStore"


export const useTasksActions = () => {
    const { dispatch } = useTasksStore()

    const setTasks = (payload: any[]) => {
        return dispatch({
            type: ActionType.SET_TASKS,
            payload
        })
    }

    const setTasksParameters = (payload: any) => {
        return dispatch({
            type: ActionType.SET_TASKS_PARAMETERS,
            payload
        })
    }

    const setTasksFilters = (payload: ITasksFiltersState) => {
        return dispatch({
            type: ActionType.SET_TASKS_FILTERS,
            payload
        })
    }

    const resetTasksStore = () => {
        return dispatch({
            type: ActionType.RESET_STORE
        })
    }

    const resetTasksPagination = () => {
        return dispatch({
            type: ActionType.RESET_TASKS_PAGINATION
        })
    }

    return {
        resetTasksStore,
        setTasks,
        setTasksParameters,
        setTasksFilters,
        resetTasksPagination
    }
}


