import { HubspotAccessToken, TriggerObject } from "../models/hubspot";

//Enums
export enum ActionType {
  SET_HUBSPOT_ACCESS_TOKEN = "SET_HUBSPOT_ACCESS_TOKEN",
  SET_HUBSPOT_TRIGGERS = "SET_HUBSPOT_TRIGGERS",
  RESET_STORE = "RESET_STORE",
}

//States
export type THubspotState = {
  hubspotAccessToken: HubspotAccessToken;
  triggers: TriggerObject;
};

interface ISetHubspotAccessTokenAction {
  type: ActionType.SET_HUBSPOT_ACCESS_TOKEN;
  payload: THubspotState["hubspotAccessToken"];
}

interface ISetHubspotTriggersAction {
  type: ActionType.SET_HUBSPOT_TRIGGERS;
  payload: THubspotState["triggers"];
}

interface IResetStoreAction {
  type: ActionType.RESET_STORE;
}

export type THubspotAction = ISetHubspotAccessTokenAction | ISetHubspotTriggersAction | IResetStoreAction;
