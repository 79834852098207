import React, { FormEvent, FunctionComponent, useCallback, useState } from 'react'
import styled from 'styled-components'
import Column from '../../../../../components/Column'
import PaymentMethods from '../../../../billing/components/PaymentMethods'
import Row from '../../../../../components/Row'
import Text from '../../../../../components/Text'
import Button from '../../../../../components/Button'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../../../interfaces/store/appStore'
import { useBillingHandlers } from '../../../../../services/actions/billing/useBillingHandlers'
import { useAuthStore } from '../../../../../stores/auth/useAuthStore'
import { IBillingPlanV2, IPrice } from '../../../../../interfaces/models/billingPlan'

interface IPaymentMethodsProps {
    selectedBillingPlan?: IBillingPlanV2,
    selectedPrice?: IPrice
 }

const PaymentMethodsTable: FunctionComponent<IPaymentMethodsProps> = ({ selectedBillingPlan, selectedPrice }) => {

    const navigate = useNavigate();
    const { getSetupIntentHandler } = useBillingHandlers();
    const { store: { subscription } } = useAuthStore() 

    const handlePaymentNavigation = useCallback(async () => {
        const result = await getSetupIntentHandler()
        if (result && selectedBillingPlan && selectedPrice) navigate(AppRoutes.BILLING_PAYMENT, { state: { setupIntent: result, selectedPrice, selectedBillingPlan, quantity: subscription.quantity, payNow: false} })
    },
    [subscription, selectedPrice, selectedBillingPlan])
    return <Column gap="16px">
        <StyledRow spaceBetween>
            <Text $heading4>{'Payment methods'}</Text>
            <Button $extraBigButton $type='blue' onClickHandler={() => handlePaymentNavigation()}>{'Change payment method'}</Button>
        </StyledRow>
        <PaymentMethods />
    </Column>
}

const StyledRow = styled(Row)`

    @media (max-width: 786px) {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
`
export default PaymentMethodsTable
