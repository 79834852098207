import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

interface IStyledSpanProps {
    $tooltipPosition?: string
}

interface ITooltipProps extends IStyledSpanProps {
    title: string
}

const Tooltip: FunctionComponent<ITooltipProps> = ({ title, $tooltipPosition }) => {

    return (
        <StyledSpan $tooltipPosition={$tooltipPosition} className='LDtooltip'>{title}</StyledSpan>
    )
}

const StyledSpan = styled.span<IStyledSpanProps>`
    display: none;
    position: absolute;
    z-index: 1008;
    left: ${({ $tooltipPosition }) => $tooltipPosition === 'left' ? '0' :
        $tooltipPosition === 'right' ? '100%' : '50%'};
    top: -27px;
    transform: translate(-50%, 0);
    padding: 0 5px;
    font-family: NunitoSans;
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
    color: white;
    background-color: ${({ theme: { septenaryColor } }) => septenaryColor};
    border-radius: 5px;
    white-space: nowrap;
`
export default Tooltip