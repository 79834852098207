import { useAxios } from "../useAxios"

export interface ICSVQuery {
    page: number,
    pageSize: number
}

export interface ICSVimport {
    downloadUrl: string,
    existingConnectionsCount: number,
    firstRow: Object,
    filesize: number,
    id: string,
    inProgress: boolean,
    invalidRowsCount: number,
    mapping: Object,
    newConnectionsCount: number,
    originalFilename: string,
    skippedExistingConnectionsCount: number,
    tags: any[],
    totalRows: number,
    untaggedConnectionsCount: number,
    user: string,
    workspace: string,
    createdAt: string
}

interface ICSVMeta {
    last_page: number,
    total: number
}

export const useCSVImportRequest = () => {
    const { sendRequest } = useAxios()

    const route = '/connections-csv'
    const baseURL = 'https://csvdev.leaddelta.com/api'
    //todo: prod https://csv.leaddelta.com point to http://35.184.157.251
    //const baseURL = 'http://34.69.199.108/api'
    // const baseURL = 'http://localhost:88/api'

    const getCSVImports = (params: ICSVQuery): Promise<{data: ICSVimport[], meta: ICSVMeta}> => {
        return sendRequest({
            slug: 'getCSVImports',
            baseURL: baseURL,
            url: `${route}`,
            method: 'GET',
            params: {
                page: params.page,
                per_page: params.pageSize
            },
            all: true
        })
    };

    const downloadCSVImport = ({ csvId } : { csvId: string}) => {
        return sendRequest({
            slug: 'downloadCSVImport',
            baseURL: baseURL,
            url: `${route}/${csvId}/download`,
            method: 'GET',
            all: true
        })
    }

    const importCSV = (formData: any) => {
        return sendRequest({
            slug: 'importCSV',
            baseURL: baseURL,
            url: `${route}`,
            method: 'POST',
            payload: formData,
            additionalHeaders: {
                'Content-type': 'multipart/form-data'
            }
        })
    }

    const importMapping = (params: any) => {
        const { id, body } = params
        return sendRequest({
            slug: 'importMappping',
            baseURL: baseURL,
            url: `${route}/${id}`,
            method: 'PATCH',
            payload: body
        })
    }

    return {
        getCSVImports,
        downloadCSVImport,
        importCSV,
        importMapping
    };
}