import React, { FormEvent, FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import TabsView, { ITabProperties } from '../../../../components/TabsView'
import CSVImport from './CSVImport'
import CustomFields from './CustomFields'
import PaymentMethodsTable from './billing/PaymentMethodsTable'
import InvoicesTable from './billing/InvoicesTable'
import { useAuthStore } from '../../../../stores/auth/useAuthStore'
import { useBillingHandlers } from '../../../../services/actions/billing/useBillingHandlers'
import { IBillingPlanV2, IPrice } from '../../../../interfaces/models/billingPlan'
import SubscriptionOverview from './billing/SubscriptionOverview'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../../interfaces/store/appStore'

interface IBillingProps { }

const billingTabs = [{
    slug: "billingOverview",
    title: "Overview",
    selected: true
},
{
    slug: "billingHistory",
    title: "Billing history",
    selected: false
},
{
    slug: "paymentMethods",
    title: "Payment methods",
    selected: false
}]



const Billing: FunctionComponent<IBillingProps> = () => {
    const navigate = useNavigate()
    const [ tabs, setTabs ] = useState<ITabProperties[]>(billingTabs)
    const [ selectedSlug, setSelectedSlug ] = useState<string>('billingOverview')
    const [ selectedPrice, setSelectedPrice ] = useState<IPrice | undefined>(undefined)
    const [ selectedBillingPlan, setSelectedBillingPlan] = useState<IBillingPlanV2 | undefined>(undefined)
    const { getBillingPlansSettingsHandler } = useBillingHandlers();
    const { store: { subscription, workspaceOwner, workspaceAdmin } } = useAuthStore() 

    useEffect(() => {
        if(workspaceOwner === false && workspaceAdmin === false) navigate(AppRoutes.SETTINGS_DATA_MANAGEMENT)
    }, [workspaceOwner, workspaceAdmin])
    useEffect(() => {
        handlePriceAndPlan()
    }, [subscription])

    const handlePriceAndPlan = useCallback(async () => {
        const billingPlans = await getBillingPlansSettingsHandler()
        let selectedPrice
        const selectedBillingPlan = billingPlans.find((plan: IBillingPlanV2) => {
            selectedPrice = plan.prices.find((price: IPrice) => price.priceId === subscription.priceId && price.productId === subscription.productId)
            if(selectedPrice) return true
        })
        if(selectedBillingPlan) setSelectedBillingPlan(selectedBillingPlan)
        if(selectedPrice) setSelectedPrice(selectedPrice)
    },
    [subscription])

    const renderComponent: {[key: string]: ReactNode} = {
        billingOverview: <SubscriptionOverview selectedPrice={selectedPrice} selectedBillingPlan={selectedBillingPlan}/>,
        billingHistory: <InvoicesTable />,
        paymentMethods: <PaymentMethodsTable selectedPrice={selectedPrice} selectedBillingPlan={selectedBillingPlan}/>
    }

    const switchTabs = useCallback((slug: string) =>{
        setSelectedSlug(slug)
        setTabs(tabs.map(tab => ({...tab, selected: tab.slug === slug})))
    }, [tabs])
    

    return <Container>
        <TabsView onSwitchTab={(slug)=>switchTabs(slug)} tabs={tabs}>
            {renderComponent[selectedSlug]}
        </TabsView>
    </Container>
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y:auto;
    height: 100%;
`

export default Billing
