import React, { useEffect } from 'react';
import styled from 'styled-components';

import ImageSignupSuccess from '../../assets/img/signup/signup-success.jpg';

import { ReactComponent as AngelList } from '../../assets/img/signup/AngelList.svg';
import { ReactComponent as Wealthsimple } from '../../assets/img/signup/Wealthsimple.svg';
import { ReactComponent as Google } from '../../assets/img/signup/GoogleText.svg';
import { ReactComponent as Instapage } from '../../assets/img/signup/Instapage.svg';
import { ReactComponent as Lightspeed } from '../../assets/img/signup/Lightspeed.svg';
import { ReactComponent as IconRating } from '../../assets/img/signup/5-stars.svg';

import { Outlet } from 'react-router-dom';
import { styles } from '../../styles/themes/style';
import Text from '../../components/Text';
import Column from '../../components/Column';
import Row from '../../components/Row';
import ActionText from '../../components/ActionText';
import { useExternalHandlers } from '../../services/actions/navigation/useExternalHandlers';

const reviews = [
    {
         quote: `My teams have been using LeadDelta for +1yr to get a foot in the door. With workspaces, we can utilize low-hanging fruits and build a far more powerful internal network of supporters than our competitors.`,
         by: `Shane Starr, Head of Revenue at Fletch`
     },
    {
        quote: `Just implemented LeadDelta for the entire team at Autoklose. It allows us to better manage our LinkedIn network. Great app.`,
        by: `Shawn Finder, Co-founder of Autoklose`
    },
];

const headerImages = [AngelList, Lightspeed,  Wealthsimple, Google, Instapage];

const AuthenticationPage = React.forwardRef((props: any, ref: any) => {
    const [step, setStep] = React.useState(1);
    const { openTermsAndConditions, openPrivacyPolicy } = useExternalHandlers()

    const parts = {
        form: (
            <Main>
                <Outlet />
            </Main>
        ),
        reviews: (
            <MainReviews>
                <FormHeader>
                    <Column gap='6px'>
                        <Title>Welcome to LeadDelta</Title>
                        <HeaderSubtitle>Reclaim the power of your network to sell, hire and grow.</HeaderSubtitle>
                    </Column>
                    <Text $bold $paragraph3>Trusted by thousands of professionals and high growth companies</Text>

                    <Row alignItems gap='10px'>
                        {headerImages.map((ImageComponent, index: number) => (
                           <ImageComponent key={index} />
                        ))}
                    </Row>
                </FormHeader>
            <Reviews>
                {reviews.map((item, index: number) => (
                    <Review
                        key={index}
                    >
                        <ReviewHeader>
                            <IconRating />
                        </ReviewHeader>

                        <ReviewQuote>
                            "{item.quote}"
                        </ReviewQuote>

                        <ReviewBy>
                            {item.by}
                        </ReviewBy>
                    </Review>
                ))}
            </Reviews>
            </MainReviews>
        ),
        successImage: (
            <ImageSuccess
                src={ImageSignupSuccess}

                alt=''
            />
        )
    }

    return (
        <Root spaceBetween>
            <RootColumns>
                <Column>
                    {step === 1 && parts.form}
                </Column>

                <Column>
                    {step === 1 && parts.reviews}

                    {step === 2 && parts.successImage}
                </Column>
            </RootColumns>
            <FooterColumn alignItems gap="5px">
                <Text>{'LeadDelta Inc. All rights Reserved.'}</Text>
                <Row gap="3px"><ActionText onClickHandler={()=>openTermsAndConditions()}>Terms of Service</ActionText><Text>and</Text><ActionText onClickHandler={()=>openPrivacyPolicy()}>Privacy Policy.</ActionText></Row>
            </FooterColumn>
        </Root>
        
    );
});

const Root = styled(Column)`
    min-height: 100%;
    width: 100%;
    background: white; //do this better
`
const FooterColumn = styled(Column)`
    padding: 28px;
`
const RootColumns = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    & > * {
        flex: 1 1 50%; 
        min-height: 100%;
    } 

    & > div:nth-child(1) {
        padding: 100px 0;
        background: white;
    }

    & > div:nth-child(2) {
        max-height: calc(100% - 80px);
        overflow-y: auto;
        padding: 100px 140px 100px 60px; 
        background: white;  
    }
    
    @media only screen and (min-width: 1024px) {
        flex-direction: row;
    }
`;

const Main = styled(Column)`
    width: 100%; 
    max-width: 400px; 
    margin: 0 auto;  
    gap: 32px;
`;

const MainReviews = styled(Column)`
    width: 100%; 
    margin: 0 auto;  
    gap: 32px;
`;

const FormHeader = styled(Column)`
    gap: 16px; 
`;

const Title = styled.h1`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px; 
    color: ${(props: any) => props.theme.primaryColor}; 
    margin: 0px; 
`;

const HeaderSubtitle = styled(Text)`
    color: ${styles.colors.black400}; 
    margin-bottom: 20px; 
`;

const Reviews = styled(Column)`
    gap: 32px;
`;

const Review = styled(Column)`
    gap: 12px; 
`;

const ReviewHeader = styled(Row)` 
    align-items: center; 
    gap: 20px; 

    & > img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
`;

const ReviewQuote = styled.h3`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: ${(props: any) => props.theme.primaryColor};
    margin: 0px;
`;

const ReviewBy = styled.p`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${(props: any) => props.theme.nonaryColor};
    margin: 0px;
`;

const ImageSuccess = styled.img`
    width: 100%;
    height: auto;
`;


export default AuthenticationPage; 
