export const industryOptions = [
    {
      "backgroundColor": "white",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#373945',
      "darkColor": "#e8e8e8",
      "color": "#333951",
      "indicatorColor": "#8c96ad",
      "borderColor": "#dae0e8",
      "placeholderColor": "#8c96ad",
      "value": null,
      "label": "All Industries"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Accounting",
      "label": "Accounting"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Airlines/Aviation",
      "label": "Airlines/Aviation"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Alternative Dispute Resolution",
      "label": "Alternative Dispute Resolution"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Alternative Medicine",
      "label": "Alternative Medicine"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Animation",
      "label": "Animation"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Apparel & Fashion",
      "label": "Apparel & Fashion"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Architecture & Planning",
      "label": "Architecture & Planning"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Arts & Crafts",
      "label": "Arts & Crafts"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Automotive",
      "label": "Automotive"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Aviation & Aerospace",
      "label": "Aviation & Aerospace"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Banking",
      "label": "Banking"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Biotechnology",
      "label": "Biotechnology"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Broadcast Media",
      "label": "Broadcast Media"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Building Materials",
      "label": "Building Materials"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Business Supplies & Equipment",
      "label": "Business Supplies & Equipment"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Capital Markets",
      "label": "Capital Markets"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Chemicals",
      "label": "Chemicals"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Civic & Social Organization",
      "label": "Civic & Social Organization"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Civil Engineering",
      "label": "Civil Engineering"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Commercial Real Estate",
      "label": "Commercial Real Estate"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Computer Games",
      "label": "Computer Games"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Computer Hardware",
      "label": "Computer Hardware"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Computer Networking",
      "label": "Computer Networking"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Computer & Network Security",
      "label": "Computer & Network Security"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Computer Software",
      "label": "Computer Software"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Construction",
      "label": "Construction"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Consumer Electronics",
      "label": "Consumer Electronics"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Consumer Goods",
      "label": "Consumer Goods"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Consumer Services",
      "label": "Consumer Services"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Cosmetics",
      "label": "Cosmetics"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Dairy",
      "label": "Dairy"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Defense & Space",
      "label": "Defense & Space"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Design",
      "label": "Design"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Education Management",
      "label": "Education Management"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "E-Learning",
      "label": "E-Learning"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Electrical/Electronic Manufacturing",
      "label": "Electrical/Electronic Manufacturing"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Entertainment",
      "label": "Entertainment"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Environmental Services",
      "label": "Environmental Services"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Events Services",
      "label": "Events Services"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Executive Office",
      "label": "Executive Office"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Facilities Services",
      "label": "Facilities Services"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Farming",
      "label": "Farming"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Financial Services",
      "label": "Financial Services"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Fine Art",
      "label": "Fine Art"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Fishery",
      "label": "Fishery"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Food & Beverages",
      "label": "Food & Beverages"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Food Production",
      "label": "Food Production"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Fund-Raising",
      "label": "Fund-Raising"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Furniture",
      "label": "Furniture"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Gambling & Casinos",
      "label": "Gambling & Casinos"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Glass, Ceramics & Concrete",
      "label": "Glass, Ceramics & Concrete"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Government Administration",
      "label": "Government Administration"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Government Relations",
      "label": "Government Relations"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Graphic Design",
      "label": "Graphic Design"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Health, Wellness & Fitness",
      "label": "Health, Wellness & Fitness"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Higher Education",
      "label": "Higher Education"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Hospital & Health Care",
      "label": "Hospital & Health Care"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Hospitality",
      "label": "Hospitality"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Human Resources",
      "label": "Human Resources"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Import & Export",
      "label": "Import & Export"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Individual & Family Services",
      "label": "Individual & Family Services"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Industrial Automation",
      "label": "Industrial Automation"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Information Services",
      "label": "Information Services"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Information Technology & Services",
      "label": "Information Technology & Services"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Insurance",
      "label": "Insurance"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "International Affairs",
      "label": "International Affairs"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "International Trade & Development",
      "label": "International Trade & Development"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Internet",
      "label": "Internet"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Investment Banking",
      "label": "Investment Banking"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Investment Management",
      "label": "Investment Management"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Judiciary",
      "label": "Judiciary"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Law Enforcement",
      "label": "Law Enforcement"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Law Practice",
      "label": "Law Practice"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Legal Services",
      "label": "Legal Services"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Legislative Office",
      "label": "Legislative Office"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Leisure, Travel & Tourism",
      "label": "Leisure, Travel & Tourism"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Libraries",
      "label": "Libraries"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Logistics & Supply Chain",
      "label": "Logistics & Supply Chain"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Luxury Goods & Jewelry",
      "label": "Luxury Goods & Jewelry"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Machinery",
      "label": "Machinery"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Management Consulting",
      "label": "Management Consulting"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Maritime",
      "label": "Maritime"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Marketing & Advertising",
      "label": "Marketing & Advertising"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Market Research",
      "label": "Market Research"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Mechanical or Industrial Engineering",
      "label": "Mechanical or Industrial Engineering"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Media Production",
      "label": "Media Production"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Medical Devices",
      "label": "Medical Devices"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Medical Practice",
      "label": "Medical Practice"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Mental Health Care",
      "label": "Mental Health Care"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Military",
      "label": "Military"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Mining & Metals",
      "label": "Mining & Metals"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Motion Pictures & Film",
      "label": "Motion Pictures & Film"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Museums & Institutions",
      "label": "Museums & Institutions"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Music",
      "label": "Music"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Nanotechnology",
      "label": "Nanotechnology"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Newspapers",
      "label": "Newspapers"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Nonprofit Organization Management",
      "label": "Nonprofit Organization Management"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Oil & Energy",
      "label": "Oil & Energy"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Online Media",
      "label": "Online Media"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Outsourcing/Offshoring",
      "label": "Outsourcing/Offshoring"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Package/Freight Delivery",
      "label": "Package/Freight Delivery"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Packaging & Containers",
      "label": "Packaging & Containers"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Paper & Forest Products",
      "label": "Paper & Forest Products"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Performing Arts",
      "label": "Performing Arts"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Pharmaceuticals",
      "label": "Pharmaceuticals"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Philanthropy",
      "label": "Philanthropy"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Photography",
      "label": "Photography"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Plastics",
      "label": "Plastics"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Political Organization",
      "label": "Political Organization"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Primary/Secondary Education",
      "label": "Primary/Secondary Education"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Printing",
      "label": "Printing"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Professional Training",
      "label": "Professional Training & Coaching"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Program Development",
      "label": "Program Development"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Public Policy",
      "label": "Public Policy"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Public Relations & Communications",
      "label": "Public Relations & Communications"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Public Safety",
      "label": "Public Safety"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Publishing",
      "label": "Publishing"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Railroad Manufacture",
      "label": "Railroad Manufacture"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Ranching",
      "label": "Ranching"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Real Estate",
      "label": "Real Estate"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Recreational Facilities & Services",
      "label": "Recreational Facilities & Services"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Religious Institutions",
      "label": "Religious Institutions"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Renewables & Environment",
      "label": "Renewables & Environment"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Research",
      "label": "Research"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Restaurants",
      "label": "Restaurants"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Retail",
      "label": "Retail"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Security & Investigations",
      "label": "Security & Investigations"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Semiconductors",
      "label": "Semiconductors"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Shipbuilding",
      "label": "Shipbuilding"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Sporting Goods",
      "label": "Sporting Goods"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Sports",
      "label": "Sports"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Staffing & Recruiting",
      "label": "Staffing & Recruiting"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Supermarkets",
      "label": "Supermarkets"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Telecommunications",
      "label": "Telecommunications"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Textiles",
      "label": "Textiles"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Think Tanks",
      "label": "Think Tanks"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Tobacco",
      "label": "Tobacco"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Translation & Localization",
      "label": "Translation & Localization"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Transportation/Trucking/Railroad",
      "label": "Transportation/Trucking/Railroad"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Utilities",
      "label": "Utilities"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Venture Capital",
      "label": "Venture Capital & Private Equity"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Veterinary",
      "label": "Veterinary"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Warehousing",
      "label": "Warehousing"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Wholesale",
      "label": "Wholesale"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Wine & Spirits",
      "label": "Wine & Spirits"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Wireless",
      "label": "Wireless"
    },
    {
      "backgroundColor": "#eef3ff",
      'darkBackgroundColor': '#373945',
      'darkDropdownColor': '#424551',
      'darkBorderColor': '#5964ff',
      "color": "#5964ff",
      "indicatorColor": "#5964ff",
      "borderColor": "#5964ff",
      "placeholderColor": "#7a89fc",
      "value": "Writing & Editing",
      "label": "Writing & Editing"
    }
  ]