import { FunctionComponent } from 'react'
import styled from 'styled-components'
import Text from '../components/Text'
import Column from '../components/Column'
import Checkbox from '../components/Checkbox'
import Row from '../components/Row'
import { styles } from '../styles/themes/style'

interface IPrivateCheckBoxControlProps {
    type: string
    value: boolean
    disabled?: boolean
    onChangeHandler: (e: boolean) => void
 }

 interface IStyledRowProps {
    $disabled?: boolean
}

const PrivateCheckBoxControl: FunctionComponent<IPrivateCheckBoxControlProps> = ({type, value, disabled, onChangeHandler}) => {
    return <StyledRow gap="12px" alignItems $disabled={disabled}>
                <Checkbox
                        checked={value}
                        disabled={disabled}
                        onClickHandler={() => onChangeHandler(!value)}
                        checkboxId={'custom-type-checkbox'}
                        />
                <Column gap='3px'>
                    <Text $bold>{`Keep this ${type} private `}</Text>
                    <Text $lighter>{`Unticking the box will make the ${type} public`}</Text>
                </Column>
        </StyledRow>
}

const StyledRow = styled(Row)<IStyledRowProps>`
    width: 100;
    ${({ $disabled }) => $disabled && `
        cursor: default;
        span, &:hover span, &:focus span{
            cursor: default;
            color: ${styles.colors.disabledState};
        }
    `}
`

export default PrivateCheckBoxControl
