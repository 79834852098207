import React, { FormEvent, FunctionComponent, useCallback, useState } from 'react'
import styled from 'styled-components'
import DropdownMenu from '../../components/dropdown/DropdownMenu'
import SvgFragment from '../SvgFragment'
import DropdownItem from '../../components/dropdown/DropdownItem'
import { styles } from '../../styles/themes/style'
import { useRemindersHandlers } from '../../services/actions/reminders/useRemindersHandlers'
import { useTasksHandlers } from '../../services/actions/tasks/useTasksHandlers'
import { useAppHandlers } from '../../services/actions/app/useAppHandlers'
import { SidebarTypes } from '../../interfaces/store/appStore'
import Column from '../../components/Column'
import Row from '../../components/Row'

interface ITasksBulkActionsDropdownStyles {
    color?: string
}

interface ITasksBulkActionsDropdownProps extends ITasksBulkActionsDropdownStyles {
    className?: string
    disabled?: boolean
}

const TasksBulkActionsDropdown: FunctionComponent<ITasksBulkActionsDropdownProps> = ({ className, disabled }) => {
    const { openSidebar } = useAppHandlers()
    const { bulkRemoveRemindersHandler } = useRemindersHandlers()
    const { bulkRemoveTasksHandler, bulkUpdateTasksHandler } = useTasksHandlers()

    const editDeleteActions = [
        {
            icon: 'clock',
            title: `Set reminder`,
            slug: 'setReminder',
            color: 'blue',
            enabled: true,
            visible: true,
            destructive: false,
            onClickHandler: () => openSidebar(SidebarTypes.TASK_EDIT_CREATE, { formType: 'reminder' })
        },
        {
            icon: 'calendar',
            title: `Set Due Date`,
            slug: 'dueDate',
            color: 'blue',
            enabled: true,
            visible: true,
            destructive: false,
            onClickHandler: () => openSidebar(SidebarTypes.TASK_EDIT_CREATE, { formType: 'due date' })
        },
        {
            icon: 'arrowUp',
            title: `Set Priority Level`,
            slug: 'priorityLevel',
            color: 'blue',
            enabled: true,
            visible: true,
            destructive: false,
            divider: true,
            onClickHandler: () => openSidebar(SidebarTypes.TASK_EDIT_CREATE, { formType: 'priority level' })
        },
        {
            icon: 'clock',
            title: `Remove Reminders`,
            slug: 'removeReminders',
            color: 'red',
            enabled: true,
            visible: true,
            destructive: false,
            onClickHandler: () => bulkRemoveRemindersHandler()
        },
        {
            icon: 'trashCan',
            title: `Delete Tasks`,
            slug: 'deleteTasks',
            color: 'red',
            enabled: true,
            visible: true,
            destructive: false,
            onClickHandler: () => bulkRemoveTasksHandler()
        }
    ]
    return (
        <Column justifyCenter>
            <DropdownMenu disabled={disabled} tooltipMessage={'More actions'} className={className} SVGtype='threeDots' $hideToggle $transparent>
                <ShowMobile>
                    <DropdownItem onClickHandler={() => bulkUpdateTasksHandler({ isBulkComplete: true })}>
                        <Row alignItems gap="5px"><SvgFragment type="checkmark"/>{'Mark Complete'}</Row>
                    </DropdownItem>
                </ShowMobile>
                {editDeleteActions.map((editDeleteAction, index) => <>
                    <DropdownItem key={index} $danger={editDeleteAction.destructive} onClickHandler={(e: any) => editDeleteAction.onClickHandler && editDeleteAction.onClickHandler()}>
                        <Row alignItems gap="5px"><SvgFragment type={editDeleteAction.icon} />{editDeleteAction.title}</Row>
                    </DropdownItem>
                    {editDeleteAction.divider && <Divider />}
                </>)}
            </DropdownMenu>
        </Column>
    )
}

const Divider = styled.div`
    width: 100%;
    height: 2px;
    margin: 10px 0px;
    background-color: rgb(218, 224, 232);
`
const ShowMobile = styled.div`
    display: none;

    @media (max-width: 575px) {
        display: block;
    }
`
export default TasksBulkActionsDropdown
