
import { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

interface IFlexStyles {
    flexColumn?: boolean,
    spaceBetween?: boolean,
    justifyCenter?: boolean,
    alignItems?: boolean
}

interface IColumnProps extends IFlexStyles {
    children?: ReactNode,
    className?: string,
    gap?: string
    onClick?: (e: any) => void
 }

const Column: FunctionComponent<IColumnProps> = ({children, ...props}) => {
    return <Container {...props}>{children}</Container>
}

const Container = styled.div<IColumnProps>`
    display: flex;
    flex-direction: column;
    ${({ gap }) => gap && `gap: ${gap};`}
    ${({ spaceBetween }) => spaceBetween && 'justify-content: space-between;'}
    ${({ justifyCenter }) => justifyCenter && 'justify-content: center;'}
    ${({ alignItems }) => alignItems && 'align-items: center;'}
`;

export default Column