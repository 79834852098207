import { FunctionComponent } from "react";
import Column from "./Column";
import styled from "styled-components";
import Text from "./Text";
import ImageNoNotifications from '../assets/img/no-notifications.png';

interface IDropdownEmptyStateProps {
    text: string
    image?: any
}

const DropdownEmptyState: FunctionComponent<IDropdownEmptyStateProps> = ({ text, image }) => {

    return (
        <StyledColumn gap={image ? '15px' : '0px'} alignItems>
            <StyledImage src={image} alt='' />
            <Text $lighter>{text}</Text>
        </StyledColumn>
    )
}

const StyledColumn = styled(Column)`
    padding: 20px 50px;
    white-space: nowrap;
`
const StyledImage = styled.img`
    width: 50px;
`

export default DropdownEmptyState