
import { ActionType, ISidebar, IRequestLoading, IModal, IToast, THeaderState } from "../../interfaces/store/appStore"
import { useAppStore } from "./useAppStore"

export const useAppActions = () => {
    const { dispatch } = useAppStore()
    const setModal = (payload?: IModal) => {
        return dispatch({
            type: ActionType.SET_MODAL,
            payload
        })
    }

    const setToast = (payload?: IToast) => {
        return dispatch({
            type: ActionType.SET_TOAST,
            payload
        })
    }

    const setRequestLoading = (payload: IRequestLoading) => {
        return dispatch({
            type: ActionType.SET_REQUEST_LOADING,
            payload
        })
    }

    const setSpinner = (payload: boolean) => {
        return dispatch({
            type: ActionType.SET_SPINNER,
            payload
        })
    }

    const setExtensionInstalled = (payload: boolean) => {
        return dispatch({
            type: ActionType.SET_EXTENSION_INSTALLED,
            payload
        })
    }

    const setExtensionId = (payload: string | undefined) => {
        return dispatch({
            type: ActionType.SET_EXTENSION_ID,
            payload
        })
    }

    const setExtensionVersion = (payload: string | undefined) => {
        return dispatch({
            type: ActionType.SET_EXTENSION_VERSION,
            payload
        })
    }

    const setOnboardingStep = (payload: number) => {
        return dispatch({
            type: ActionType.SET_ONBOARDING_STEP,
            payload
        })
    }

    const setHeaderState = (payload?: THeaderState) => {
        return dispatch({
            type: ActionType.SET_HEADER_STATE,
            payload
        })
    }

    const setSidebar = (payload: ISidebar[]) => {
        return dispatch({
            type: ActionType.SET_SIDEBAR,
            payload
        })
    }

    const setDarkMode = (payload: boolean) => {
        return dispatch({
            type: ActionType.SET_DARK_MODE,
            payload
        })
    }

    const setLeadDeltaTabIsActive = (payload: boolean) => {
        return dispatch({
            type: ActionType.SET_LEADDELTA_TAB_IS_ACTIVE,
            payload
        })
    }

    const resetAppStore = () => {
        return dispatch({
            type: ActionType.RESET_STORE
        })
    }

    return {
        setModal,
        setToast,
        setRequestLoading,
        setSpinner,
        setExtensionInstalled,
        setExtensionId,
        setExtensionVersion,
        setOnboardingStep,
        setHeaderState,
        setSidebar,
        setDarkMode,
        resetAppStore,
        setLeadDeltaTabIsActive
    }
}


