import { TTagAction, TTagsState, ActionType, ITagsFilterState } from "../../interfaces/store/tagStore";
import { makeStore } from "../makeStore";

const initialTagsFilter: ITagsFilterState = {
    searchByName: '',
    usersMutualCondition: 'And',
    selectedUsersMutual: [],
    tagAccessType: { value: 'all', label: 'All' },
    sort: '',
}

const initialTagsParameters = {
    page: 1,
    pageSize: 25,
    total: 0
} 

const initialState: TTagsState = {
    tags: [],
    tagsFilter: initialTagsFilter,
    tagsParameters: initialTagsParameters,
}


const reducer = (state: TTagsState, action: TTagAction): TTagsState => {
    switch (action.type) {
        case ActionType.SET_TAGS: return { ...state, tags: action.payload }
        case ActionType.SET_TAGS_FILTER: return { ...state, tagsFilter: {...state.tagsFilter, ...action.payload} }
        case ActionType.SET_TAGS_PARAMETERS: return {  ...state, tagsParameters: {...state.tagsParameters, ...action.payload} }
        case ActionType.RESET_TAGS_PAGINATION: return {  ...state, tagsParameters: {...initialTagsParameters }, tagsFilter: {...initialTagsFilter} }
        case ActionType.RESET_STORE: return { ...initialState }
        default: return state
    }
}

const { StoreProvider: TagsStoreProvider, useStoreContext: useTagsStore } = makeStore(reducer, initialState)

export { TagsStoreProvider, useTagsStore }