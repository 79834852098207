import { FunctionComponent } from "react";
import { capitalLetters } from "../../../../../utils";
import styled from "styled-components";
import Row from "../../../../../components/Row";
import Text from "../../../../../components/Text";
import ProfilePhoto from "../../../../../components/ProfilePhoto";
import Column from "../../../../../components/Column";
import { styles } from '../../../../../styles/themes/style'
import { useConnectionsHandlers } from "../../../../../services/actions/connections/useConnectionsHandlers";

interface ILinkedConnectionColumnProps {
    id: any
    profile: any
    getConnectionByProfileIdHandler?: (e: any) => void
}

interface IStyledText {
    $red?: boolean
    $associations?: boolean
}

const LinkedConnectionColumn: FunctionComponent<ILinkedConnectionColumnProps> = ({id, profile}) => {

    const { getConnectionByProfileIdHandler } = useConnectionsHandlers()

    return (
        <StyledColumn gap='10px' alignItems>
            <ProfilePhoto tooltipMessage={capitalLetters('')} capitalLetters={capitalLetters(profile)} />
            <Column>
                <div onClick={(e: any) => {e.stopPropagation(); getConnectionByProfileIdHandler(id)}}><StyledText $associations>{capitalLetters(profile, true)}</StyledText></div>
            </Column>
        </StyledColumn> 
    )
}

const StyledColumn = styled(Row)`
    cursor: pointer;

    &:hover .reminder {
        visibility: visible;
    }
`
const StyledText = styled(Text) <IStyledText>`
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis; 

    ${({ $red }) => $red && `color: ${styles.colors.red600}`}
    ${({ $associations }) => $associations && `
        color: ${styles.colors.primary600};
        font-weight: 700
    `}
`

export default LinkedConnectionColumn