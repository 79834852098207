import { FunctionComponent, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import Spinner from '../../components/Spinner'
import { AppRoutes } from '../../interfaces/store/appStore';

const Redirect: FunctionComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const invite = searchParams.get('invite')
        const appId = searchParams.get('app_id')
        const extensionRedirectTo = searchParams.get('extensionRedirectTo')

        //todo: redirect from the email invite and tasks should be resolved better in approuter
        if (extensionRedirectTo) window.open(decodeURIComponent(extensionRedirectTo), '_self');
        if (invite) {
            navigate(`${AppRoutes.SIGNUP}?invite=${invite}`);
        }
        else {
            if (appId) {
                window.open(`chrome-extension://${appId}/src/options/index.html`, '_self'); //check if this is used
            }
            else {
                navigate('/billing');
            }
        }
    }, []);

    return (
        <Spinner showHideSpinner={true} />
    )
};

const Container = styled.div`
    display: flex;
`

export default Redirect