import React, { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import NavigationRail from './components/NavigationRail'
import { useAuthHandlers } from '../../services/actions/auth/useAuthHandlers'
import { styles } from '../../styles/themes/style'

interface ICanvasPageProps { }

interface IRailWrappeStyle {
    showMobile?: boolean
}

const CanvasPage: FunctionComponent<ICanvasPageProps> = () => {
    const { getUserWorkspacesHandler } = useAuthHandlers()

    useEffect(()=>{
        getUserWorkspacesHandler();
    }, [])

    return <Container>
        <NavigationRail />
        <Outlet />
    </Container>
}

const Container = styled.div`
    width: 100%;
    padding: 0px 0px 0px ${styles.lengths.railWidth};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    height: 100%;
    position: relative;

    @media (max-width: 575px) {
        padding: 0px 0px 0px 0px;
        gap: 0px;
    }
`
export default CanvasPage
