

import { IConnection, ITag } from "../../interfaces/models/connection"
import { ActionType, TConnectionsFilterState } from "../../interfaces/store/connectionStore"
import { IConnectionsQueryParams } from "../../services/apis/connections/useConnectionsRequest"
import { useConnectionsStore } from "./useConnectionsStore"


export const useConnectionsActions = () => {
    const { dispatch } = useConnectionsStore()

    const setConnectionsFilter = (payload: TConnectionsFilterState) => {
        return dispatch({
            type: ActionType.SET_CONNECTIONS_FILTER,
            payload
        })
    }

    const setConnections = (payload: IConnection[]) => {
        return dispatch({
            type: ActionType.SET_CONNECTIONS,
            payload
        })
    }

    const resetConnectionsStore = () => {
        return dispatch({
            type: ActionType.RESET_STORE
        })
    }

    const setMultipleConditionsFilters = (payload: IConnectionsQueryParams) => {
        return dispatch({
            type: ActionType.SET_MULTIPLE_CONDITIONS_FILTERS,
            payload
        })
    }

    const setConnectionFilterOptions = (payload: any) => {
        return dispatch({
            type: ActionType.SET_CONNECTIONS_FILTER_OPTIONS,
            payload
        })
    }

    const setConnectionsParameters = (payload: any) => {
        return dispatch({
            type: ActionType.SET_CONNECTIONS_PARAMETERS,
            payload
        })
    }

    const resetConnectionsPagination = () => {
        return dispatch({
            type: ActionType.RESET_CONNECTIONS_PAGINATION
        })
    }

    return {
        setConnectionsFilter,
        resetConnectionsStore,
        setConnections,
        setMultipleConditionsFilters,
        setConnectionFilterOptions,
        setConnectionsParameters,
        resetConnectionsPagination
    }
}


