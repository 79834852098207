
import { ICreateTag } from "../../../interfaces/models/tag";
import { useAuthStore } from "../../../stores/auth/useAuthStore";
import { useAxios } from "../useAxios"

export interface ITagsQueryParams {
  page: number;
  pageSize: number;
  searchByName: string;
  usersMutual?: string;
  usersMutualCondition?: string,
  tagAccessType?: string
  sort?: string
}

export const useTagsRequest = () => {
    const { sendRequest } = useAxios()
    const { store: { workspace} } = useAuthStore()

    const route = '/tags'

    //GET
    const getTags = () => {
        return sendRequest({
            slug: 'getTags',
            url: `${route}`,
            method: 'GET'
        })
    };

    //PAGINATE TAGS //todo: remove get tags
    const getTagsPaginate = (params: ITagsQueryParams) => {
        return sendRequest({
            slug: 'getTagsPaginate',
            url: `${route}/paginate`,
            method: 'GET',
            params
        })
    };

    //POST
    const createTag = async (body: ICreateTag) => {
        return sendRequest({
            slug: 'createTag',
            url: `${route}`,
            method: 'POST',
            payload: body
        });
    };

    //PUT
    const updateTag = async (id: string, payload: ICreateTag) => {
        return sendRequest({
            slug: 'updateTag',//can add id for the current tag listen to loading
            url: `${route}/${id}`,
            method: 'PUT',
            payload
        });
    }

    const updateTagsAccess = async (payload: any) => {
        return sendRequest({
            slug: 'updateTagsAccess',
            url: `workspaces/${workspace._id}/tags`,
            method: 'PUT',
            payload,
            all: true
        });
    }

    //DELETE
    const deleteTag = async (id: string) => {
        return sendRequest({
            slug: 'deleteTag',
            url: `${route}/${id}`,
            method: 'DELETE',
            all: true
        });
    }

    const deleteTags = async (ids: string[]) => {
        return sendRequest({
            slug: 'deleteTag',
            url: `${route}/bulk/delete`,
            method: 'DELETE',
            payload: {tags: ids},
            all: true
        });
    }

    return {
        getTags,
        createTag,
        updateTag,
        updateTagsAccess,
        deleteTag,
        deleteTags,
        getTagsPaginate
    };
}