import { FunctionComponent, ReactNode } from "react"
import styled from "styled-components"
import SvgFragment from "../fragments/SvgFragment";
import { styles } from "../styles/themes/style";
import Row from "./Row";
import LoadingSpinner from "./LoadingSpinner";
import Text from "./Text";
import TooltipWrapper from "./TooltipWrapper";

interface IStyledButtonProps {
    transparent?: boolean,
    disabled?: boolean,
    margin?: string,//todo: remove margin
    $type?: string,
    $SVGtype?: string,
    $bigButton?: boolean,
    $extraBigButton?: boolean,
    $mediumButton?: boolean,
    $mediumBigButton?: boolean,
    $smallButton?: boolean,
    $rotateSVG?: boolean,
    $marginRightSVG?: boolean
    $justifyLeft?: boolean
    $color?: string
}

interface IButtonProps extends IStyledButtonProps {
    tooltipMessage?: string,
    children?: ReactNode,
    type?: "button" | "submit" | "reset",
    tooltipPosition?: string,
    className?: string,
    isLoading?: boolean,
    onClickHandler?: (e: any) => void
}

const Button: FunctionComponent<IButtonProps> = ({ $SVGtype, children, tooltipMessage, tooltipPosition, onClickHandler, disabled, isLoading, transparent = false, ...props }) => {

    return (
       <TooltipWrapper tooltipText={tooltipMessage ? tooltipMessage : children && typeof children === "string" ? children : ''}>
            <StyledButton transparent={transparent} disabled={disabled || isLoading} onClick={(e) => !disabled && onClickHandler && onClickHandler(e)} {...props}>
                {isLoading ? <Row gap='5px' alignItems><LoadingSpinner /><Text>{'In progress'}</Text></Row> : 
                <>
                    <SvgFragment type={$SVGtype} />
                    {children}
                </>}
            </StyledButton>
        </TooltipWrapper>

    )
}
const StyledButton = styled.button<IStyledButtonProps>`
    height: 34px;
    width: 100%;
    ${({ $extraBigButton }) => $extraBigButton && 'width: 250px;'}
    ${({ $bigButton }) => $bigButton && 'width: 150px;'}
    ${({ $mediumBigButton }) => $mediumBigButton && 'width: 110px;'}
    ${({ $mediumButton }) => $mediumButton && 'width: 85px;'}
    ${({ $smallButton }) => $smallButton && 'width: 32px;'}
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;
    gap: 5px;
    justify-content: center;
    border-radius: 4px;
    line-height: 20px;
    font-family: NunitoSans;
    font-size: 14px;
    font-weight: bold;
    color: ${({ $color, theme: { primaryColor } }) => $color ? $color : primaryColor};
    padding: ${({ $bigButton, $SVGtype }) => $bigButton ? `6px 9px 6px ${$SVGtype ? '4px' : '9px'}` : '6px 4px'};
    cursor: pointer;
    transition: all .2s ease;

    &:focus { outline: none; }

    &:hover {
        color: ${({ $color, theme: { primary } }) => $color ? $color : (primary ? styles.colors.primary600 : styles.colors.darkTextcolor)};
        & .LDtooltip { display: block; }
        background-color: ${({ theme: { senaryColor } }) => senaryColor }};
        & .path { fill: ${({ theme: { primary } }) => primary ? styles.colors.primary600 : styles.colors.darkTextcolor}; }
    }

    ${({ margin }) => margin && `margin: ${margin} !important;`};

    ${({ $type }) => $type === 'focused' && `
        color: ${styles.colors.primary600};
    `}

    ${({ $type }) => $type === 'white' && `
        background-color: ${styles.colors.white};
        border: 1px solid ${styles.colors.white};
        & .path { fill: ${styles.colors.black300}; }

        &:hover {
            border: 1px solid ${styles.colors.primary600};
            & .path { fill: ${styles.colors.primary600}; }
        }
    `}
    ${({ $type }) => $type === 'grey' && `
        background-color: ${styles.colors.uiBackground};
        border: 1px solid ${styles.colors.uiBackground};
        & .path { fill: ${styles.colors.black300}; }

        &:hover {
            background-color: ${styles.colors.primary200};
            border: 1px solid ${styles.colors.primary200};
            & .path { fill: ${styles.colors.primary600}; }
        }
    `}
    ${({ $type }) => $type === 'golden' && `
        background-color: #fef8e7;
        border: 1px solid #fef8e7;
        & .path { fill: #fdbe00; }

        &:hover {
            & .path { fill: #fdbe00; }
        }
    `} /* todo: one of the missing colors, add them to design */
    ${({ $type }) => $type === 'orange' && `
        background-color: #fcd9b4;
        border: 1px solid #fcd9b4;
        & .path { fill: #ff9100; }

        &:hover {
            & .path { fill: #ff9100; }
        }
    `}
    ${({ $type }) => $type === 'light-blue' && `
        color: ${styles.colors.primary600};
        background-color: ${styles.colors.primary100};
        border: 1px solid ${styles.colors.primary100};
        & .path { fill: ${styles.colors.primary100}; }

        &:hover {
            background-color: ${styles.colors.primary200};
            border: 1px solid ${styles.colors.primary200};
            & .path { fill: ${styles.colors.primary600}; }
        }
    `}
    ${({ $type }) => $type === 'blue' && `
        color: ${styles.colors.white};
        background-color: ${styles.colors.primary600};
        border: 1px solid ${styles.colors.primary600}; 
        & .path { fill: ${styles.colors.primary600}; }

        &:hover {
            color: ${styles.colors.white};
            background-color: ${styles.colors.primary400};
            border: 1px solid ${styles.colors.primary400};
            & .path { fill: ${styles.colors.white}; }
        }
    `}
    ${({ $type }) => $type === 'red' && `
        color: ${styles.colors.white};
        background-color: ${styles.colors.red500};
        border: 1px solid ${styles.colors.red500}; 
        & .path { fill: ${styles.colors.primary600}; }

        &:hover {
            color: ${styles.colors.white};
            background-color: ${styles.colors.red400};
            border: 1px solid ${styles.colors.red400};
            & .path { fill: ${styles.colors.white}; }
        }
    `}
    /*todo: remove these and do it properly*/
    ${({ $type }) => $type === 'dark-primary' && `
        background-color: #373945;
        border: 1px solid #373945;
        & .path { fill: ${styles.colors.black300}; }

        &:hover {
            border: 1px solid ${styles.colors.primary600};
            & .path { fill: ${styles.colors.primary600}; }
        }
    `}
    ${({ $type }) => $type === 'dark-secondary' && `
        background-color: #4E515C;
        border: 1px solid #4E515C;
        & .path { fill: ${styles.colors.black300}; }

        &:hover {
            border: 1px solid ${styles.colors.primary600};
            & .path { fill: ${styles.colors.primary600}; }
        }
    `}

    & .svg {
        vertical-align: top;
        position: relative;
        transition: all .2s ease;
        ${({ $rotateSVG }) => $rotateSVG && 'transform: rotate(180deg);'}
        ${({ $marginRightSVG }) => $marginRightSVG && 'margin-right: 5px;'}
        & .path { fill: ${({ $color }) => $color ? $color : styles.colors.primary600}; } 
    }

    &:disabled {
        color: ${({ theme: { primary } }) => primary ? styles.colors.black200 : styles.colors.black300};
        background-color: ${({transparent}) => transparent ? 'transparent' : styles.colors.black100};
        border: 1px solid ${({transparent}) => transparent ? 'transparent' : styles.colors.black100};
        cursor: default;

        & .LDtooltip { display: none; }
        & .path { fill: ${({ theme: { primary } }) => primary ? styles.colors.black200 : styles.colors.black300}; } 
    }
    ${({ $justifyLeft }) => $justifyLeft && `justify-content: flex-start;`}

`
export default Button