import { FunctionComponent } from "react";
import styled from "styled-components";
import { styles } from "../styles/themes/style";
import Text from "../components/Text";
import Column from "../components/Column";
import Row from "../components/Row";
import Button from "../components/Button";
import { useAuthStore } from "../stores/auth/useAuthStore";
import moment from "moment";
import CustomModal from "../components/modal/CustomModal";
import { useAppHandlers } from "../services/actions/app/useAppHandlers";
import { useAppStore } from "../stores/application/useAppStore";
import SvgFragment from "./SvgFragment";

const CsvOverviewModal: FunctionComponent = () => {
    const { hideModal } = useAppHandlers()
    const { store: { modal } } = useAppStore()

    return (
        <CustomModal
        $center
        headingText={'Connections Import Overview'}
        confirmButton={{
            text: 'Acknowledge',
            onClickHandler: hideModal
        }}
    >
        <OverviewModal>
            <Column gap="5px" spaceBetween>
                <StyledColumn>
                    <Row spaceBetween>
                        <Text $bold>{`Total rows in CSV file:`}</Text>
                        <Text $bold>{modal.totalRows}</Text>
                    </Row>
                </StyledColumn>
                <StyledColumn gap="12px">
                    <Row spaceBetween>
                        <Text $bold>{`Imported as new connections:`}</Text>
                        <Text $bold>{modal.newConnectionsCount}</Text>
                    </Row>
                    <Row spaceBetween>
                        <Text $bold>{`Updated existing connections:`}</Text>
                        <Text $bold>{modal.existingConnectionsCount}</Text>
                    </Row>
                </StyledColumn>
                <StyledColumn gap="12px">
                    <Row spaceBetween>
                        <Row alignItems gap="5px"><SvgFragment type="warning" /><Text $bold>{`Not imported:`}</Text></Row>
                        <Text $bold>{modal.invalidRowsCount}</Text>
                    </Row>
                </StyledColumn>
            </Column>
        </OverviewModal>
    </CustomModal>
    )
}

const OverviewModal = styled.div`
    border-radius: 5px;
    background-color: ${styles.colors.black100};
    padding: 24px;
`
const StyledColumn = styled(Column)`
    padding: 8px 16px;
    background: white;
    border-radius: 5px;
`
export default CsvOverviewModal