import { IAnalyticsResponse } from "../../../interfaces/response/analytics";
import { useAuthStore } from "../../../stores/auth/useAuthStore";
import { useAnalyticsRequest } from "../../apis/analytics/useAnalyticsRequest";

export const useAnalyticsHandlers = () => {

    const { getAnalytics, incrementDiconnectedConnectionsCountAnalytics } = useAnalyticsRequest()
    const { store: { workspace }} = useAuthStore()

    //GET - all of these can be done differently, but since we don't call for many different types and show it only in few places, this is the chosen way
    const getTotalConnectionsOverviewAnalytics = async (): Promise<IAnalyticsResponse[]> => {
        const result = await getAnalytics({
            type: "total",
            version: "connections-total,connections-updated,connections-imported-linkedin-total,connections-updated-connected-to-linkedin,connections-imported-csv-total,connections-imported-prospects-total,connections-disconnected-total"
        })
        return result ?? []
    };

    const getDashboardOverviewAnalytics = async (): Promise<IAnalyticsResponse[]> => {
        const result = await getAnalytics({
            type: "total",
            version: "connections-total,tags-total,connections-untagged,connections-messaged,connections-with-notes"
        })
        return result ?? []
    };

    const getDashboardChartAnalytics = async (): Promise<IAnalyticsResponse | undefined> => {
        const result = await getAnalytics({
            type: "total",
            version: "connections-profile-statistics"
        }, 'ProfileStatistics')
        return result ? result[0] : undefined
    }

    const incrementDiconnectedConnectionsCountAnalyticsHandler = async (incrementValue: any) => {
        return await incrementDiconnectedConnectionsCountAnalytics(incrementValue);
    }

    return {
        getTotalConnectionsOverviewAnalytics,
        getDashboardOverviewAnalytics,
        getDashboardChartAnalytics,
        incrementDiconnectedConnectionsCountAnalyticsHandler
    };
}
