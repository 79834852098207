import React from 'react';
import styled from 'styled-components';

const DropdownSeparator = React.forwardRef((props: any, ref: any) => {

    return (
        <Root
            ref={ref}

            {...props}
        />
    );
});

const Root = styled.div`
    border-bottom: 2px solid ${(props: any) => props.theme.tertiaryColor};
    margin: 10px 0;
`;

export default DropdownSeparator;
