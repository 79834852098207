import { FunctionComponent } from "react"
import styled from "styled-components";
import ListItem from "../components/list/ListItem"
import ProfilePhoto from "../components/ProfilePhoto"
import Text from "../components/Text";

interface IInvitationItemFragmentProps {
    invite?: string
    removeInvite: (e: any) => void;
}

const InvitationItemFragment: FunctionComponent<IInvitationItemFragmentProps> = ({ invite, removeInvite }) => {
    return (
        <Invite onDestructiveClickHandler={() => removeInvite(invite)}>
            <ProfilePhoto capitalLetters={invite?.slice(0, 2)}/>
            <InviteEmail>{invite}</InviteEmail>
        </Invite>
    )
}

const Invite= styled(ListItem)`
    padding: 10px;
    background-color: ${({ theme: { senaryColor } }) => senaryColor};
    border-radius: 4px;
    gap: 4px;
`

const InviteEmail = styled(Text)`
    flex-grow: 1;
`

export default InvitationItemFragment;