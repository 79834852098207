import React, { FormEvent, FunctionComponent, ReactNode, useCallback, useState } from 'react'
import styled from 'styled-components'
import TabsView, { ITabProperties } from '../../../../components/TabsView'
import CSVImport from './CSVImport'
import CustomFields from './CustomFields'
import Tags from './dataManagement/Tags'

interface IDataManagementProps { }

const dataManagementTabs = [/* {
    slug: "customConnectionFields",
    title: "Custom connection fields",
    selected: false
}, */
{
    slug: "csvImport",
    title: "CSV import",
    selected: true
},
/* {
    slug: "tags",
    title: "Tags",
    selected: false
} */]



const DataManagement: FunctionComponent<IDataManagementProps> = () => {
    const [ tabs, setTabs ] = useState<ITabProperties[]>(dataManagementTabs)
    const [ selectedSlug, setSelectedSlug ] = useState<string>('csvImport')

    const renderComponent: {[key: string]: ReactNode} = {
        /* customConnectionFields: <CustomFields />, */
        csvImport: <CSVImport />,
        tags: <Tags />
    }

    const switchTabs = useCallback((slug: string) =>{
        setSelectedSlug(slug)
        setTabs(tabs.map(tab => ({...tab, selected: tab.slug === slug})))
    }, [tabs])

    

    return <Container>
        <TabsView onSwitchTab={(slug)=>switchTabs(slug)} tabs={tabs}>
            {renderComponent[selectedSlug]}
        </TabsView>
    </Container>
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y:auto;
    height: 100%;
`

export default DataManagement
