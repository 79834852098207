import { FunctionComponent } from 'react'
import styled from 'styled-components'
import Text from '../../../../components/Text'
import SvgFragment from '../../../../fragments/SvgFragment'
import { useAppStore } from '../../../../stores/application/useAppStore'

import PlansAndFeatures from './PlansAndFeatures'

interface ICompareFeaturesStyles { }

interface ICompareFeaturesProps extends ICompareFeaturesStyles {
    selectedPrices?: any[],
    currency?: string,
    selectedCycle?: string,
    setShowCompareFeatures?: (e: any) => void
}

const CompareFeatures: FunctionComponent<ICompareFeaturesProps> = ({ selectedPrices, selectedCycle, currency, setShowCompareFeatures }) => {

    return (
        <Container>
            <Wrapper>
                <TitleWrapper>
                    <Text $black $heading2>Compare plans</Text>
                    <CloseButton onClick={() => setShowCompareFeatures && setShowCompareFeatures(false)}><SvgFragment type='close' /></CloseButton>
                </TitleWrapper>
                <PlansAndFeatures selectedPrices={selectedPrices} selectedCycle={selectedCycle} currency={currency} setSelectedPrice={e => setShowCompareFeatures && setShowCompareFeatures(false)}/>
            </Wrapper>
        </Container>
    )
}
const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #33395182;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
`
const Wrapper = styled.div<ICompareFeaturesStyles>`
    width: 100%;
    height: calc(100% - 140px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: white;
    padding: 24px;
    border-radius: 4px;
    margin: 80px 16px auto 16px};
`
const TitleWrapper = styled.div`
    display: flex;
    position: relative;
`
const CloseButton = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    .svg {
        width: 34px;
        height: 34px
    }
`

export default CompareFeatures