import { FormEvent, FunctionComponent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import DateComponent from "../../components/DateComponent";
import { useNotesStore } from "../../stores/notes/useNotesStore";
import Input from "../../components/Input";
import AdvancedFilterFragment from "../AdvancedFilterFragment";
import { useNotesHandlers } from "../../services/actions/notes/useNotesHandlers";
import { IUser } from "../../interfaces/models/connection";
import FormControl from "../../components/FormControl";
import ProfilePhoto from "../../components/ProfilePhoto";
import Row from "../../components/Row";
import Text from "../../components/Text";
import Column from "../../components/Column";
import Select from "../../components/select/Select";
import { useAuthStore } from "../../stores/auth/useAuthStore";
import ActionText from "../../components/ActionText";
import Button from "../../components/Button";
import { SidebarTypes } from "../../interfaces/store/appStore";
import { useAppHandlers } from "../../services/actions/app/useAppHandlers";

interface IFilterNotesFormProps { }


const FilterNotesForm: FunctionComponent<IFilterNotesFormProps> = () => {

    const { store: { notesFilters } } = useNotesStore()
    const { store: { workspace} } = useAuthStore()

    const { setInitialNotesFiltersHandler, workspaceUsersPublicAllHandler, setClearNotesFilterHandler } = useNotesHandlers()
    const [workspaceUsers, setWorkspaceUsers] = useState<any>([])
    const { closeSidebar } = useAppHandlers()

    const { searchByText, searchByConnectionName, createdAtFrom, createdAtTo, selectedUsersMutual, usersMutualCondition, selectedUsersTagged, usersTaggedCondition } = notesFilters
    const infoTitleNotes = "Choose whether you want to get items that have all the selected notes (AND) or any of the selected notes (OR).";
    const inputTitleMutualNotes = "User Notes";
    const inputTitleTaggedNotes = "Users tagged"

    const noteTypeOptions = [
        { value: 'all', label: 'All' },
        { value: 'yes', label: 'Public' },
        { value: 'no', label: 'Private' },  
    ];

    useEffect(() => {
        workspaceUsersHandler()
    }, [])

    const workspaceUsersHandler = async () => {
        const result = await workspaceUsersPublicAllHandler({
            condition: 'or',
            name: '',
            email: '' 
        })
        setWorkspaceUsers(result)
    }

    const setSelectedUsersMutual = (newlySelectedUsers: IUser[]) => {
        const updatedFilter = {
            ...notesFilters,
            selectedUsersMutual: [...newlySelectedUsers]
        }; 
        setInitialNotesFiltersHandler(updatedFilter);
    };

    const resetSelectedUsersMutual = () => {
        const updatedFilter = {
            ...notesFilters,
            selectedUsersMutual: []
        }; 
        setInitialNotesFiltersHandler(updatedFilter);
    };

    const setSelectedUsersTagged = (newlySelectedUsers: IUser[]) => {
        const updatedFilter = {
            ...notesFilters,
            selectedUsersTagged: [...newlySelectedUsers]
        }; 
        setInitialNotesFiltersHandler(updatedFilter);
    };

    const resetSelectedUsersTagged = () => {
        const updatedFilter = {
            ...notesFilters,
            selectedUsersTagged: []
        }; 
        setInitialNotesFiltersHandler(updatedFilter);
    };

    const updateForm = useCallback((control: any, newValue: string) => {
        const updatedFilter = {
            ...notesFilters,
            [control]: newValue
        };

        setInitialNotesFiltersHandler(updatedFilter);
    }, [notesFilters, setInitialNotesFiltersHandler]);

    const resetFormHandler = useCallback(() => {
        setClearNotesFilterHandler();
    }, [notesFilters, setClearNotesFilterHandler]);

    const showResults = useCallback(async (e: FormEvent) => {
        e.preventDefault()
        closeSidebar(SidebarTypes.NOTE_FILTER)
    }, [notesFilters]);

    return (
        <StyledForm>
            <FormControl $orientation="column">
                <DateComponent
                    title='Date Created'
                    dateFrom={createdAtFrom}
                    dateTo={createdAtTo}
                    dateFromValueChangedHandler={(e: any) => setInitialNotesFiltersHandler({...notesFilters, createdAtFrom: e})}
                    dateToValueChangedHandler={(e: any) =>  setInitialNotesFiltersHandler({...notesFilters, createdAtTo: e})}
                    resetFilter={() => setInitialNotesFiltersHandler({...notesFilters, createdAtFrom: '', createdAtTo: ''})}
                />
            </FormControl>
            <FormControl $orientation="column">
                <Input
                    placeholder="Search by text..."
                    prefilledValue={searchByText}
                    onChangeHandler={(e: any) => setInitialNotesFiltersHandler({...notesFilters, searchByText: e})}
                />
            </FormControl>
            <FormControl $orientation="column">
                <Input
                    placeholder="Search by connection name..."
                    prefilledValue={searchByConnectionName}
                    onChangeHandler={(e: any) => setInitialNotesFiltersHandler({...notesFilters, searchByConnectionName: e})}
                />
            </FormControl>
            { 
            workspace.isBusiness &&
            <><AdvancedFilterFragment
                    filterOptions={workspaceUsers.map((user: IUser) => ({
                        value: `${user.firstName} ${user.lastName}`,
                        label: <Row alignItems gap="10px">
                            <ProfilePhoto capitalLetters={`${user?.firstName?.charAt(0).toUpperCase()}${user?.lastName?.charAt(0).toUpperCase()}`} />
                            <Column>
                                <Text>{user?.firstName + ' ' + user?.lastName}</Text>
                                <Text>{user?.email}</Text>
                            </Column>
                        </Row>,
                        id: user._id,
                        selected: false,
                        object: user
                    }))}
                    selectedItems={selectedUsersMutual}
                    infoTitle={infoTitleNotes}
                    inputTitle={inputTitleMutualNotes}
                    tabs={[{ title: 'Or', slug: 'Or', selected: usersMutualCondition === 'Or' }, { title: 'And', slug: 'And', selected: usersMutualCondition !== 'Or' }]}
                    onSelectedTabHandler={(tab: string) => updateForm('usersMutualCondition', tab)}
                    onSelectedItemHandler={setSelectedUsersMutual}
                    onResetSelectionsHandler={resetSelectedUsersMutual} />
            <AdvancedFilterFragment
                        filterOptions={workspaceUsers.map((user: IUser) => ({
                            value: `${user.firstName} ${user.lastName}`,
                            label: <Row alignItems gap="10px">
                                <ProfilePhoto capitalLetters={`${user?.firstName?.charAt(0).toUpperCase()}${user?.lastName?.charAt(0).toUpperCase()}`} />
                                <Column>
                                    <Text>{user?.firstName + ' ' + user?.lastName}</Text>
                                    <Text>{user?.email}</Text>
                                </Column>
                            </Row>,
                            id: user._id,
                            selected: false,
                            object: user
                        }))}
                        selectedItems={selectedUsersTagged}
                        infoTitle={infoTitleNotes}
                        inputTitle={inputTitleTaggedNotes}
                        tabs={[{ title: 'Or', slug: 'Or', selected: usersTaggedCondition === 'Or' }, { title: 'And', slug: 'And', selected: usersTaggedCondition !== 'Or' }]}
                        onSelectedTabHandler={(tab: string) => updateForm('usersTaggedCondition', tab)}
                        onSelectedItemHandler={setSelectedUsersTagged}
                        onResetSelectionsHandler={resetSelectedUsersTagged} />
            <FormControl $orientation="column">
                        <Select
                            placeholder="Note type"
                            isSearchable={false}
                            options={noteTypeOptions}
                            value={notesFilters?.noteAccessType ? { label: 'Note type: ' + notesFilters?.noteAccessType.label } : undefined}
                            onChange={(e: any) => updateForm('noteAccessType', e)} />
            </FormControl></>
            }
            <Row gap="10px" spaceBetween>
                <ActionText danger onClickHandler={() => resetFormHandler()}>Clear filters</ActionText>
                <Row gap="10px" alignItems>
                    <Button $mediumButton $type="blue" onClickHandler={(e: any) => showResults(e)}>Results</Button>
                </Row>
            </Row>
        </StyledForm>
    )
}

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
`
export default FilterNotesForm