import { ICSVQuery, ICSVimport, useCSVImportRequest } from "../../apis/csv/useCSVimportRequest";
import { useAppHandlers } from "../app/useAppHandlers";


export const useCSVImportHandlers= () => {
    const { getCSVImports, downloadCSVImport } = useCSVImportRequest()
    const { showSuccessToast } = useAppHandlers()

    const getCSVImportsHandler = async (params: ICSVQuery): Promise<{ data: ICSVimport[], meta: { pages: number, totalCount: number }}> => {
        const result = await getCSVImports(params)
        return {
            data: result?.data ?? [],
            meta: {
                pages: result?.meta?.last_page ?? 1,
                totalCount: result?.meta?.total ?? 0
            }
        }
    };

    const downloadCSVHandler = async (singleImport: any) => {
        const response = await downloadCSVImport({ csvId: singleImport.id });
        // Create a Blob URL for the blob data
        if(response){
            const url = window.URL.createObjectURL(new Blob([response])) 
            const link = document.createElement('a')
            link.href = url;
            link.download = singleImport.originalFilename
            document.body.appendChild(link)
            link.click()
            link.remove()   
        } 

        showSuccessToast({message: 'CSV successfully downloaded.'})
    }

    return {
        getCSVImportsHandler,
        downloadCSVHandler
    };
}

