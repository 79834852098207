import { HubspotAccessToken, TriggerObject } from "../../interfaces/models/hubspot";
import { THubspotState, THubspotAction, ActionType } from "../../interfaces/store/hubspotStore";
import { makeStore } from "../makeStore";

const initialState: THubspotState = {
    hubspotAccessToken: {} as HubspotAccessToken,
    triggers: [] as TriggerObject
}

const reducer = (state: THubspotState, action: THubspotAction): THubspotState => {
    switch (action.type) {
        case ActionType.SET_HUBSPOT_ACCESS_TOKEN: return { ...state, hubspotAccessToken: action.payload }
        case ActionType.SET_HUBSPOT_TRIGGERS: return { ...state, triggers: action.payload }
        case ActionType.RESET_STORE: return { ...initialState }
        default: return state
    }
}

const { StoreProvider: HubspotStoreProvider, useStoreContext: useHubspotStore } = makeStore(reducer, initialState)

export { HubspotStoreProvider, useHubspotStore }