import { useAxios } from "../useAxios"
import { useAuthStore } from "../../../stores/auth/useAuthStore"


export const usePabblyWebhook = () => {
    const { sendRequest } = useAxios()
    const { store: { authData: { accessToken } } } = useAuthStore()

    const route = '/pabbly/webhooks'

    const getPabblyWebhooks = (payload?: any) => {
        return sendRequest({
            slug: 'get-pabbly-webhooks',
            url: `${route}/paginate`,
            method: 'GET',
            payload
        })
    };

    const createPabblyWebhook = (payload?: any) => {
        return sendRequest({
            slug: 'create-pabbly-webhook',
            url: `${route}`,
            method: 'POST',
            payload
        })
    };

    const editPabblyWebhook = (payload?: any) => {
        return sendRequest({
            slug: 'edit-pabbly-webhook',
            url: `${route}/${payload._id}`,
            method: 'PUT',
            payload: payload.body
        })
    };

    const deletePabblyWebhook = (id?: any) => {
        return sendRequest({
            slug: 'delete-pabbly-webhook',
            url: `${route}/${id}`,
            method: 'DELETE'
        })
    };

    return {
        getPabblyWebhooks,
        createPabblyWebhook,
        editPabblyWebhook,
        deletePabblyWebhook
    };
}