import { FunctionComponent, useEffect, useState } from "react";
import { styles } from "../styles/themes/style";

import styled from "styled-components";
import SvgFragment from "../fragments/SvgFragment";
import Input from "./Input";

interface IStyledInputProps {
    $error?: boolean,
    $disabled?: boolean,//todo: check disabled in input
}

export interface ICounterProps extends IStyledInputProps {
    type?: string,
    placeholder?: string,
    prefilledValue?: number,
    minValue?: number,
    maxValue?: number,
    onChangeHandler: (e: any) => void
}

const InputNumber: FunctionComponent<ICounterProps> = ({ $disabled, prefilledValue, minValue, maxValue, onChangeHandler, ...props }) => {
    const [$value, setValue] = useState<string>(prefilledValue ? prefilledValue.toString() : '0')
    useEffect(() => {
        setValue(prefilledValue ? prefilledValue.toString() : '0')
    }, [prefilledValue])
    const inputChangedHandler = (value: string) => {
        if(value!=='0') {
            setValue(value.replace(/^0+/, ''));
        } else {
            setValue('0');
        }
        if(minValue && +value < minValue) return
        if(maxValue && +value > maxValue) return
        onChangeHandler(+value)
    }

    const minusValueHandler = (event: any) => {
        event.preventDefault();
        setValue((+$value-1).toString())
        inputChangedHandler((+$value-1).toString())  
    }

    const plusValueHandler = (event: any) => {
        event.preventDefault();
        setValue((+$value+1).toString())
        inputChangedHandler((+$value+1).toString())
    }


    return (
            <InputWrapper>
                <Input
                    type='number'
                    $noBorder
                    prefilledValue={$value?.toString()}
                    onChangeHandler={inputChangedHandler}
                    {...props}
                />
                <Divider />
                <StyledCounter onClick={minusValueHandler}>
                    <SvgFragment type="minus" />
                </StyledCounter>
                <Divider />
                <StyledCounter onClick={plusValueHandler}>
                    <SvgFragment type="plus" />
                </StyledCounter>
            </InputWrapper>
    )
}

const InputWrapper = styled.div`
    max-width: 125px;
    min-width: 125px;
    height: 35px;
    display: flex;
    background: ${(props: any) => props.theme.secondaryColor};
    border: 2px solid ${({ theme: { quinaryColor } }) => `${quinaryColor}`};
    border-radius: 4px;

    &:hover { border: 2px solid ${styles.colors.primary600}; }

    & input  {background-color: transparent; }
`
const StyledCounter = styled.button`
    width: fit-content;
    height: 31px;
    display: flex;
    background: ${(props: any) => props.theme.secondaryColor};
    border: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 1px 3px;
    .svg {
        width: 20px;
        height: 20px;
        .path {
            fill: ${styles.colors.black300};
        }
    }
    ${({ disabled }) => disabled ? `.svg { fill: ${styles.colors.disabledState}; }` : `&:hover .svg .path { fill: ${styles.colors.primary600}; }`}

    &:hover { background-color: ${styles.colors.primary100} }
`
const Divider = styled.div`
    width: 2px;
    height: 31px;
    background: ${({ theme: { quinaryColor } }) => `${quinaryColor}`};
`
export default InputNumber