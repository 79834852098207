import { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgFragment from '../../../../fragments/SvgFragment'
import Text from '../../../../components/Text'
import { styles } from '../../../../styles/themes/style'

const NoDataPlaceholder:FunctionComponent<{ svgType: string, title: string }> = ({ svgType, title }) => {
    
    return (
        <Container>
            <SvgFragment type='svgType'/>
            {title && <Text> { title } </Text>}
        </Container>
    )
}
const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;

    width: 100%;

    background: ${({theme: {secondaryColor}}) => secondaryColor};
    & .svg .path {
        ${({theme: { primary }}) => primary ? styles.colors.black100 : styles.colors.darkBlack600 };
    }
`
export default NoDataPlaceholder