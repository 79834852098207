import { FunctionComponent } from 'react'
import CustomModal from '../../../CustomModal'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const TakeShortBrakeSection: FunctionComponent = () => {
    const navigate = useNavigate()

    return (
        <CustomModal
            headingText={'Sounds good, let’s take a short break. Here’s what happens next.'}
            closeButton
            descriptionText={<StyledText>{'You can login to LeadDelta but you won’t be able to use it until the pause period is over. We’ll send you a reminder.'}</StyledText>}
            confirmButton={{
                text: 'Got it',
                bigButton: true,
                onClickHandler: () => navigate(0)
            }}
        />
    )
}

const StyledText = styled.div`
    display: inline-block;
    text-align: left;
    font-weight: normal;
`

export default TakeShortBrakeSection