import React, { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Column from '../../../../../components/Column'
import Row from '../../../../../components/Row'
import Text from '../../../../../components/Text'
import Switch from '../../../../../components/Switch'
import { useAuthStore } from '../../../../../stores/auth/useAuthStore'
import { useAuthHandlers } from '../../../../../services/actions/auth/useAuthHandlers'

interface ITagsProps { }

const Tags: FunctionComponent<ITagsProps> = () => {
    const { store: { userWorkspaces, workspace } }  = useAuthStore()
    const { saveTagsAndNotesSettingsHandler } = useAuthHandlers()
    const [ autoTags, setAutoTags ] = useState<boolean>(true)
    useEffect(() => {
        if(workspace && userWorkspaces) {
            const selectedWorkspace = userWorkspaces.find((userWorkspace: any) => workspace._id === userWorkspace.workspaceId)
            setAutoTags(selectedWorkspace?.showAutoTags ?? true)
        }
    }, [userWorkspaces, workspace])

    const showAutoTagsHandler = useCallback(async (newAutoTags: boolean) => {
        await saveTagsAndNotesSettingsHandler(newAutoTags)
        //setAutoTags(newAutoTags)
    }, [userWorkspaces])
    return <Column gap="40px">
    <Row spaceBetween gap='40px'>
        <Column gap='16px'>
            <Text $label $capitalize>{'Auto tags'}</Text>
            <Text $lighter>
                {`${autoTags? 'Hide' : 'Show'} auto-tags`}
            </Text>
        </Column>
        <Switch prefilledValue={autoTags} onClickHandler={()=>showAutoTagsHandler(!autoTags)}/>
    </Row>
</Column>
}

export default Tags
