import { useStripe } from '@stripe/react-stripe-js'
import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { useBillingHandlers } from '../../../services/actions/billing/useBillingHandlers'
import { useBillingActions } from '../../../stores/billing/useBillingActions'
import Spinner from '../../../components/Spinner'
import { analytic, getID, getPageUrl, getPageTitle } from '../../../utils'
import { useBillingRequest } from '../../../services/apis/billing/useBillingRequest'
import { AppRoutes } from '../../../interfaces/store/appStore'
import { useAppHandlers } from '../../../services/actions/app/useAppHandlers'

interface IProcessingSectionProps { }

const ProcessingSection: FunctionComponent<IProcessingSectionProps> = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const { buySubscriptionHandler, addPaymentMethodHandler } = useBillingHandlers()
    const { getCurrentPayingPriceRequestHandler } = useBillingRequest();
    const { showErrorToast } = useAppHandlers()
    const navigate = useNavigate()
    const stripe = useStripe()

    useEffect(() => {
        if (stripe) {
            //we can make this experience better by sending price id ans product Id, plans should already be here
            const setupIntentClientSecret = searchParams.get('setup_intent_client_secret') ?? ""
            const payNow = searchParams.get('payNow') ?? undefined
/*             const billingEmail = searchParams.get('billingEmail') ?? ""
            const billingName = searchParams.get('billingName') ?? "" */
            const customerId = searchParams.get('customerId') ?? ""
            const licenceQuantity = searchParams.get('quantity') ?? undefined
            const priceId = searchParams.get('priceId') ?? undefined //coupon as well
            const couponId = searchParams.get('couponId') ?? undefined
            const prorationDate = searchParams.get('prorationDate') ?? undefined
            const currency = searchParams.get('currency') ?? undefined
            

            if (setupIntentClientSecret) stripe.retrieveSetupIntent(setupIntentClientSecret)
                .then(async ({ setupIntent }: any) => {
                    const price = await getCurrentPayingPriceRequestHandler(priceId);

                    // Inspect the SetupIntent `status` to indicate the status of the payment
                    // to your customer.
                    //
                    // Some payment methods will [immediately succeed or fail][0] upon
                    // confirmation, while others will first enter a `processing` state.
                    //
                    // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                    switch (setupIntent?.status) {
                        case 'succeeded':
                            let result = payNow ? await buySubscriptionHandler({ paymentMethodId: setupIntent?.payment_method as string, customerId, licenceQuantity, priceId, couponId, prorationDate, currency }) :
                                await addPaymentMethodHandler(setupIntent?.payment_method as string)

                            if (payNow && result) {

                                // analytic 
                                analytic({
                                    event: 'purchase',
                                    transaction_id: getID(),
                                    pageUrl: getPageUrl(),
                                    pageTitle: getPageTitle(),
                                    currency: price.currency,
                                    value: price.unit_amount,
                                    items: [
                                        {
                                            item_id: 'LD-paid',
                                            item_name: 'LD paid plan'
                                        }
                                    ]
                                });
                            }

                            navigate(`${AppRoutes.SETTINGS_BILLING}${payNow && result ? '?successModal=true' : ''}`)

                            break;

                        case 'processing':
                            result = payNow ? await buySubscriptionHandler({ paymentMethodId: setupIntent?.payment_method as string, customerId, licenceQuantity, priceId, couponId, currency }) :
                                await addPaymentMethodHandler(setupIntent?.payment_method as string) //todo: check this at some point, added for SEPA and SOFORT
                            navigate(`${AppRoutes.SETTINGS_BILLING}${payNow && result ? '?successModal=true' : ''}`)
                            break;

                        case 'requires_payment_method':
                            // Redirect your user back to your payment page to attempt collecting
                            // payment again
                            showErrorToast({ message: 'Failed to process payment details. Please try another payment method.'})
                            navigate(`${AppRoutes.BILLING}`)
                            console.log('Failed to process payment details. Please try another payment method.');
                            break;
                    }
                });
        }
    }, [searchParams, stripe])

    return (<Container>
        <Spinner showHideSpinner={true} />
    </Container>)
}
const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
`

export default ProcessingSection