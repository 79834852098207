import React, { FunctionComponent, useEffect, useState } from 'react'
import { styles } from './../../../../../styles/themes/style';
import styled from 'styled-components'
import Column from '../../../../../components/Column';
import Row from '../../../../../components/Row';
import TriggersHubspot from './TriggersHubspot';
import AccessTokenHubspot from './AccessTokenHubspot';
import { useHubspotHandlers } from '../../../../../services/actions/hubspot/useHubspotHandlers';

interface IHubspotProps { }

const Hubspot: FunctionComponent<IHubspotProps> = () => {
    const { getHubspotTriggersHandler, getHubspotAccessTokenHandler } = useHubspotHandlers()
    const [selectedPage, setSelectedPage] = useState<string>('triggers')

    const sections = [
        { id: 'triggers', label: 'Triggers', enabled: true },
        { id: 'accessToken', label: 'Access Token', enabled: true }
    ]

    useEffect(() => {
        getHubspotAccessTokenHandler()
        getHubspotTriggersHandler()
    }, [])

    const renderRoute = () => {
        switch (selectedPage) {
            case 'accessToken':
                return <AccessTokenHubspot />
            case 'triggers':
            default:
                return <TriggersHubspot />
        }
    }

    return (
        <Container>
            <Column>
                <Row gap='10px'>
                    {sections.map((integration) => integration.enabled && (
                        <NavButton key={integration.id} $disabled={false} $selected={selectedPage === integration.id}>
                            <a onClick={() => setSelectedPage(integration.id)}>{integration.label}</a>
                        </NavButton>
                    ))}
                </Row>
                {renderRoute()}
            </Column>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    padding: 16px;
`

const NavButton = styled.div<{$disabled?: boolean, $selected?: boolean}>`
    line-height: 20px;
    font-family: NunitoSans;
    font-size: 14px;
    font-weight: bold;
    padding: 0px 12px;
    height: 100%;
    display: flex;
    align-items: center;
    a {
      ${({ theme: { primaryColor } }) => primaryColor && `color: ${primaryColor};`}
    }
    ${({ $disabled }) => $disabled && `
        cursor: default;
        a, &:hover a, &:focus a {
            cursor: default;
            color: ${styles.colors.disabledState} !important;
        }
    `}
    ${({ $selected }) => $selected && `
        border-bottom: 2px solid #5964FF;
        cursor: default;
        a {
            cursor: default;
            color: ${styles.colors.primary600} !important;
        }
    `}
`

export default Hubspot;
