import { FunctionComponent, useContext, useEffect, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { styles } from "../../styles/themes/style";
import { HexColorInput, HexColorPicker } from 'react-colorful'
import CustomModal from '../../components/modal/CustomModal';
import { useAppHandlers } from '../../services/actions/app/useAppHandlers';


export interface ICustomColorModalProps{
    title?: string,
    colorProp: string,
    onColorChangedHandler: (e: any) => void
}

const CustomColorModal : FunctionComponent<ICustomColorModalProps> = ({title, colorProp, onColorChangedHandler}) => {
    const themeContext = useContext(ThemeContext)
    const [color, setColor] = useState('ffffff')
    const { hideModal } = useAppHandlers()

    useEffect(() => {
        setColor(colorProp.substring(1))  

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        
        <CustomModal 
                headingText={title}
                confirmButton={{
                text: 'Save',
                mediumButton: true,
                onClickHandler: (e: any) => { onColorChangedHandler(color); hideModal() }
            }}
                cancelButton={{
                text: "Close",
                mediumButton: true,
                onClickHandler: (e: any) => { hideModal() }
            }}
        >
            <StyledBody>
                <Label>Hex Color Code</Label>
                <HexInput color={color} onChange={setColor} />
                <HexColorPicker color={color} onChange={setColor} />
            </StyledBody>
        </CustomModal>
    )
}

const StyledBody = styled.div`

    & .react-colorful { width: 100%; }
    & .react-colorful__saturation, .react-colorful__hue { border-radius: 0; }
    & .react-colorful__saturation-pointer, .react-colorful__hue-pointer {
        width: 20px;
        height: 20px;
    }
`
const Label = styled.p`
    line-height: 16px;
    font-family: NunitoSans;
    font-size: 11px;
    color: ${styles.colors.primary600};
    text-transform: uppercase;
    margin: 0 0 6px;
`
const HexInput = styled(HexColorInput)`
    width: 100%;
    height: 34px;
    line-height: 30px;
    font-family: NunitoSans;
    font-size: 14px;
    color: ${styles.colors.primary600};
    outline: none;
    padding: 0 10px;
    background-color: ${styles.colors.uiBackground};
    border: 2px solid ${props => props.theme.primary ? '#DAE0E8' : '#4E515C'};
    border-radius: 4px;
    margin-bottom: 24px;

    &:focus, &:hover { border: 2px solid ${styles.colors.primary600}; }
`


export default CustomColorModal