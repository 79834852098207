import axios from "axios";
import { TCallbackFunction } from "../../interfaces/common";
import { IAuthData } from "../../interfaces/store/authStore";
import { useAppActions } from "../../stores/application/useAppActions";
import { useAuthActions } from "../../stores/auth/useAuthActions";
import { useAuthStore } from "../../stores/auth/useAuthStore";

export interface IAxiosParams {
  enabled?: boolean,
  slug: string,
  baseURL?: string,
  url: string
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
  params?: Object
  payload?: Object,
  additionalHeaders?: {
    [key: string]: string;
  }
  clb?: TCallbackFunction<any>
  errorClb?: TCallbackFunction<any>
  displayToast?: boolean;
  displayErrorToast?: boolean
  all?: boolean;

}

export interface IResponse {
  data?: any,
  success?: boolean
}

const controllers: { [key: string]: AbortController } = {};

export const useAxios = () => {
  const { store: { authData: { accessToken, refreshToken } } } = useAuthStore()
  const { syncAccesToken, setAuthData } = useAuthActions()
  const { setRequestLoading, setToast } = useAppActions()

  const sendRequest = async ({ baseURL, slug, url, method = 'GET', params, payload, additionalHeaders, errorClb, clb, displayToast, displayErrorToast, enabled = true, all = false }: IAxiosParams) => {
    displayToast = displayToast !== undefined ? displayToast : method !== 'GET';

    let continueLoading = false;
    try {
      setRequestLoading({ [slug]: true });

      if (!enabled) throw Error("Forbidden!");

      // Abort the previous request with the same slug if it exists
      if (controllers[slug]) {
        controllers[slug].abort();
      }

      // Create a new controller for the current request
      const controller = new AbortController();
      controllers[slug] = controller;

      const { accessToken: additionalAccess, refreshToken: additionalRefresh, ...forcedHeaders } = additionalHeaders ?? {}

      const { data, headers } = await axios.request({
        baseURL: baseURL ?? process.env.REACT_APP_API_URL,
        url,
        method,
        params,
        data: payload,
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          ...(additionalAccess || accessToken) && { Authorization: additionalAccess || accessToken},
          ...(additionalRefresh || refreshToken) && { 'refresh-token': additionalRefresh || refreshToken },
          ...forcedHeaders
        },
        signal: controller.signal
      })

      delete controllers[slug];

      if (refreshToken && headers?.['new-access-token']) {
        const newAccessToken = syncAccesToken(headers?.['new-access-token']);

        setAuthData({ accessToken: newAccessToken, refreshToken });
      }

      //if (clb) clb(data?.data)
      if(!baseURL && (!data || !data?.success)) throw Error("There was a problem performing this action!");
      else if (!data) throw Error("There was a problem performing this action!");

      if (displayToast) setToast({ type: 'success', message: "Action succeeded!" });

      return all ? data : data?.data;
    }
    catch (error: any) {
      //console.error(error)
      if(error?.code === 'ERR_CANCELED') continueLoading = true;
      const errorMessage = axios.isAxiosError(error) ? error?.response?.data?.message : "There was a problem performing this action!";

      if (displayToast || displayErrorToast) setToast({ type: 'error', message: errorMessage });

      if (errorClb) errorClb(errorMessage);

      return all ? (error as any)?.response?.data : (error as any)?.response?.data?.data ;
    }
    finally {
      if(!continueLoading) setRequestLoading({ [slug]: false })
      delete controllers[slug];
    }
  }
  return { sendRequest }
}