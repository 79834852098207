import React, { FormEvent, FunctionComponent, ReactNode, useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import Column from './Column'
import Text from './Text'
import Row from './Row'
import ActionText from './ActionText'
import { styles } from '../styles/themes/style'
import TooltipWrapper from './TooltipWrapper'
import SvgFragment from '../fragments/SvgFragment'

interface ITabsViewProps { 
    onSwitchTab: (e: any) => void
    tabs: ITabProperties[],
    children?: ReactNode,
    infoTitle?: string,
    onReset?: (e: any) => void
}

export interface ITabProperties {
    slug: any
    title: string
    selected: boolean
}

const TabsView: FunctionComponent<ITabsViewProps> = ({onSwitchTab, children, infoTitle, tabs, onReset}) => {
    //this icon should be a tooltip, that's why it's custom from everthing else, reveist it when adding tooltips

    return <Container>
        <Column gap='12px' >
            <StyledRow spaceBetween>
                <Row gap="10px">
                    {!!tabs.length && tabs.map((tab, index) =>
                        <StyledContainer key={index} selected={tab.selected}><ActionText key={index} $active={tab.selected} variable={!tab.selected} onClickHandler={() => !tab.selected && onSwitchTab(tab.slug)} $bold>{tab.title}</ActionText></StyledContainer>
                        )}
                </Row>
                {onReset && <Row alignItems gap="2px">
                        {infoTitle?.length ? <TooltipWrapper tooltipText={infoTitle ?? ''}>
                            <StyledSvg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 4a8.001 8.001 0 0 0 0 16 8 8 0 1 0 0-16zm0 3.548a1.355 1.355 0 1 1 0 2.71 1.355 1.355 0 0 1 0-2.71zm1.806 8.194a.387.387 0 0 1-.387.387h-2.838a.387.387 0 0 1-.387-.387v-.774c0-.214.173-.387.387-.387h.387v-2.065h-.387a.387.387 0 0 1-.387-.387v-.774c0-.214.173-.387.387-.387h2.064c.214 0 .387.173.387.387v3.226h.387c.214 0 .387.173.387.387v.774z" fillRule="evenodd" />
                            </StyledSvg>
                        </TooltipWrapper> : null}
                        <ActionText onClickHandler={(e: any) => onReset(e)} danger>Reset</ActionText>
                    </Row>}
            </StyledRow>
            {children}

        </Column>
    </Container>
}

const Container = styled.div`
    width: 100%;
`
const StyledRow = styled(Row)`
    border-bottom: 1px solid ${({ theme: { quinaryColor} }) => quinaryColor};
`
const StyledContainer = styled.div<{selected: boolean}>`
    padding: 12px 0px;
    ${({selected}) => selected && `border-bottom: 2px solid ${styles.colors.primary600};`}
`

const StyledInfo = styled.div`
    position: relative;
    &:hover {
        span {
            display: block;
        }
        svg {
            fill: ${(props) => styles.colors.primary600};
        }
    }
`;

const StyledSvg = styled.svg`
  cursor: pointer;
  margin-bottom: 3px;
  transition: all 0.2s ease;
  fill: ${styles.colors.disabledState};

&:hover {
    fill: ${(props) => styles.colors.primary600};
}
`;

export default TabsView
