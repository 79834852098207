import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { styles } from '../styles/themes/style'

interface IInformationBoxProps { 
    message: ReactNode
}

const InformationBox: FunctionComponent<IInformationBoxProps> = ({ message }) => {
    return <Container>{message}</Container>
}

const Container = styled.div`
    color: ${props => props.theme.primaryColor};
    font-family: NunitoSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 16px 20px; 
    background: ${styles.colors.yellow300}
`

export default InformationBox
