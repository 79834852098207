import React, { useEffect } from 'react';
import styled from 'styled-components';

import Input from '../../../components/Input';
import { isValid } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { useAppHandlers } from '../../../services/actions/app/useAppHandlers';
import { useAuthActions } from '../../../stores/auth/useAuthActions';
import { useAuthRequest } from '../../../services/apis/auth/useAuthRequest';
import { AppRoutes } from '../../../interfaces/store/appStore';
import { styles } from '../../../styles/themes/style';


const ForgotPasswordSection = React.forwardRef((props: any, ref: any) => {
    const [step, setStep] = React.useState(1);
    const [values, setValues] = React.useState<any>({});
    const [valid, setValid] = React.useState(false);
    const [loaded, setLoaded] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState<any>(false);
    const { showSuccessToast, showErrorToast } = useAppHandlers();
    const { resetAuthStore } = useAuthActions()
    const { verificationCode, verificationCodeValidate, resetPassword } = useAuthRequest();
    const navigate = useNavigate();

    /* useEffect(() => {
        resetAuthStore()
    }, []) */

    const SendResetInstructions = React.useCallback(async () => {
        setLoading(true);

        try {
            if (valid) {
                const response = await verificationCode({
                    email: values.email
                });

                if (response?.success) {
                    setStep(2);

                    showSuccessToast({ message: `Your verification code is sent successfully!` });
                }
                else {
                    showErrorToast({ message: response?.message });
                }
            }
        }
        catch (error) {
            console.log('Sign up with email', error);
        }

        setLoading(false);
    }, [values, valid]);

    const VerificationCodeConfirm = React.useCallback(async () => {
        setLoading(true);

        try {
            if (valid) {
                const response = await verificationCodeValidate({
                    email: values.email,
                    code: values.verificationCode
                });

                if (response?.success) {
                    setStep(3);

                    updateValue('token', response.data.token);

                    showSuccessToast({ message: `Your verification code confirmed successfully!` });
                }
                else {
                    showErrorToast({ message: response?.message });
                }
            }
        }
        catch (error) {
            console.log('Sign up with email', error);
        }

        setLoading(false);
    }, [values, valid]);

    const UpdateNewPassword = React.useCallback(async () => {
        setLoading(true);

        try {
            if (valid) {
                const response = await resetPassword({
                    email: values.email,
                    password: values.newPassword,
                    passwordConfirmation: values.newPasswordConfirmation,
                    token: values.token
                });

                if (response?.success) {
                    showSuccessToast({ message: `Your password is updated successfully!` });

                    navigate(AppRoutes.SIGNIN);
                }
                else {
                    showErrorToast({ message: response?.message });
                }
            }
        }
        catch (error) {
            console.log('Sign up with email', error);
        }

        setLoading(false);
    }, [values, valid]);

    const updateValid = React.useCallback((valuesNew: any) => {
        const validNew = !!(
            isValid.email(valuesNew.email)
        );

        setValid(validNew);
    }, []);

    const updateValue = React.useCallback((property: string, valueNew: any) => {
        setValues((previous: any) => {
            const valuesNew = {
                ...previous,
                [property]: valueNew
            };

            // Valid 
            updateValid(valuesNew);

            return valuesNew;
        });
    }, []);

    const parts = {
        form: (
            <Main>
                <FormHeader>
                    <Title>Forgot Password?</Title>

                    <Description>Already have an account? <Link onClick={() => navigate(AppRoutes.SIGNIN)}>Log in</Link> instead.</Description>
                </FormHeader>

                <FormMain>
                    <Label>
                        Email address

                        <Input
                            placeholder='e.g. hey@email.com'

                            type='email'

                            value={values.email || ''}

                            onChangeHandler={valueNew => updateValue('email', valueNew)}
                        />
                    </Label>
                </FormMain>

                <Column
                    style={{
                        gap: 24
                    }}
                >
                    <Button
                        onClick={SendResetInstructions}

                        style={{
                            width: 'unset',
                            alignSelf: 'flex-start'
                        }}

                        disabled={!valid || loading}
                    >
                        Send Reset Instructions
                    </Button>
                </Column>

                <Meta
                    style={{
                        marginTop: -8
                    }}
                >
                    For security reasons, we do NOT store your password. So rest assured that we will never send your password via email.
                </Meta>
            </Main>
        ),
        formVerficationConfirm: (
            <Main>
                <FormHeader>
                    <Title>Enter verification code</Title>

                    <Description>If the email you@work.com is valid, you will receive a verification code. Enter it below or <Link onClick={() => navigate(AppRoutes.SIGNIN)}>Log in</Link> instead.</Description>
                </FormHeader>

                <FormMain>
                    <Label>
                        Verification code

                        <Input
                            placeholder='Enter the code you received via email'

                            type='verificationCode'

                            value={values.verificationCode || ''}

                            onChangeHandler={valueNew => updateValue('verificationCode', valueNew)}
                        />
                    </Label>
                </FormMain>

                <Column
                    style={{
                        gap: 24
                    }}
                >
                    <Button
                        onClick={VerificationCodeConfirm}

                        style={{
                            width: 'unset',
                            alignSelf: 'flex-start'
                        }}

                        disabled={!(
                            values.verificationCode
                        ) || loading}
                    >
                        Submit Verification Code
                    </Button>
                </Column>

                <Meta
                    style={{
                        marginTop: -8
                    }}
                >
                    For security reasons, we do NOT store your password. So rest assured that we will never send your password via email.
                </Meta>
            </Main>
        ),
        updateNewPassword: (
            <Main>
                <FormHeader>
                    <Title>Reset password</Title>

                    <Description>Please enter a new password and confirm it, or <Link onClick={() => navigate(AppRoutes.SIGNIN)}>Log in</Link> instead.</Description>
                </FormHeader>

                <FormMain>
                    <Label>
                        <span>Password</span>

                        <Input
                            placeholder='Enter new password here'

                            type={showPassword === 'newPassword' ? 'text' : 'password'}

                            value={values.newPassword || ''}

                            onChangeHandler={valueNew => updateValue('newPassword', valueNew)}

                            style={{
                                paddingRight: 60
                            }}
                        />

                        <Show
                            onClick={() => setShowPassword((previous: any) => previous === 'newPassword' ? false : 'newPassword')}
                        >
                            {showPassword === 'newPassword' ? 'Hide' : 'Show'}
                        </Show>
                    </Label>

                    <Label>
                        <span>Password confirmation</span>

                        <Input
                            placeholder='Please confirm the password'

                            type={showPassword === 'newPasswordConfirmation' ? 'text' : 'password'}

                            value={values.newPasswordConfirmation || ''}

                            onChangeHandler={valueNew => updateValue('newPasswordConfirmation', valueNew)}

                            style={{
                                paddingRight: 60
                            }}
                        />

                        <Show
                            onClick={() => setShowPassword((previous: any) => previous === 'newPasswordConfirmation' ? false : 'newPasswordConfirmation')}
                        >
                            {showPassword === 'newPasswordConfirmation' ? 'Hide' : 'Show'}
                        </Show>
                    </Label>
                </FormMain>

                <Column
                    style={{
                        gap: 24
                    }}
                >
                    <Button
                        onClick={UpdateNewPassword}

                        style={{
                            width: 'unset',
                            alignSelf: 'flex-start'
                        }}

                        disabled={!(
                            values.newPassword &&
                            values.newPasswordConfirmation &&
                            values.newPassword === values.newPasswordConfirmation
                        ) || loading}
                    >
                        Submit New Password
                    </Button>
                </Column>

                <Meta
                    style={{
                        marginTop: -8
                    }}
                >
                    For security reasons, we do NOT store your password. So rest assured that we will never send your password via email.
                </Meta>
            </Main>
        )
    }

    return (
        <>
        {loaded && <>
            {step === 1 && parts.form}

            {step === 2 && parts.formVerficationConfirm}

            {step === 3 && parts.updateNewPassword}
        </>}
        </>
    );
});


const Link = styled.span`
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    color: ${(props: any) => props.theme.primary600};
    transition: opacity 0.3s ease 0s; 

    &:hover {
        opacity: 0.54;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row; 
`;

const Column = styled.div`
    display: flex;
    flex-direction: column; 
`;

const Main = styled(Column)`
    width: 100%; 
    max-width: 400px; 
    margin: 0 auto;  
    gap: 32px;
`;

const FormHeader = styled(Column)`
    gap: 6px; 
`;

const FormMain = styled(Column)`
    gap: 16px; 
`;

const Title = styled.h1`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px; 
    color: ${(props: any) => props.theme.primaryColor}; 
    margin: 0px; 
`;

const Description = styled.p`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${(props: any) => props.theme.nonaryColor}; 
    margin: 0px; 
`;

const Label = styled.label`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    gap: 6px; 

    font-family: NunitoSans;
    letter-spacing: 0.5px;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    font-weight: bold;
    color: ${(props: any) => props.theme.nonaryColor}; 
    cursor: default;
`;

const Show = styled.span`
    position: absolute;
    bottom: 8px;
    right: 12px;
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${(props: any) => props.theme.primary600};
    cursor: pointer;
    user-select: none; 
`

const Button: any = styled.button<any>` 
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${(props: any) => props.theme.secondaryColor};
    border: none;
    outline: none;
    margin: 0;
    width: 100%;
    background: ${(props: any) => props.theme.primary600};
    padding: 0 10px;
    border-radius: 4px; 
    min-height: 34px;
    user-select: none; 
    transition: color .14s, background .14s, opacity .3s, transform .3s; 

    ${(props: any) => props.version === 'light' ? `
        color: ${props.color === 'default' ? props.theme.primaryColor : props.theme.primary600};
        background: ${props.theme.secondaryColor};
        border: 1px solid ${styles.colors.primary600};
    ` : ''}

    ${(props: any) => props.disabled ? `
        cursor: default;
        color: ${props.theme.disabledState};
        background-color: ${props.theme.senaryColor}; 
        pointer-events: none;
    ` : ''}

    &:hover {
        opacity: 0.74;
    }

    &:active {
        transform: scale(0.97);
    }
`;

const Meta = styled.p`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${(props: any) => props.theme.nonaryColor}; 
    margin: 0;
    margin-top: 24px; 
`

export default ForgotPasswordSection; 
