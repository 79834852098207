import React, { FormEvent, FunctionComponent, ReactNode, useCallback, useState } from 'react'
import styled from 'styled-components'
import Text from '../components/Text'

interface IFormTitleContainerProps {
    children?: ReactNode
    title?: string
    label?: string
 }

const FormTitleContainer: FunctionComponent<IFormTitleContainerProps> = ({ children, title, label }) => {
    return <Container>
        {title && <Text $heading3 $black>{title}</Text>}
        {label && <Text $lighter>{label}</Text>}
        {children}
    </Container>
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export default FormTitleContainer
